import styled from '@emotion/styled';
import { IonContent } from '@ionic/react';

import React, { useState } from 'react';
import ApolloErrorHandler from '../../../components/ApolloErrorHandler';
import { ErrorLabel } from '../../../components/ErrorLabel';
import Searchbar from '../../../components/Search/Searchbar';
import TrailSearchList from '../../../components/Search/TrailSearchList';
import SkeletonSpinner from '../../../components/Skeletons/SkeletonSpinner';
import { TrailFragment } from '../../../generated/graphql';
import { useTrails } from '../../../containers/TrailsContainer';

interface Props {
	onSelect: (trail: TrailFragment) => void;
	onClose?: () => any;
}

const SelectTrail: React.FC<Props> = ({ onSelect, onClose }) => {
	const [search, setSearch] = useState('');
	const { data, loading, error } = useTrails();

	if (error) return <ApolloErrorHandler error={error} />;
	if (loading) return <SkeletonSpinner />;
	if (!data) return <ErrorLabel>Not found</ErrorLabel>;

	return (
		<IonContent>
			<Searchbox>
				<Searchbar
					onChange={(e: React.FormEvent<HTMLInputElement>) =>
						setSearch(e.currentTarget.value)
					}
					placeholder="Select a Trail System..."
					value={search}
				/>
			</Searchbox>

			<TrailSearchList currentSearch={search} onSearchItemClick={onSelect} />
		</IonContent>
	);
};

export default SelectTrail;

const Searchbox = styled.div`
	max-width: 100%;
	width: 100%;
	display: flex;
`;
