import { IonRouterOutlet } from '@ionic/react';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Auth } from './containers/AuthContainer';
import AdminPage from './pages/admin/AdminPage';
import AuthenticatedRoute from './pages/AuthenticatedRoute';
import CreateAccountPage from './pages/create-account/CreateAccountPage';
import CreateTrailPage from './pages/create-trail/CreateTrailPage';
import EditTrailPage from './pages/edit-trail/EditTrailPage';
import FeedPage from './pages/feed/FeedPage';
import FeedbackPage from './pages/feedback/FeedbackPage';
import LoginPage from './pages/login/LoginPage';
import ForgotPasswordPage from './pages/onboarding/ForgotPasswordPage';
import TutorialPage from './pages/onboarding/TutorialPage';
import PremiumModal from './pages/premium/PremiumModal';
import PrivateRoute from './pages/PrivateRoute';
import EditProfilePage from './pages/profile/EditProfilePage';
import PublicProfilePage from './pages/public-profile/PublicProfilePage';
import PublicRoute from './pages/PublicRoute';
import SettingsPage from './pages/settings/SettingsPage';
import TrailDetailsPage from './pages/trail-details/TrailDetailsPage';
import TrailStatusPage from './pages/trail-status/TrailStatusPage';
import TrailsPage from './pages/trails/TrailsPage';
import AuthRoute from './pages/AuthRoute';

const AppRoutes: React.FC = () => {
	const { user } = Auth.useContainer();

	return (
		<IonRouterOutlet placeholder="ss" id="main" key={user?.id || 'anonymous'}>
			<PublicRoute exact path="/trails" component={TrailsPage} />
			<PublicRoute exact path="/trails/:id" component={TrailDetailsPage} />

			<PublicRoute exact path="/feed" component={FeedPage} />

			<PrivateRoute exact path="/admin" component={AdminPage} />
			<PrivateRoute exact path="/edit-trail/:id" component={EditTrailPage} />
			<PrivateRoute exact path="/create-trail" component={CreateTrailPage} />

			<AuthenticatedRoute exact path="/create-account" component={CreateAccountPage} />

			<PrivateRoute exact path="/profile" component={EditProfilePage} />
			<PublicRoute exact path="/profile/:id" component={PublicProfilePage} />

			<PrivateRoute exact path="/premium" component={PremiumModal} />
			<PrivateRoute exact path="/tutorial" component={TutorialPage} />

			<PublicRoute exact path="/settings" component={SettingsPage} />
			<PrivateRoute exact path="/give-feedback" component={FeedbackPage} />

			<AuthRoute exact path="/login" component={LoginPage} />
			<PublicRoute exact path="/login/forgot-password" component={ForgotPasswordPage} />

			<PublicRoute exact path="/trailstatus/:id" component={TrailStatusPage} />

			<Redirect exact path="/" to="/trails" />
		</IonRouterOutlet>
	);
};

export default React.memo(AppRoutes);
