import { IonCol, IonRow } from '@ionic/react';
import { UploadTaskSnapshot } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { Photo } from '../../models/Photo';
import IonGridNoPadding from '../../utils/IonGridNoPadding';
import { FileUploadState } from '../../utils/fileUploadReducer';
import InProgressPhoto from './InProgressPhoto';
import PhotoUploadDropzone from './PhotoUploadDropzone';

export interface PhotoGalleryOptions {
	showClearButton: boolean;
	showUploadProgress: boolean;
	showAsCropped: boolean;
	compressionFunction?: (
		localFileURL: string
	) => Promise<Blob | Uint8Array | ArrayBuffer>;
}

export const DefaultPhotoGalleryOptions = {
	showClearButton: false,
	showUploadProgress: true,
	showAsCropped: false,
	compressionFunction: undefined,
};

interface Props {
	onChange: (value: Photo[]) => any;
	uploadBucketName: string;
	maxItems: number;
	options?: PhotoGalleryOptions;
	onStatusChange?: (status: {
		value: UploadTaskSnapshot[] | undefined;
		loading: boolean;
		error: Error | undefined;
	}) => any;
}

const PhotoUploadGallery: React.FC<Props> = ({
	onChange,
	uploadBucketName,
	maxItems,
	options = DefaultPhotoGalleryOptions,
	onStatusChange,
}) => {
	const [inProgressPhotos, setInProgressPhotos] = useState<FileUploadState[]>(
		[]
	);

	useEffect(() => {
		const photos = inProgressPhotos.reduce(
			(accumilatedPhotos, currentPhoto) => {
				if (currentPhoto.data) {
					accumilatedPhotos.push(currentPhoto.data);
				}
				return accumilatedPhotos;
			},
			[] as Photo[]
		);

		onChange(photos);
	}, [inProgressPhotos, onChange]);

	return (
		<IonGridNoPadding>
			<IonRow>
				{/* In Progress (uploading) Photos */}
				{inProgressPhotos.map((fileUploadState) => (
					<IonCol size="auto" key={fileUploadState.localFileURL}>
						<InProgressPhoto
							fileUploadState={fileUploadState}
							options={options}
						/>
					</IonCol>
				))}

				{/* Upload Photos Button */}
				<PhotoUploadDropzone
					onChange={setInProgressPhotos}
					onStatusChange={onStatusChange}
					maxItems={maxItems}
					currentItems={inProgressPhotos.length}
					uploadBucketName={uploadBucketName}
					compressionFunction={options.compressionFunction}
				/>
			</IonRow>
		</IonGridNoPadding>
	);
};

export default PhotoUploadGallery;
