import styled from '@emotion/styled';

const OrDivider = styled.div`
	position: relative;
	width: 300px;
	height: 30px;
	margin: auto;
	font-weight: bold;
	line-height: 30px;
	text-align: center;
	color: white;

	&::before,
	&::after {
		position: absolute;
		width: 130px;
		height: 1px;
		top: 15px;
		background-color: white;
		content: '';
	}

	&::before {
		left: 0;
	}

	&::after {
		right: 0;
	}
`;

export default OrDivider;
