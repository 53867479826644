import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react';

import { RateApp } from 'capacitor-rate-app';
import React, { useRef } from 'react';
import { FormItem } from '../../components/Form/FormComponents';
import { Auth } from '../../containers/AuthContainer';
import { Capacitor } from '@capacitor/core';
import { RouteComponentProps, withRouter } from 'react-router';

interface Props extends RouteComponentProps {}

const SettingsPage: React.FC<Props> = ({ history }) => {
	const pageRef = useRef<any>();
	const { user, logout } = Auth.useContainer();

	return (
		<IonPage ref={pageRef}>
			<IonHeader>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Settings</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{(Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') && (
					<FormItem>
						<IonButton expand="block" color="dark" onClick={() => RateApp.requestReview()}>
							Rate Skiwise - Help us grow!
						</IonButton>
					</FormItem>
				)}
				<FormItem>
					{user?.id && (
						<IonButton expand="block" onClick={logout}>
							Logout
						</IonButton>
					)}
					{!user?.id && (
						<IonButton expand="block" onClick={() => history.replace('/login')}>
							Login or Signup
						</IonButton>
					)}
				</FormItem>
			</IonContent>
		</IonPage>
	);
};

export default withRouter(SettingsPage);
