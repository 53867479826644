import styled from '@emotion/styled';

import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import TrailStatusCard from './TrailStatusCard';

interface Props extends RouteComponentProps<{ id: string }> {}

const TrailStatusPage: React.FC<Props> = ({ match }) => {
	const trailId = match.params.id;

	if (!trailId) {
		return <div>Error trail not found.</div>;
	}

	return (
		<Container>
			<TrailStatusCard trailId={trailId} />
		</Container>
	);
};

export default withRouter(TrailStatusPage);

const Container = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
`;
