import { IonButton } from '@ionic/react';
import React from 'react';
import Section from '../../components/Section/Section';

const AdminResourcesSection = () => {
	return (
		<Section>
			<Section.Header>Resources</Section.Header>
			<Section.Subheader>
				More resources to help you utilize Skiwise better.
			</Section.Subheader>
			<Section.Content>
				<IonButton
					target="_blank"
					href="help.skiwise-app.com/for-trails"
					fill="outline"
				>
					<span className="pl-2">Visit help center</span>
				</IonButton>
				<div className="flex flex-col space-y-1 text-blue-500">
					<div>
						<a target="_blank" href="help.skiwise-app.com/for-trails">
							How to sell trail passes through Skiwise?
						</a>
					</div>
					<div>
						<a target="_blank" href="help.skiwise-app.com/for-trails">
							How to edit trail location?
						</a>
					</div>
					<div>
						<a target="_blank" href="help.skiwise-app.com/for-trails">
							How to create grooming reports?
						</a>
					</div>
					<div>
						<a target="_blank" href="help.skiwise-app.com/for-trails">
							How to edit trail details?
						</a>
					</div>
				</div>
			</Section.Content>
		</Section>
	);
};

export default AdminResourcesSection;
