import * as Joi from 'joi';

// must be built properly before exporting
export const EditTrailSchema = Joi.object({
	name: Joi.string().min(3).max(40).required(),
	// location: Joi.object({
	// 	city: Joi.string().allow(''),
	// 	state: Joi.string().allow(''),
	// 	country: Joi.string().allow(''),
	// 	lat: Joi.number().required(),
	// 	lng: Joi.number().required(),
	// }).required(),
	// Basic Info
	description: Joi.string().allow(''),
	isClassicAllowed: Joi.boolean(),
	isSkateAllowed: Joi.boolean(),
	trailsLength: Joi.number().integer().positive().max(1000),
	isLighted: Joi.boolean(),
	isSnowmaking: Joi.boolean(),
	isDogsAllowed: Joi.boolean(),
	// Pass
	passIsRequired: Joi.boolean(),
	passDayCost: Joi.number().precision(2),
	passSeasonCost: Joi.number().precision(2),
	passPurchaseURL: Joi.string().uri().allow(''),
	// photos: {
	// 	photoURLs: Joi.array().items(Joi.string()),
	// },
	// trailMap: {
	// 	photoURLs: Joi.array().items(Joi.string()),
	// },
	// admins: Joi.array().items(Joi.any()),
	//Links
	websiteURL: Joi.string().uri().allow(''),
	facebookURL: Joi.string().uri().allow(''),
	instagramURL: Joi.string().uri().allow(''),

	businessEmail: Joi.string()
		.email({ tlds: { allow: false } })
		.allow(''),
	// ssid: Joi.string(),
});
