import { twMerge } from 'tailwind-merge';

import styled from '@emotion/styled';
import { IonInput, IonLabel, IonListHeader, IonNote, IonTextarea } from '@ionic/react';
import React from 'react';
import { sm } from '../../theme/style-system';

export const IonNoteSmall = styled(IonNote)`
	font-size: 0.9rem;
`;

export const IonListHeaderSmall = styled(IonListHeader)`
	font-size: 1rem;
`;

export const Form = styled.form`
	width: 100%;
`;

export const FormTopPadding = styled.div`
	width: 100%;
	height: 1rem;
`;

export const FormItem = styled.div`
	color: var(--ion-color-dark);
	max-width: ${sm};
	margin: auto;
	padding-left: 4%;
	padding-right: 4%;
	margin-bottom: 20px;
	width: 100%;
`;

export const FormLabel = styled(IonLabel)<{ light?: boolean }>`
	color: ${(props) => (props.light ? 'var(--ion-color-light)' : 'var(--ion-color-dark)')};
	font-size: 1rem;
	font-weight: bold;
	padding-left: 10px;
	span {
		font-size: 0.8rem;
		font-weight: bold;
		color: var(--ion-color-primary);
	}
`;

export const FormSubLabel = styled(IonLabel)<{ light?: boolean }>`
	color: ${(props) => (props.light ? 'var(--ion-color-light)' : 'var(--ion-color-medium)')};
	font-size: 0.7rem;
`;

export const FormTextarea: React.FC<React.ComponentProps<typeof IonTextarea>> = ({
	mode = 'md',
	className,
	...props
}) => (
	<IonTextarea
		className={twMerge(`mt-[5px] bg-[--ion-color-light] rounded-[10px] text-[.9rem]`, className)}
		mode={mode}
		{...props}
	/>
);

export const FormBottomPadding = styled.div`
	width: 100%;
	height: 180px;
`;

export const FormInput: React.FC<React.ComponentProps<typeof IonInput>> = ({
	mode = 'md',
	className,
	...props
}) => (
	<IonInput
		className={twMerge(`mt-[5px] rounded-[10px] bg-[--ion-color-light]`, className)}
		mode={mode}
		{...props}
	/>
);

export const FormError = styled.div`
	font-size: 0.9rem;
	color: var(--ion-color-danger);
`;

export const FormHeaderLabel = styled(FormLabel)<{ light?: boolean }>`
	font-size: 1.2rem;
	font-weight: bold;
	color: ${(props) => (props.light ? 'var(--ion-color-light)' : 'var(--ion-color-dark)')};
`;
