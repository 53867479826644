import styled from '@emotion/styled';

export const ErrorLabel = styled.div`
	max-width: 600px;
	height: 100%;
	max-height: 300px;
	font-weight: bold;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 0.9rem;
	word-wrap: break-word;
	color: var(--ion-color-medium-shade);
`;
