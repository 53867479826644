import styled from '@emotion/styled';
import { IonFabButton, IonIcon } from '@ionic/react';
import { add } from 'ionicons/icons';

import React from 'react';
import { useFab } from '../hooks/useFab';

interface Props {
	onClick: () => any;
}

const TrailReportFab: React.FC<Props> = ({ onClick }) => {
	const { padding, hidden } = useFab('bottom');

	const handleClick = () => {
		if (onClick) {
			onClick();
		}
	};
	return (
		<FabContainer hidden={hidden} padding={padding}>
			<IonFabButton translucent color="dark" onClick={handleClick}>
				<IonIcon icon={add} />
			</IonFabButton>
		</FabContainer>
	);
};

export default TrailReportFab;

const FabContainer = styled.div<{ padding: string }>`
	position: absolute;
	bottom: ${(props) => props.padding};
	right: 0;
	padding: 6px;
`;
