import styled from '@emotion/styled';
import { IonIcon } from '@ionic/react';
import { search } from 'ionicons/icons';
import React from 'react';
import { TrailFragment } from '../../generated/graphql';
import { VFlexbox } from '../../utils/Flexbox';
import { timeSince } from '../../utils/UtilityFunctions';

export interface TrailSearchItemType {
	key: string;
	prefix?: React.ReactNode;
	trail: TrailFragment;
}

interface Props {
	item: TrailSearchItemType;
	onSearchItemClick: (id: TrailFragment) => void;
}

const TrailSearchItem: React.FC<Props> = ({ item, onSearchItemClick }) => {
	const trailReportAge =
		item.trail.trailReports.length > 0
			? item.trail.trailReports[0].createdAt
			: undefined;
	const timeAgo = trailReportAge
		? timeSince(trailReportAge, { mini: true })
		: undefined;

	return (
		<Outer
			className="rounded hover:bg-gray-100"
			onClick={() => onSearchItemClick(item.trail)}
		>
			<Left>
				{item.prefix ? item.prefix : <StyledIcon icon={search} />}
				<VFlexbox>
					<Title>{item.trail.name}</Title>
					<SubTitle>
						{item.trail.location.city && item.trail.location.state
							? `${item.trail.location.city} ${item.trail.location.state}`
							: ''}
					</SubTitle>
				</VFlexbox>
			</Left>
			<Right>
				{trailReportAge && <TrailReportInfo>{timeAgo}</TrailReportInfo>}
			</Right>
		</Outer>
	);
};

const StyledIcon = styled(IonIcon)`
	color: var(--ion-color-medium);
	padding-right: 10px;
	padding-left: 5px;
	font-size: 1.4rem;
`;

export default React.memo(TrailSearchItem);

const Outer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 10px;
	margin-right: 10px;
	border-bottom: 1px solid var(--ion-color-light);
	padding: 5px;
	min-height: 3rem;
	cursor: pointer;
`;

const Left = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const Right = styled.div`
	display: flex;
`;

const Title = styled.div`
	font-size: 1rem;
	line-height: 1rem;
`;

const SubTitle = styled.div`
	color: var(--ion-color-medium);
	font-size: 0.7rem;
`;

const TrailReportInfo = styled.div`
	border-radius: 10px;
	background: rgba(255, 20, 20, 1);
	color: white;
	font-size: 0.6rem;
	font-weight: bold;
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-right: 5px;
	line-height: 0.6rem;
`;
