import { createContainer } from 'unstated-next';

function useRedirectURL() {
	// const [redirectURL, setRedirectURL] = useState<string | undefined>(undefined);
	// const [isUsed, setIsUsed] = useState(false);

	// const history = useHistory();

	// useEffect(() => {
	// 	console.log('asdasd');
	// 	App.addListener('appUrlOpen', (data: any) => {
	// 		// Example url: https://beerswift.app/tabs/tab2
	// 		// slug = /tabs/tab2
	// 		const slug = data.url.split('/').pop();
	// 		console.log(data, slug);
	// 		if (slug) {
	// 			history.push(`/${slug}`);
	// 		}
	// 		// If no match, do nothing - let regular routing
	// 		// logic take over
	// 	});
	// }, []);

	// useEffect(() => {
	// 	setRedirectURL(location.pathname);
	// }, [setRedirectURL, location.pathname]);

	// const clearRedirectURL = () => {
	// 	setRedirectURL(undefined);
	// 	setIsUsed(true);
	// };

	return null;

	// return { redirectURL: !isUsed ? redirectURL : undefined, setRedirectURL, clearRedirectURL };
}

export const RedirectURL = createContainer(useRedirectURL);
