import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

export const useFormController = (inputName: string, changingValue: any) => {
	const formContext = useFormContext();
	const { register, setValue } = formContext;

	useEffect(() => {
		register({ name: inputName });
	}, [register, inputName]);

	useEffect(() => {
		setValue(inputName, changingValue);
	}, [changingValue, setValue, inputName]);

	return formContext;
};
