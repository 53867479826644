import { IonItem } from '@ionic/react';
import React from 'react';
import { Star } from '../../../components/icons/Icons';
import { TrailFragment } from '../../../generated/graphql';
import { IonNoteSmall } from '../../Form/FormComponents';
import TrailSearchItem from '../TrailSearchItem';
import { useTrails } from '../../../containers/TrailsContainer';

interface Props {
	noItemsLabel: string;
	onSearchItemClick: (trail: TrailFragment) => void;
	userId: string;
}

const FollowingTrailsSection: React.FC<Props> = ({
	noItemsLabel,
	onSearchItemClick,
	userId,
}) => {
	const { data } = useTrails();

	const followingTrailsItems = (data?.followingTrails || []).map((trail) => ({
		key: trail.id,
		prefix: (
			<div className="pr-[10px]">
				<Star className=" text-primary-500" />
			</div>
		),
		trail: trail,
	}));

	// Sort following trail items by most recent trail report date.
	// This will do a mutable sort in-place.
	followingTrailsItems.sort((a, b) => {
		const creationDateA =
			a.trail.trailReports.length > 0 ? a.trail.trailReports[0].createdAt : 0;
		const creationDateB =
			b.trail.trailReports.length > 0 ? b.trail.trailReports[0].createdAt : 0;
		return new Date(creationDateA) > new Date(creationDateB) ? -1 : 1;
	});

	return (
		<>
			{followingTrailsItems.length > 0 ? (
				followingTrailsItems.map((item) => (
					<TrailSearchItem
						key={item.key}
						item={item}
						onSearchItemClick={onSearchItemClick}
					/>
				))
			) : (
				<IonItem lines="none">
					<IonNoteSmall>{noItemsLabel}</IonNoteSmall>
				</IonItem>
			)}
		</>
	);
};

export default FollowingTrailsSection;
