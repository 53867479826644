import { IonLabel } from '@ionic/react';

import styled from '@emotion/styled';
import { Coords } from 'google-map-react';
import React, { useState } from 'react';
import SelectLocationModal from '../SelectLocationModal';
interface Props {
	currentPageRef: any;
	onChange: any;
	value: Coords;
}

const LocationSelect: React.FC<Props> = ({
	currentPageRef,
	onChange,
	value,
}) => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			{/* <div onClick={() => setShowModal(true)}> */}
			<SelectButton onClick={() => setShowModal(true)}>
				{value && (
					<>
						<IonLabel>lat: {value.lat.toString().slice(0, 6)}</IonLabel>
						<Divider>|</Divider>
						<IonLabel>lng: {value.lng.toString().slice(0, 6)}</IonLabel>
					</>
				)}
			</SelectButton>
			<SelectLocationModal
				onChange={onChange}
				setShowModal={setShowModal}
				showModal={showModal}
				currentPageRef={currentPageRef}
			/>
		</>
	);
};

export default LocationSelect;

const SelectButton = styled.div`
	width: 100%;
	background: var(--ion-color-light);
	padding: 10px;
	margin-top: 5px;
	border-radius: 10px;
	display: flex;
	height: 2.5rem;
`;

const Divider = styled.span`
	padding-left: 1rem;
	padding-right: 1rem;
`;
