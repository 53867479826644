import { ErrorMessage } from '@hookform/error-message';
import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonContent,
	IonHeader,
	IonIcon,
	IonMenuButton,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { chevronForward } from 'ionicons/icons';

import React from 'react';
import { Controller, FormProvider, UseFormMethods } from 'react-hook-form';
import {
	FormBottomPadding,
	FormError,
	FormInput,
	FormItem,
	FormLabel,
	FormTopPadding,
} from '../../components/Form/FormComponents';
import IonToggleExtended from '../../components/Form/IonToggleExtended';
import LocationSelect from '../../components/Form/LocationSelect';
import { CreateTrailModel } from './models/CreateTrailModel';

interface Props {
	pageRef: any;
	form: UseFormMethods<CreateTrailModel>;
	onSubmit: (data: CreateTrailModel) => any;
}

const CreateTrailView: React.FC<Props> = ({ pageRef, form, onSubmit }) => {
	return (
		<>
			<IonHeader>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonButtons slot="end">
						<IonButton onClick={form.handleSubmit(onSubmit)}>
							Submit
							<IonIcon icon={chevronForward} />
						</IonButton>
					</IonButtons>
					<IonTitle>Submit New Trail</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<FormProvider {...form}>
					<FormTopPadding />
					<FormItem>
						<FormLabel>Trail Name</FormLabel>
						<Controller
							name="name"
							render={({ onChange, value }) => (
								<FormInput
									onIonChange={(e: any) => onChange(e.detail.value)}
									value={value}
								/>
							)}
						/>
						<ErrorMessage
							name="name"
							render={({ message }) => <FormError>{message}</FormError>}
						/>
					</FormItem>

					<FormItem>
						<FormLabel>Location</FormLabel>
						<Controller
							name="location"
							render={({ onChange, value }) => (
								<LocationSelect
									currentPageRef={pageRef.current}
									onChange={onChange}
									value={value}
								/>
							)}
						/>
						<ErrorMessage
							name="location"
							render={({ message }) => <FormError>{message}</FormError>}
						/>
					</FormItem>

					<FormItem>
						<FormLabel>Are you the trail owner?</FormLabel>
						<Controller
							name="isOwner"
							render={({ onChange, value }) => (
								<IonToggleExtended
									onIonChange={(e: any) => onChange(e.detail.checked)}
									checked={value}
								/>
							)}
						/>
						<ErrorMessage
							name="isOwner"
							render={({ message }) => <FormError>{message}</FormError>}
						/>
					</FormItem>

					{form.watch('isOwner') === true && (
						<FormItem>
							<FormLabel>Trail Contact Email</FormLabel>
							<Controller
								name="businessEmail"
								render={({ onChange, value }) => (
									<FormInput
										onIonChange={(e: any) => onChange(e.detail.value)}
										value={value}
									/>
								)}
							/>
							<ErrorMessage
								name="businessEmail"
								render={({ message }) => <FormError>{message}</FormError>}
							/>
						</FormItem>
					)}
					<FormItem>
						<IonCard>
							<IonCardContent>
								New trail submissions will go through an approval process. Once
								approved, if you're the trail owner you'll receive an email
								letting you know. <br /> <br />{' '}
								<b>This process can take up to 3 days.</b>
							</IonCardContent>
						</IonCard>
					</FormItem>

					<FormBottomPadding />
				</FormProvider>
			</IonContent>
		</>
	);
};

export default CreateTrailView;
