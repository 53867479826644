import styled from '@emotion/styled';
import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonModal,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { close, logoApple, logoGoogle } from 'ionicons/icons';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	FormError,
	FormHeaderLabel,
	FormInput,
	FormItem,
	FormTopPadding,
} from '../../components/Form/FormComponents';
import OrDivider from '../../components/OrDivider';
import { Auth } from '../../containers/AuthContainer';

interface FormData {
	email: string;
	password: string;
	firstName: string;
	lastName: string;
}

interface Props {
	showModal: boolean;
	setShowModal: any;
}

const SignupModal: React.FC<Props> = ({ setShowModal, showModal }) => {
	const { formSignup, googleLogin, appleLogin, authError } = Auth.useContainer();

	const { handleSubmit, control } = useForm<FormData>({
		mode: 'onSubmit',
	});

	const onSubmit = (formData: FormData) => {
		formSignup(formData.email, formData.password, formData.firstName, formData.lastName);
	};

	return (
		<IonModal isOpen={showModal}>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start" color="primary">
						<IonButton onClick={() => setShowModal(false)}>
							<IonIcon icon={close} />
						</IonButton>
					</IonButtons>
					<IonTitle>Sign Up</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{/* {!isMobile && (
					<LogoContainer>
						<img src={SKIWISE_LOGO} alt='skiwise logo' />
					</LogoContainer>
				)} */}

				<FormTopPadding />
				<FormItem>
					<FormHeaderLabel>Create Account</FormHeaderLabel>
				</FormItem>
				<FormItemNoBottomPadding className="d-flex">
					<Controller
						name="firstName"
						control={control}
						render={({ onChange }) => (
							<FormInput
								onIonChange={(e: CustomEvent) => onChange(e.detail.value)}
								type="text"
								placeholder="First Name"
								autocapitalize="on"
								inputmode="text"
							/>
						)}
					/>

					<Spacer />

					{/* LASTNAME */}
					<Controller
						name="lastName"
						control={control}
						render={({ onChange }) => (
							<FormInput
								onIonChange={(e: CustomEvent) => onChange(e.detail.value)}
								type="text"
								placeholder="Last Name"
								autocapitalize="on"
								inputmode="text"
							/>
						)}
					/>
				</FormItemNoBottomPadding>

				{/* EMAIL */}
				<FormItemNoBottomPadding>
					<Controller
						name="email"
						control={control}
						render={({ onChange }) => (
							<FormInput
								onIonChange={(e: CustomEvent) => onChange(e.detail.value)}
								type="email"
								placeholder="Email"
								inputmode="email"
							/>
						)}
					/>
				</FormItemNoBottomPadding>
				<FormItemNoBottomPadding>
					{/* PASSWORD */}
					<Controller
						name="password"
						control={control}
						render={({ onChange }) => (
							<FormInput
								clearOnEdit={false}
								onIonChange={(e: CustomEvent) => onChange(e.detail.value)}
								type="password"
								placeholder="Password"
								inputmode="text"
							/>
						)}
					/>
				</FormItemNoBottomPadding>
				<FormItemNoBottomPadding>
					<IonButton expand="block" onClick={handleSubmit(onSubmit)}>
						Sign Up
					</IonButton>

					{authError && <StyledFormError>{authError}</StyledFormError>}
				</FormItemNoBottomPadding>

				<OrDivider>OR</OrDivider>

				<FormItem>
					<AlternativeLogins>
						<IonButton onClick={googleLogin} color="black" expand="block">
							<IonIcon slot="start" icon={logoGoogle} />
							Sign In with Google
						</IonButton>

						<IonButton onClick={appleLogin} color="black" expand="block">
							<IonIcon slot="start" icon={logoApple} />
							Sign In with Apple
						</IonButton>
					</AlternativeLogins>
				</FormItem>
			</IonContent>
		</IonModal>
	);
};

export default SignupModal;

const FormItemNoBottomPadding = styled(FormItem)`
	margin-bottom: 10px;
`;

const Spacer = styled.div`
	flex-grow: 0;
	width: 10px;
	height: auto;
`;

const StyledFormError = styled(FormError)`
	color: white;
	background: rgba(0, 0, 0, 0.8);
	padding: 10px;
	border-radius: 5px;
`;

const AlternativeLogins = styled.div`
	width: 100%;
`;
