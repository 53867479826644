import { IonRow } from '@ionic/react';

import styled from '@emotion/styled';

const IonRowNoPadding = styled(IonRow)`
	padding: 0;
	margin: 0;
`;

export default IonRowNoPadding;
