import styled from '@emotion/styled';
import { IonButton, IonIcon } from '@ionic/react';
import { peopleOutline } from 'ionicons/icons';
import React from 'react';

interface Props {
	onButtonClick: () => any;
}

const MainMenuNotLoggedIn: React.FC<Props> = ({ onButtonClick }) => {
	return (
		<HeaderArea>
			<Icon>
				<IonIcon icon={peopleOutline} />
			</Icon>
			<MenuText>
				It looks like you aren't logged in to Skiwise. Join today!
			</MenuText>
			<ButtonsArea>
				<IonButton onClick={onButtonClick}>Login or Signup</IonButton>
			</ButtonsArea>
		</HeaderArea>
	);
};

export default MainMenuNotLoggedIn;

const HeaderArea = styled.div`
	background: var(--ion-color-secondary);
	width: 100%;
	min-height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 20px;
	font-size: 0.95rem;
`;

const Icon = styled.div`
	width: 100%;
	color: var(--ion-color-light);
	display: flex;
	font-size: 1.7rem;
`;

const MenuText = styled.div`
	font-weight: bold;
	color: var(--ion-color-light);
`;

const ButtonsArea = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;
