import styled from '@emotion/styled';
import { IonIcon } from '@ionic/react';
import { search } from 'ionicons/icons';
import React from 'react';

const Searchbar: React.FC<any> = (props) => {
	return (
		<Wrapper>
			<SearchIcon icon={search} />
			<SearchInput type="search" inputMode="search" {...props} />
		</Wrapper>
	);
};

export default Searchbar;

const Wrapper = styled.div`
	max-width: 100%;
	width: 100%;
	background: white;
	margin: 12px;
	padding: 0px;
	border: 0;
	border-radius: 9999px;
	box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const SearchIcon = styled(IonIcon)`
	font-size: 24px;
	color: var(--ion-color-dark-tint);
	padding-left: 8px;
	display: block;
`;

const SearchInput = styled.input`
	width: 100%;
	margin: 0;
	color: var(--ion-color-dark-tint);
	background: none;
	padding: 10px;
	border: 0;
	flex-grow: 1;
	&:focus {
		outline: none;
	}
`;
