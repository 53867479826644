import styled from '@emotion/styled';
import { IonIcon } from '@ionic/react';

import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { TRAIL_MARKER_IMAGE } from '../../../constants/consts';
import { timeSince } from '../../../utils/UtilityFunctions';

interface Props {
	isActive: boolean;
	trailReportAge: string | undefined;
	isFollowing: boolean;
}

const TrailMarker: React.FC<Props> = ({ isActive, trailReportAge, isFollowing }) => {
	const [isHovered, setIsHovered] = useState(false);
	const timeAgo = trailReportAge ? timeSince(trailReportAge, { mini: true }) : undefined;

	const shouldFocus = trailReportAge || isActive || isFollowing || isHovered;

	return (
		<div
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			className={twMerge(
				'opacity-70 cursor-pointer',
				isHovered && 'opacity-90',
				shouldFocus && 'opacity-100'
			)}
		>
			<DownArrow />/
			<div
				className={twMerge(
					'absolute -translate-x-1/2 translate-y-[-100%] bg-white w-8 h-8 rounded-full shadow flex items-center justify-center border-2',
					'border-[var(--ion-color-secondary)] duration-200',
					isFollowing && 'border-yellow-500',
					isActive && 'scale-105 border-[var(--ion-color-dark)]',
					isHovered && 'scale-105'
				)}
			>
				<IonIcon
					icon={TRAIL_MARKER_IMAGE}
					className={twMerge(
						'w-1/2 h-1/2',
						'text-[var(--ion-color-secondary)]',
						isFollowing && 'text-yellow-500',
						isActive && 'text-[var(--ion-color-dark)]'
					)}
				/>
			</div>
			{/* <AlertAvailable></AlertAvailable> */}
			{trailReportAge && <TrailReportAge>{timeAgo}</TrailReportAge>}
		</div>
	);
};

// const TrailMarkerWrapper = styled.div<{ isActive: boolean }>`
// 	position: absolute;
// 	transform: translate(-50%, -100%);
// 	background: rgba(255, 255, 255, 1);
// 	width: 32px;
// 	height: 32px;
// 	border-radius: 50%;
// 	box-shadow: 0px 0px 10px 3px rgba(1, 1, 1, 0.2);
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	border-width: 2px;
// 	border-color: ${(props) => (props.isActive ? 'var(--ion-color-dark)' : TRAIL_COLOR)};
// 	cursor: pointer;
// `;

export default React.memo(TrailMarker);

const DownArrow = styled.div`
	position: absolute;
	transform: translate(-50%, 50%);
	bottom: 0;
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 8px solid var(--ion-color-dark);
`;

// const AlertAvailable = styled.div`
// 	position: absolute;
// 	transform: translate(70%, -280%);
// 	border-radius: 50%;
// 	width: 10px;
// 	height: 10px;
// 	background: rgba(255, 20, 20, 0.9);
// `;

const TrailReportAge = styled.div`
	position: absolute;
	transform: translate(3px, -38px);
	border-radius: 10px;
	background: rgba(255, 20, 20, 1);
	color: white;
	font-size: 0.6rem;
	font-weight: bold;
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
	line-height: 0.6rem;
	cursor: pointer;
`;

// const TrailMarkerWrapper = styled.div<{ isActive: boolean }>`
// 	position: absolute;
// 	transform: translate(-50%, -100%);
// 	background: rgba(255, 255, 255, 1);
// 	width: 32px;
// 	height: 32px;
// 	border-radius: 50%;
// 	box-shadow: 0px 0px 10px 3px rgba(1, 1, 1, 0.2);
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	border-width: 2px;
// 	border-color: ${(props) =>
// 		props.isActive ? 'var(--ion-color-dark)' : TRAIL_COLOR};
// 	cursor: pointer;
// `;

// const MarkerCircle = styled.div`
// 	border-radius: 50%;
// 	background: white;
// 	/* background-color: */
// 	/* background-image: url('./assets/images/trail_bg_square.jpg'); */
// 	/* background-size: cover; */
// 	width: 25px;
// 	height: 25px;
// 	border: 10px solid blue;
// `;
