import {} from '@ionic/react';

import styled from '@emotion/styled';
import React from 'react';

interface Props {
	chips: string[];
}

const ChipsModule: React.FC<Props> = ({ chips }) => {
	return (
		<Wrapper>
			{chips.map((chip) => (
				<Chip key={chip} color="var(--ion-color-primary)">
					{chip}
				</Chip>
			))}
		</Wrapper>
	);
};

export default ChipsModule;

const Wrapper = styled.div``;

const Chip = styled.div<{ color: string; outline?: boolean }>`
	display: inline-block;
	color: ${(props) => (props.outline ? props.color : 'var(--ion-color-light)')};
	background: ${(props) => (props.outline ? 'white' : props.color)};
	min-width: 24px;
	font-size: 0.7rem;
	font-weight: bold;
	padding-top: 0.2rem;
	padding-bottom: 0.2rem;
	border-radius: 1rem;
	/* box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.4); */
	border: ${(props) => (props.outline ? '1px solid ' + props.color : 'none')};
	margin-right: 0.5rem;
	padding-right: 0.7rem;
	padding-left: 0.7rem;
	margin-bottom: 0.5rem;
`;
