import { gql } from '@apollo/client';
import styled from '@emotion/styled';
import { IonAlert, IonButton, IonCard, IonIcon } from '@ionic/react';
import { trashOutline } from 'ionicons/icons';

import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Background from '../../../../components/Background';
import UserAvatar from '../../../../components/UserAvatar';
import {
	DEFAULT_PROFILE_PICTURE,
	STAR_ICON,
	TRAIL_REPORT_BACKGROUND_IMAGE,
} from '../../../../constants/consts';
import { Auth } from '../../../../containers/AuthContainer';
import { PhotoViewer } from '../../../../containers/PhotoViewerContainer';
import {
	TrailReportFragment,
	useDeleteTrailReportMutation,
} from '../../../../generated/graphql';
import { showErrorToast, showSuccessToast } from '../../../../utils/Toasts';
import { timeSince } from '../../../../utils/UtilityFunctions';
import TextModule from '../../trailinfo/components/TextModule';

gql`
	mutation DeleteTrailReport($id: uuid!) {
		deleteTrailReport(id: $id) {
			id
		}
	}
`;

const TRAIL_REPORT_STYLES = {
	bg_image: TRAIL_REPORT_BACKGROUND_IMAGE,
	bg_color_overlay: 'rgba(var(--ion-color-primary-rgb), 0.7)',
	chip_color: 'var(--ion-color-primary)',
	card_border_color: 'var(--ion-color-primary)',
};

const GROOMING_REPORT_STYLES = {
	bg_image: TRAIL_REPORT_BACKGROUND_IMAGE,
	bg_color_overlay: 'rgba(var(--ion-color-primary-rgb), 0.7)',
	chip_color: 'var(--ion-color-primary)',
	card_border_color: 'var(--ion-color-primary)',
};

interface Props {
	trailReport: TrailReportFragment;
	onTrailReportChange?: () => void;
}

const TrailReportModule: React.FC<Props> = ({
	trailReport,
	onTrailReportChange,
}) => {
	const { user } = Auth.useContainer();
	const { showPhotoReel } = PhotoViewer.useContainer();
	const [showDeleteAlert, setShowDeleteAlert] = useState(false);
	const [deleteTrailReport] = useDeleteTrailReportMutation({
		variables: { id: trailReport.id },
	});

	const handleDeleteTrailReportButtonClicked = () => {
		deleteTrailReport()
			.then(() => {
				console.debug('Trail report removed');
				showSuccessToast('Trail Report Deleted Successfully');

				if (onTrailReportChange) {
					onTrailReportChange();
				}
			})
			.catch((e) => {
				console.error(e);
				showErrorToast('Error deleting trail report. Please try again later.');
			});
	};

	const reportStyles = trailReport.isGroomingReport
		? GROOMING_REPORT_STYLES
		: TRAIL_REPORT_STYLES;

	function getStars(quantity: number) {
		const stars = [];
		for (let i = 0; i < quantity; i++) {
			stars.push(
				<Star key={i}>
					<IonIcon icon={STAR_ICON} />
				</Star>
			);
		}
		return stars;
	}

	const timeAgo = trailReport.createdAt
		? `${timeSince(trailReport.createdAt, { mini: true })}`
		: undefined;
	const { pathname } = useLocation();
	const history = useHistory();

	return (
		<IonCard>
			<Header color={reportStyles.card_border_color}>
				<HeaderItem
					onClick={(event) => {
						const ownerProfileRoute = `/profile/${trailReport.owner.id}`;
						if (pathname !== ownerProfileRoute) {
							history.replace(ownerProfileRoute);
						}
						event.stopPropagation();
					}}
				>
					<div className="mr-2">
						<UserAvatar
							size="xs"
							photoURL={
								trailReport.owner.photo?.downloadURL || DEFAULT_PROFILE_PICTURE
							}
						/>
					</div>

					<HeaderText>{trailReport.owner.displayName}</HeaderText>
				</HeaderItem>
				<HeaderItem>
					<HeaderText>{timeAgo}</HeaderText>
				</HeaderItem>
			</Header>

			<ContentBox color={reportStyles.card_border_color}>
				<Background
					img={TRAIL_REPORT_BACKGROUND_IMAGE}
					overlay={reportStyles.bg_color_overlay}
					blur={0}
				>
					<Content
						onClick={(event) => {
							const trailRoute = `/trails/${trailReport.trail.id}`;
							if (pathname !== trailRoute) {
								history.replace(trailRoute);
							}
							event.stopPropagation();
						}}
					>
						<Item>
							<ContentTopItem>
								<Stars>{getStars(trailReport.rating)}</Stars>
							</ContentTopItem>
						</Item>
						<Item>
							{trailReport.isGroomingReport && (
								<Chip className="mt-1 mb-1 mr-2" color="#f7a100">
									Grooming Report
								</Chip>
							)}
							{trailReport.skiRecommendation && (
								<Chip
									className="mt-1 mb-1 mr-2"
									color={reportStyles.chip_color}
								>
									{trailReport.skiRecommendation}
								</Chip>
							)}
							{trailReport.skiTime && (
								<Chip
									className="mt-1 mb-1 mr-2"
									color={reportStyles.chip_color}
								>
									{trailReport.skiTime}
								</Chip>
							)}
							{trailReport.snowType && (
								<Chip
									className="mt-1 mb-1 mr-2"
									color={reportStyles.chip_color}
								>
									{trailReport.snowType}
								</Chip>
							)}
							{trailReport.weather && (
								<Chip
									className="mt-1 mb-1 mr-2"
									color={reportStyles.chip_color}
								>
									{trailReport.weather}
								</Chip>
							)}
						</Item>

						{trailReport.comment && (
							<Item>
								<TextModule
									maxLength={300}
									fontSize="1rem"
									textColor="var(--ion-color-light)"
									buttonColor="dark"
								>
									{trailReport.comment}
								</TextModule>
							</Item>
						)}

						{trailReport.photos && (
							<Item>
								<div className="grid grid-cols-4 pt-4 md:grid-cols-6">
									{trailReport.photos.map(({ photo }, index) => (
										<BoxPadding
											key={photo.id}
											onClick={(event) => {
												showPhotoReel(
													trailReport.photos.map(
														({ photo }) => photo.downloadURL
													),
													index
												);
												event.stopPropagation();
											}}
										>
											<SquareImgContainer className="cursor-pointer">
												<Img
													src={photo.downloadURLThumbnail || photo.downloadURL}
												/>
											</SquareImgContainer>
										</BoxPadding>
									))}
								</div>
							</Item>
						)}
					</Content>
				</Background>
			</ContentBox>

			<Footer color={reportStyles.card_border_color}>
				<div className="my-1">{trailReport.trail.name}</div>
				{user?.id && user.id === trailReport.owner.id && (
					<div>
						<IonButton
							onClick={() => setShowDeleteAlert(true)}
							className="ml-1"
							color="danger"
							size="small"
						>
							<IonIcon icon={trashOutline} />
						</IonButton>
					</div>
				)}
			</Footer>
			<IonAlert
				isOpen={showDeleteAlert}
				onDidDismiss={() => setShowDeleteAlert(false)}
				header={'Delete Trail Report'}
				message={"Are you sure you'd like to delete this trail report?"}
				buttons={[
					{
						text: 'Back',
						role: 'cancel',
					},
					{
						text: 'Delete',
						handler: handleDeleteTrailReportButtonClicked,
					},
				]}
			/>
		</IonCard>
	);
};

export default React.memo(TrailReportModule);

const Item = styled.div`
	padding-top: 2px;
	padding-bottom: 2px;
`;

const ContentTopItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

const Stars = styled.div`
	display: flex;
	justify-content: left;
	font-size: 0.8rem;
	color: white;
`;

const Content = styled.div`
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 24px;
	padding-bottom: 24px;
	margin: auto;
`;

const BoxPadding = styled.div`
	padding-right: 10px;
	padding-bottom: 10px;
	flex: 0 0 20%;
`;

const SquareImgContainer = styled.div`
	float: left;
	position: relative;
	width: 100%;
	padding-bottom: 100%;
	background: var(--ion-color-dark);
	overflow: hidden;
	border-radius: 12px;

	box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.3);
`;

const Img = styled.img`
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	/* box-shadow: 0px -30px 10px 10px; */
`;

const Star = styled.div`
	padding-right: 0.3rem;
`;

const ContentBox = styled.div<{ color: string }>`
	height: 100%;
	background: var(--ion-color-secondary);
	border-left: ${(props) => props.color && `3px solid ${props.color}`};
	border-right: ${(props) => props.color && `3px solid ${props.color}`};
`;

const Header = styled.div<{ color: string }>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 10px;
	padding-bottom: 10px;
	background: ${(props) => props.color};
	color: white;
`;

const HeaderItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const HeaderText = styled.div`
	font-size: 0.8rem;
	font-weight: bold;
`;

const Footer = styled.div<{ color: string }>`
	width: 100%;
	display: flex;
	flex-direction: row;
	padding-right: 2px;
	padding-left: 16px;
	font-weight: bold;
	align-items: center;
	justify-content: space-between;
	background: ${(props) => props.color};
	/* height: 32px; */
	color: white;
`;

const Chip = styled.div<{ color: string; outline?: boolean }>`
	display: inline-block;
	color: ${(props) => (props.outline ? props.color : 'var(--ion-color-light)')};
	background: ${(props) => (props.outline ? 'white' : props.color)};
	min-width: 24px;
	font-size: 0.7rem;
	font-weight: bold;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	padding-top: 0.2rem;
	padding-bottom: 0.2rem;
	border-radius: 1rem;
	/* box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.4); */
	border: ${(props) => (props.outline ? '1px solid ' + props.color : 'none')};
`;
