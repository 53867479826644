import styled from '@emotion/styled';
import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { link, logoFacebook, logoInstagram } from 'ionicons/icons';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import SearchbarButton from '../../components/Search/SearchbarButton';
import TrailSearchModal from '../../components/Search/TrailSearchModal';
import TrailReportFab from '../../components/TrailReportFab';
import { LINK_FACEBOOK, LINK_INSTAGRAM, LINK_WEBSITE } from '../../constants/consts';
import { ActiveTrail } from '../../containers/ActiveTrailContainer';
import { Auth } from '../../containers/AuthContainer';
import { LoginModal } from '../../containers/LoginModalContainer';
import { TrailsMap } from '../../containers/TrailsMapContainer';
import { useWindowSize } from '../../hooks/useWindowSize';
import CreateTrailReportModal from '../create-trail-report/CreateTrailReportModal';
import TrailsMapApollo from './TrailsMapApollo';
import ActiveTrailCard from './components/ActiveTrailCard';

const TrailsPage: React.FC<RouteComponentProps> = () => {
	const { user } = Auth.useContainer();
	const [showTrailReportModal, setShowTrailReportModal] = useState(false);
	const [showTrailSearchModal, setShowTrailSearchModal] = useState(false);
	const { isMobile } = useWindowSize();

	const { showLoginScreenPopup } = LoginModal.useContainer();

	return (
		<IonPage>
			<IonHeader translucent>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Skiwise Live Trail Map</IonTitle>
					{!isMobile && (
						<IonButtons slot="end">
							<IonButton target="_blank" href={LINK_FACEBOOK}>
								<IonIcon icon={logoFacebook} />
							</IonButton>
							<IonButton target="_blank" href={LINK_INSTAGRAM}>
								<IonIcon icon={logoInstagram} />
							</IonButton>
							<IonButton target="_blank" href={LINK_WEBSITE} fill="outline">
								<IonIcon icon={link} />
								<span className="pl-2">Website</span>
							</IonButton>
						</IonButtons>
					)}
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen={true} scrollY={false} scrollX={false}>
				<TrailsMap.Provider>
					<ActiveTrail.Provider>
						<FloatingSearchbarContainer>
							<SearchbarButton
								onClick={(e: any) => {
									e.stopPropagation();
									setShowTrailSearchModal(true);
								}}
								isButton={true}
								placeholder={'Search trails'}
							/>
						</FloatingSearchbarContainer>

						<TrailSearchModal
							showModal={showTrailSearchModal}
							setShowModal={setShowTrailSearchModal}
						/>
						<TrailsMapApollo />
						<ActiveTrailCard />
						<TrailReportFab
							onClick={() =>
								user?.id
									? setShowTrailReportModal(true)
									: showLoginScreenPopup('You must be logged in to create a trail report.')
							}
						/>
						<CreateTrailReportModal
							showModal={showTrailReportModal}
							setShowModal={setShowTrailReportModal}
						/>
					</ActiveTrail.Provider>
				</TrailsMap.Provider>
			</IonContent>
		</IonPage>
	);
};

export default TrailsPage;

const FloatingSearchbarContainer = styled.div`
	cursor: pointer;
	position: absolute;
	width: 100%;
	z-index: 2;
`;
