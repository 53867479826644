import styled from '@emotion/styled';
import { IonButton, IonCol, IonIcon } from '@ionic/react';
import { UploadTaskSnapshot } from 'firebase/storage';
import { close } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { useUploadFiles } from '../../hooks/useUploadFiles';
import { FileUploadState } from '../../utils/fileUploadReducer';
import { ErrorLabel } from '../ErrorLabel';

interface Props {
	maxItems: number;
	currentItems: number;
	uploadBucketName: string;
	compressionFunction?: (
		localFileURL: string
	) => Promise<Blob | Uint8Array | ArrayBuffer>;
	onChange?: (photos: FileUploadState[]) => any;
	onStatusChange?: (status: {
		value: UploadTaskSnapshot[] | undefined;
		loading: boolean;
		error: Error | undefined;
	}) => any;
}

//disabled and maxItems status needs to change as new items added
const PhotoUploadDropzone: React.FC<Props> = ({
	currentItems,
	maxItems,
	uploadBucketName,
	compressionFunction,
	onChange,
	onStatusChange,
}) => {
	const { status, files, uploadFiles, clear } = useUploadFiles(
		uploadBucketName,
		{
			compressionFunction: compressionFunction,
		}
	);

	const [tooManyItemsError, setTooManyItemsError] = useState<
		string | undefined
	>(undefined);

	/** Trigger onChange() */
	useEffect(() => {
		if (onChange) {
			onChange(files);
		}
	}, [files, onChange]);

	/** Trigger onStatusChange() */
	useEffect(() => {
		if (status)
			if (onStatusChange) {
				onStatusChange(status);
			}
	}, [status, onStatusChange]);

	const inputRef = useRef<any>();

	const handleInputChange = () => {
		setTooManyItemsError(undefined);
		const inputFiles = inputRef?.current?.files;

		// Verify not too many images are being uploaded
		if (inputFiles.length > maxItems - currentItems) {
			setTooManyItemsError(`Too many items. Max: ${maxItems}`);
			inputRef.current.value = '';
			return;
		}

		uploadFiles(inputRef?.current?.files);
	};

	const isFull = currentItems === maxItems;

	return (
		<>
			{currentItems > 0 && (
				<IonCol size="auto">
					<GridItem onClick={() => console.log('sdasd')}>
						<IonButton
							onClick={() => clear()}
							expand="block"
							color="primary"
							size="small"
						>
							<IonIcon icon={close} />
							Clear
						</IonButton>
					</GridItem>
				</IonCol>
			)}

			{!isFull && (
				<IonCol size="auto">
					<div
						className="relative w-[6rem] h-[6rem] flex items-center justify-center bg-none rounded-lg border-dashed border-2 dash border-gray-300 hover:bg-gray-50 cursor-pointer"
						onClick={() => inputRef?.current?.click()}
					>
						{!tooManyItemsError ? (
							<AiOutlineCloudUpload className="w-6 h-6 text-gray-400" />
						) : (
							<ErrorLabel>{tooManyItemsError}</ErrorLabel>
						)}
					</div>
					<input
						// disabled={files.length > }
						ref={inputRef}
						onChange={() => handleInputChange()}
						type="file"
						name="images"
						accept="image/*"
						multiple={maxItems - currentItems > 1 ? true : false}
						hidden
					/>
				</IonCol>
			)}
		</>
	);
};

export default PhotoUploadDropzone;

const GridItem = styled.div`
	position: relative;
	margin: auto;
	width: 6rem;
	height: 6rem;
	background: none;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
`;
