import { gql } from '@apollo/client';
import { FCM } from '@capacitor-community/fcm';
import {
	ActionPerformed,
	PushNotificationSchema,
	PushNotifications,
} from '@capacitor/push-notifications';

import { useCallback, useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { useUploadRegisteredPushTokenMutation } from '../generated/graphql';
import { Auth } from './AuthContainer';
import { Capacitor } from '@capacitor/core';

const platform = Capacitor.getPlatform();

gql`
	mutation UploadRegisteredPushToken($object: push_token_insert_input!) {
		insertPushToken(object: $object) {
			id
		}
	}
`;

const usePush = () => {
	const [notifications, setNotifications] = useState<PushNotificationSchema[]>(
		[]
	);
	const [token, setToken] = useState('');
	const { user } = Auth.useContainer();
	const [UploadRegisteredPushToken] = useUploadRegisteredPushTokenMutation();

	const requestPushNotificationPermission = () => {
		PushNotifications.requestPermissions().then((result: any) => {
			if (result.granted) {
				// Register with Apple / Google to receive push via APNS/FCM
				register();
			} else {
				// Show some error
			}
		});
	};

	const register = useCallback(() => {
		if (platform === 'web') {
			return;
		}
		// Register with Apple / Google to receive push via APNS/FCM
		PushNotifications.register();

		// On success
		PushNotifications.addListener('registration', () => {
			FCM.getToken()
				.then((result) => {
					console.debug('[FCM token]', result.token);
					UploadRegisteredPushToken({
						variables: {
							object: {
								id: result.token,
								platform: platform,
								userId: user?.id,
							},
						},
					})
						.then((res) => console.debug(res))
						.catch((e) => console.warn(e));
					setToken(result.token);
				})
				.catch((err) => console.error(err));
		});

		// On Error
		PushNotifications.addListener('registrationError', (error: any) => {
			// showErrorToast('Error on registration: ' + JSON.stringify(error));
		});

		// Show us the notification payload if the app is open on our device
		PushNotifications.addListener(
			'pushNotificationReceived',
			(notification: PushNotificationSchema) => {
				setNotifications((notifs) => [...notifs, notification]);
			}
		);

		// Method called when tapping on a notification
		PushNotifications.addListener(
			'pushNotificationActionPerformed',
			(notification: ActionPerformed) => {
				const newNotif = {
					id: notification.notification.id,
					title: notification.notification.title,
					body: notification.notification.body,
					data: notification.notification.data,
				};
				setNotifications((notifs) => [...notifs, newNotif]);
			}
		);
	}, [UploadRegisteredPushToken, user]);

	//Initialize
	useEffect(() => {
		register();
	}, [register]);

	return { notifications, requestPushNotificationPermission, token };
};

export const Push = createContainer(usePush);
