import * as Joi from 'joi';

export const EditProfileSchema = Joi.object({
	displayName: Joi.string()
		.min(6)
		.max(20)
		.regex(new RegExp('^[a-zA-Z0-9_]*$'))
		.message('Only letters, numbers and "_" are allowed.')
		.required(),
	photo: Joi.any(),
	description: Joi.string().allow(''),
});
