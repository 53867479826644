import styled from '@emotion/styled';
import { IonButton, IonIcon } from '@ionic/react';
import { chevronForward } from 'ionicons/icons';

import React, { useEffect, useState } from 'react';
import { useParagraph } from '../../../../hooks/useParagraph';

interface Props {
	children: string;
	maxLength: number;
	textColor: string;
	buttonColor: string; //ionic color
	fontSize?: string;
}

const TextModule: React.FC<Props> = ({
	maxLength,
	children,
	textColor,
	buttonColor,
	fontSize,
}) => {
	const [showingMore, setShowingMore] = useState(true);

	const { text, isShortened } = useParagraph(children, {
		maxLength: maxLength,
		newLineWeight: 100,
	});

	useEffect(() => {
		if (children) {
			if (isShortened) {
				setShowingMore(false);
			}
		}
	}, [children, isShortened]);

	return (
		<Wrapper color={textColor} fontSize={fontSize}>
			{showingMore ? <P>{children}</P> : <P>{text + '...'}</P>}
			{!showingMore && (
				<IonButton
					size="small"
					fill="clear"
					color={buttonColor}
					onClick={(e) => {
						setShowingMore(true);
						e.stopPropagation();
					}}
				>
					<b>Show More</b>
					<IonIcon icon={chevronForward} />
				</IonButton>
			)}
		</Wrapper>
	);
};

export default TextModule;

const Wrapper = styled.div<{ color: string; fontSize?: string }>`
	font-size: ${(props) => props.fontSize ?? '0.9rem'};
	color: ${(props) => props.color};
`;

const P = styled.p`
	white-space: pre-line;
`;
