import styled from '@emotion/styled';

import React from 'react';
import { IonNoteSmall } from '../../components/Form/FormComponents';
import { TrailFeedQuery } from '../../generated/graphql';

interface Props {
	data: TrailFeedQuery;
}

const FavoritesFeed: React.FC<Props> = ({ data }) => {
	const followingTrails = data.user?.followingTrails;
	return (
		<Wrapper>
			{followingTrails ? (
				<>
					{followingTrails.map(({ trail }) => (
						// <FavoritesFeedTrailCard key={trail.id} trail={trail} />
						// <TrailCard trail={trail} />
						<></>
					))}
				</>
			) : (
				<IonNoteSmall>No trails following</IonNoteSmall>
			)}
		</Wrapper>
	);
};

export default FavoritesFeed;

const Wrapper = styled.div``;
