import {
	IonButtons,
	IonContent,
	IonHeader,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react';

import React from 'react';
import ErrorPage from '../../components/ErrorPage';
import EditProfileApollo from './EditProfileApollo';
import ProfileHeader from './components/ProfileHeader';
import { useUser } from '../../containers/UserContainer';

interface Props {}

const EditProfilePage: React.FC<Props> = () => {
	const { data } = useUser();
	return (
		<IonPage className="profile">
			<IonHeader translucent>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Edit Profile</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen={true}>
				{data?.user ? (
					<>
						<ProfileHeader user={data.user} />
						<EditProfileApollo userId={data.user.id} />
					</>
				) : (
					<ErrorPage>User not found. You may need to login again.</ErrorPage>
				)}
			</IonContent>
		</IonPage>
	);
};

export default EditProfilePage;
