import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { useEffect, useState } from 'react';
import { AdManager } from '../containers/AdmobContainer';
const safeAreaInsets = require('safe-area-insets');
const isAvailable = Capacitor.isPluginAvailable('Keyboard');

export const useFab = (position: 'top' | 'bottom') => {
	const [showing, setShowing] = useState(true);
	const { showBanner } = AdManager.useContainer();

	// const AdSize = width && width > MAX_SCREEN_SIZE_FOR_SMALLER_ADS ? 90 : 50;
	const AdSize = 50;

	const paddingFromTopEdgeOfDevice = showBanner
		? `${safeAreaInsets.top}px`
		: '0px';
	const paddingFromBottomEdgeOfDevice = showBanner
		? `${AdSize + safeAreaInsets.bottom}px`
		: '0px';

	useEffect(() => {
		if (isAvailable) {
			if (position === 'bottom') {
				Keyboard.addListener('keyboardWillShow', () => {
					setShowing(false);
				});

				Keyboard.addListener('keyboardWillHide', () => {
					setShowing(true);
				});
			}
		}
	}, [position, setShowing]);

	return {
		//Should you hide the element
		hidden: !showing,
		// How much padding should be applied so the FAB doesn't interfere w/ other page elements?
		padding:
			position === 'bottom'
				? paddingFromBottomEdgeOfDevice
				: paddingFromTopEdgeOfDevice,
	};
};
