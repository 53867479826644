import {} from '@ionic/react';

import React from 'react';

interface Props {
	clusterSize: number;
}

const ClusterMarker: React.FC<Props> = ({ clusterSize }) => {
	return (
		<div
			className="flex items-center justify-center font-bold text-white bg-gray-800 bg-opacity-50 rounded-full text-md"
			style={{
				width: `${Math.min(35 + clusterSize * 3, 150)}px`,
				height: `${Math.min(35 + clusterSize * 3, 150)}px`,
			}}
		>
			{clusterSize}
		</div>
	);
};

export default React.memo(ClusterMarker);
