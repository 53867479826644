import { IonButton, IonIcon } from '@ionic/react';
import { notificationsOutline, trailSign } from 'ionicons/icons';

import styled from '@emotion/styled';
import React from 'react';
import UserAvatar from '../../../../components/UserAvatar';
import { LoginModal } from '../../../../containers/LoginModalContainer';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import TrailAlertsButton from './TrailAlertsButton';
import { useUser } from '../../../../containers/UserContainer';

interface Props {
	userId?: string;
	trailId: string;
	name: string;
	length?: number;
}

const LikeModule: React.FC<Props> = ({ userId, trailId, name, length }) => {
	const { showLoginScreenPopup } = LoginModal.useContainer();
	const { data } = useUser();
	const { width } = useWindowSize();

	return (
		<LikeWrapper>
			<Details>
				<UserAvatar size="md" />
				<DetailsContent>
					<HeaderText>{name}</HeaderText>
					<DetailText>
						{length && (
							<>
								<Heart icon={trailSign} />
								<LikeCount>{length}km</LikeCount>
							</>
						)}
					</DetailText>
				</DetailsContent>
			</Details>

			<RightSection>
				<RightContent>
					{data?.user ? (
						<TrailAlertsButton user={data.user} trailId={trailId} />
					) : (
						<IonButton
							expand={width && width > 500 ? undefined : 'block'}
							onClick={() =>
								showLoginScreenPopup('You must be logged in to subscribe to trail report alerts.')
							}
						>
							<AlertButton>
								<span>{'Follow'}</span>
								<Bell icon={notificationsOutline} />
							</AlertButton>
						</IonButton>
					)}
				</RightContent>
			</RightSection>
		</LikeWrapper>
	);
};

export default LikeModule;

const LikeWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	flex-wrap: wrap;
`;

const HeaderText = styled.div`
	font-size: 1.2rem;
	color: var(--ion-color-dark);
`;

const RightContent = styled.div`
	@media (min-width: 500px) {
		float: right;
	}
`;

const RightSection = styled.div`
	flex-grow: 1;
`;

const Details = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const DetailsContent = styled.div`
	padding-left: 8px;
	display: column;
	flex-direction: row;
	padding-right: 8px;
`;

const DetailText = styled.div`
	display: flex;
	align-items: center;
`;

const LikeCount = styled.div`
	color: var(--ion-color-medium-shade);
	font-weight: normal;
	font-size: 1.1rem;
	padding-left: 0.2rem;
	padding-right: 0.5rem;
`;

const Heart = styled(IonIcon)`
	font-size: 0.9rem;
	color: var(--ion-color-medium-shade);
`;

const AlertButton = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	span {
		padding-right: 14px;
		padding-left: 14px;
	}
`;

const Bell = styled(IonIcon)`
	font-size: 1.4em;
	vertical-align: end;
`;
