import {
	IonButtons,
	IonContent,
	IonHeader,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import React, { useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
// import Select from 'react-select';
import AdminResourcesSection from './AdminResourcesSection';
import AdminTrailInfoSection from './AdminTrailInfoSection';
import AdminTrailPassesSection from './AdminTrailPassesSection';

interface Props extends RouteComponentProps<{ id: string }> {}

const AdminPage: React.FC<Props> = () => {
	// const { user } = Auth.useContainer();

	// const { data } = useAdminPageDetailsQuery({
	// 	variables: { userId: user?.id || '' },
	// 	skip: !user?.id,
	// });

	const pageRef = useRef<any>();

	// const trails = data?.trails || [];

	return (
		<IonPage id="admin-page" ref={pageRef}>
			<IonHeader>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Admin</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div className="flex flex-col items-stretch min-h-full gap-4 p-4 pb-20 bg-gray-200 md:p-12">
					<div className="flex justify-between">
						{/* <Select
							onChange={(e) => setTrailId(e?.value)}
							placeholder='Select trail...'
							options={data?.trails.map((trail) => ({ value: trail.id, label: trail.name }))}
							className='w-full max-w-md'
						/> */}
					</div>
					<div className="grid grid-cols-1 gap-4 md:grid-cols-1">
						<AdminTrailInfoSection />
						<AdminTrailPassesSection />
						<AdminResourcesSection />
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default withRouter(AdminPage);
