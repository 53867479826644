import React from 'react';
import ApolloContainer from './ApolloContainer';
import { Auth } from './AuthContainer';
import { Config } from './ConfigContainer';
import PhotoViewerContainer from './PhotoViewerContainer';
import { Push } from './PushContainer';
import { RedirectURL } from './RedirectURLContainer';
import { TrailsProvider } from './TrailsContainer';
import { UserProvider } from './UserContainer';
import { UserLocation } from './UserLocationContainer';
import { ChakraProvider } from '@chakra-ui/react';

interface Props {
	children: React.ReactNode;
}

const Store: React.FC<Props> = ({ children }) => {
	console.log('Global contexts have been re-rendered at: ' + Date.now());

	return (
		<ApolloContainer>
			<Config.Provider>
				<ChakraProvider>
					<Auth.Provider>
						<Push.Provider>
							<PhotoViewerContainer>
								<RedirectURL.Provider>
									<UserLocation.Provider>
										<UserProvider>
											<TrailsProvider>
												<div className="text-poppins">{children}</div>
											</TrailsProvider>
										</UserProvider>
									</UserLocation.Provider>
								</RedirectURL.Provider>
							</PhotoViewerContainer>
						</Push.Provider>
					</Auth.Provider>
				</ChakraProvider>
			</Config.Provider>
		</ApolloContainer>
	);
};

export default React.memo(Store);
