import { useState } from 'react';
import { TrailFragment } from '../generated/graphql';

import { createContainer } from 'unstated-next';

function useActiveTrail() {
	const [activeTrail, setActiveTrail] = useState<TrailFragment | null>(null);

	return {
		activeTrail,
		setActiveTrail,
	};
}

export const ActiveTrail = createContainer(useActiveTrail);
