import React from 'react';

export function useRequiredContext<T>(
	name: string,
	context: React.Context<T | null>
): T {
	const value = React.useContext(context);
	if (!value) {
		throw new Error(
			`[${name}] You can't call this hook unless under it's provider.`
		);
	}

	return value;
}
