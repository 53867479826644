import { Photo } from '../models/Photo';

export type FileUploadState = {
	localFileURL: string;
	data: Photo | undefined;
	loadingPercent: number | undefined;
	error: string | undefined;
};

export function storageReducer(draft: FileUploadState[], action: any) {
	switch (action.type) {
		case SUBMITTING: {
			draft[action.index] = {
				localFileURL: action.localFileURL,
				data: undefined,
				loadingPercent: undefined,
				error: undefined,
			};
			return;
		}

		case UPDATE_LOADING: {
			if (draft[action.index]) {
				draft[action.index].loadingPercent = action.percent;
			}
			return;
		}

		case SUCCESS: {
			if (draft[action.index]) {
				draft[action.index].data = action.data;
				draft[action.index].loadingPercent = undefined;
				draft[action.index].error = undefined;
			}
			return;
		}

		case ERROR: {
			if (draft[action.index]) {
				draft[action.index].loadingPercent = undefined;
				draft[action.index].error = action.error;
			}

			return;
		}

		case RESET: {
			return action.initialState;
		}

		default:
			return;
	}
}

export const SUBMITTING = `SUBMITTING`;
export const UPDATE_LOADING = `UPDATE_LOADING`;
export const SUCCESS = `SUCCESS`;
export const ERROR = `ERROR`;
export const RESET = `RESET`;

//ACTION CREATORS
export const submitting = (index: number, localFileURL: string) => ({
	type: SUBMITTING,
	index,
	localFileURL,
});
export const updateLoading = (index: number, percent: number) => ({
	type: UPDATE_LOADING,
	percent,
	index,
});
export const success = (index: number, data: Photo) => ({
	type: SUCCESS,
	data,
	index,
});
export const error = (index: number, error: string) => ({
	type: ERROR,
	error,
	index,
});
export const reset = (initialState: FileUploadState[]) => ({
	type: RESET,
	initialState,
});
