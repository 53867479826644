import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { arrowBack, chevronForward } from 'ionicons/icons';

import { ErrorMessage } from '@hookform/error-message';
import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory, withRouter } from 'react-router-dom';
import {
	FormError,
	FormInput,
	FormItem,
	FormLabel,
	FormTopPadding,
} from '../../components/Form/FormComponents';
import { showErrorToast, showSuccessToast } from '../../utils/Toasts';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

interface FormData {
	email: string;
}

const ForgotPassword: React.FC = () => {
	const form = useForm<FormData>();
	const [submitting, setSubmitting] = useState(false);
	const history = useHistory();

	const onSubmit = (formData: FormData) => {
		setSubmitting(true);
		//run forgot password
		FirebaseAuthentication.sendPasswordResetEmail({ email: formData.email })
			.then(() => {
				showSuccessToast('Password reset email sent to ' + formData.email);
			})
			.catch((e) => {
				showErrorToast(e.message);
			})
			.finally(() => {
				setSubmitting(false);
			});
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonButton className="ion-float-left" onClick={() => history.goBack()}>
							Back
							<IonIcon icon={arrowBack} slot="start" />
						</IonButton>
					</IonButtons>
					<IonTitle>Forgot Password</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<FormProvider {...form}>
					<FormTopPadding />
					<FormItem>
						<FormLabel>Account Email</FormLabel>
						<Controller
							name="email"
							render={({ onChange, value }) => (
								<FormInput onIonChange={onChange} value={value} inputMode="text" mode="md" />
							)}
						/>
						<ErrorMessage
							name="displayName"
							render={({ message }) => <FormError>{message}</FormError>}
						/>
					</FormItem>
					<FormItem>
						<IonButton
							disabled={submitting}
							onClick={form.handleSubmit(onSubmit)}
							type="submit"
							className="ion-float-right"
						>
							Send Password Reset email
							<IonIcon icon={chevronForward} />
						</IonButton>
					</FormItem>
				</FormProvider>
			</IonContent>
		</IonPage>
	);
};

export default withRouter(ForgotPassword);
