import imageCompression from 'browser-image-compression';
import {
	UploadTask,
	UploadTaskSnapshot,
	getDownloadURL,
	ref,
	uploadBytesResumable,
} from 'firebase/storage';
import { useImmerReducer } from 'use-immer';
import { v4 as uuidv4 } from 'uuid';
import { storage } from '../firebase';
import {
	FileUploadState,
	error,
	reset,
	storageReducer,
	submitting,
	success,
	updateLoading,
} from '../utils/fileUploadReducer';
import { useLoadingValue } from './useLoadingValue';

const initialState: FileUploadState[] = [];

interface UploadFileHookParams {
	compressionFunction?: (
		localFileURL: string
	) => Promise<Blob | Uint8Array | ArrayBuffer>;
}

export const useUploadFiles = (
	bucketName: string,
	params: UploadFileHookParams = {}
) => {
	const [state, dispatch] = useImmerReducer<FileUploadState[]>(
		storageReducer,
		initialState
	);
	const {
		value: valueMainReducer,
		loading: loadingMainReducer,
		error: errorMainReducer,
		reset: resetMainReducer,
		setValue,
		setError,
		startLoading,
	} = useLoadingValue<UploadTaskSnapshot[], Error>(false);

	const uploadFiles = async (newFiles?: FileList) => {
		if (!newFiles) {
			return;
		}

		resetMainReducer(); // For WHOLE reducer's upload status
		startLoading();
		const promises: UploadTask[] = [];
		const currentFileCount = state.length;

		for (let i = 0; i < newFiles.length; i++) {
			const newFile = newFiles[i];
			const _splitFileName = newFile.name.split('.');
			const fileExtensionName = _splitFileName[_splitFileName.length - 1]; // ex. jpg, png, gif

			const localFileURL = URL.createObjectURL(newFile);
			const fileName = uuidv4() + '.' + fileExtensionName;

			const index = currentFileCount + i;

			dispatch(submitting(index, localFileURL));

			let newCompressedFile: Blob | Uint8Array | ArrayBuffer = newFile;
			if (params.compressionFunction) {
				console.debug('Doing custom compressing...');
				newCompressedFile = await params.compressionFunction(localFileURL);
			} else {
				console.debug('Doing default compressing...');
				newCompressedFile = await imageCompression(newFile, {
					maxWidthOrHeight: 1080,
					maxSizeMB: 0.4,
				});
			}

			const uploadTask = uploadBytesResumable(
				ref(storage, `${bucketName}/${fileName}`),
				newCompressedFile
			);

			promises.push(uploadTask);

			uploadTask.on(
				'state_changed',
				(snapshot) => {
					const progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					if (snapshot.state === 'running') {
						dispatch(updateLoading(index, progress));
					}
				},
				(err) => {
					dispatch(error(index, err.message));
				},
				async () => {
					const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
					URL.revokeObjectURL(localFileURL);

					dispatch(
						success(index, {
							downloadURL: downloadURL,
							downloadURLThumbnail: undefined,
							bucketName: bucketName,
							fileName: fileName,
						})
					);
				}
			);
		}

		try {
			const result = await Promise.all(promises);

			console.debug(
				'[useUploadFiles] Upload successful. Uploaded files count: ',
				result.length
			);
			setValue(result);
		} catch (err) {
			console.error('[useUploadFiles]', err);
			setError(err as Error);
		}
	};

	return {
		status: {
			value: valueMainReducer,
			loading: loadingMainReducer,
			error: errorMainReducer,
		},
		files: state,
		uploadFiles,
		clear: () => dispatch(reset(initialState)),
	};
};
