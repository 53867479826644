import { IonPage } from '@ionic/react';

import React from 'react';
import { RouteComponentProps } from 'react-router';
import PublicProfileApollo from './PublicProfileApollo';

interface Props extends RouteComponentProps<{ id: string }> {}

const PublicProfilePage: React.FC<Props> = ({ match }) => {
	const userId = match.params.id;

	return (
		<IonPage>
			<PublicProfileApollo userId={userId} />
		</IonPage>
	);
};

export default PublicProfilePage;
