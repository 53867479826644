import { IonIcon } from '@ionic/react';
import { search } from 'ionicons/icons';
import React from 'react';

interface Props {
	isButton?: boolean;
	onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	inputRef?: React.MutableRefObject<any>;
}

const SearchbarButton: React.FC<Props | any> = ({
	isButton = false,
	onClick,
	inputRef,
	...props
}) => {
	return (
		<div
			className="flex items-center w-full max-w-[600px] mx-auto p-3 hover:scale-[1.01] duration-200"
			onClick={onClick}
		>
			<div className="bg-white border border-gray-300 shadow-searchButton flex items-center w-full rounded-full p-3 px-5 space-x-2 text-gray-500">
				<IonIcon icon={search} />
				<div>Search trails</div>
			</div>
		</div>
	);
};

export default SearchbarButton;

// const Searchbox = styled(animated.div)`
// 	display: flex;
// 	flex-direction: row;
// 	align-items: center;
// 	flex-grow: 1;
// 	width: 100%;
// 	max-width: 600px;
// 	margin: auto;
// `;

// const SearchButton = styled.div<{ isButton: boolean }>`
// 	max-width: 100%;
// 	width: 100%;
// 	background: white;
// 	margin: 12px;
// 	padding: 10px;
// 	border: 0;
// 	border-radius: 9999px;
// 	box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.2);
// 	display: flex;
// 	flex-direction: row;
// 	align-items: center;
// 	cursor: ${(props) => (props.isButton ? 'pointer' : 'auto')};
// `;

// const SearchIcon = styled(IonIcon)`
// 	font-size: 24px;
// 	color: var(--ion-color-dark-tint);
// 	padding-left: 8px;
// 	padding-right: 8px;
// 	display: block;
// `;

// const SearchInput = styled.input<{ isButton: boolean }>`
// 	width: 100%;
// 	margin: 0;
// 	color: var(--ion-color-dark-tint);
// 	background: none;
// 	border: 0;
// 	flex-grow: 1;
// 	&:focus {
// 		outline: none;
// 	}
// 	cursor: ${(props) => (props.isButton ? 'pointer' : 'auto')} !important;
// `;
