import styled from '@emotion/styled';
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonContent,
	IonIcon,
	IonLabel,
	IonLoading,
	IonNote,
	IonToast,
} from '@ionic/react';
import { chevronForward, logoApple, logoGoogle } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import Background from '../../components/Background';
import { FormHeaderLabel, FormInput, FormItem } from '../../components/Form/FormComponents';
import { Hoverable } from '../../components/Hoverable';

import OrDivider from '../../components/OrDivider';
import { DEFAULT_BACKGROUND_IMAGE, SKIWISE_LOGO } from '../../constants/consts';
import { Auth } from '../../containers/AuthContainer';
import { useWindowSize } from '../../hooks/useWindowSize';
import { LogoContainer } from '../onboarding/components/LogoContainer';
import SignupModal from '../onboarding/SignupModal';

interface FormData {
	email: string;
	password: string;
}

const Login: React.FC = () => {
	const { control, handleSubmit } = useForm<FormData>({
		defaultValues: {
			email: '',
			password: '',
		},
		shouldUnregister: false,
	});
	const [showErrorToast, setShowErrorToast] = useState(false);
	const history = useHistory<{ referrer?: string }>();
	const { isMobile } = useWindowSize();

	const { formLogin, appleLogin, googleLogin, user, isAuthenticating, authError } =
		Auth.useContainer();
	const [showSignupModal, setShowSignupModal] = useState(false);

	useEffect(() => {
		if (authError) {
			setShowErrorToast(true);
		}
	}, [authError]);

	useEffect(() => {
		if (user) {
			setShowSignupModal(false);
			history.replace('/create-account');
		}
	}, [user, history]);

	const onSubmit = async (formData: FormData) => {
		await formLogin(formData.email, formData.password);
	};

	return (
		<Background
			img={DEFAULT_BACKGROUND_IMAGE}
			// overlay='rgba(var(--ion-color-primary-rgb), 0.7)'
			// blur={2}
		>
			<StyledIonContent>
				<IonLoading isOpen={isAuthenticating} message={'Authenticating...'} />

				<SignupModal showModal={showSignupModal} setShowModal={setShowSignupModal} />

				<ContentWrapper>
					{/* {platform} */}
					{/* <FormItem>
						<LoginPageMessage>You must be logged in to view that page.</LoginPageMessage>
					</FormItem> */}
					{!isMobile && (
						<LogoContainer>
							<img src={SKIWISE_LOGO} alt="skiwise logo" />
						</LogoContainer>
					)}

					<FormItem>
						<FormHeaderLabel light>Log In</FormHeaderLabel>
						<div className="flex flex-col space-y-2">
							<Controller
								name="email"
								control={control}
								render={({ onChange }) => (
									<FormInput
										onIonChange={(e: CustomEvent) => onChange(e.detail.value)}
										type="email"
										placeholder="Email Address"
										inputmode="email"
									/>
								)}
							/>
							<Controller
								name="password"
								control={control}
								render={({ onChange }) => (
									<FormInput
										clearOnEdit={false}
										onIonChange={(e: CustomEvent) => onChange(e.detail.value)}
										type="password"
										placeholder="Password"
										inputmode="text"
									/>
								)}
							/>

							<IonButton
								type="button"
								color="primary"
								expand="block"
								onClick={handleSubmit(onSubmit)}
							>
								Log In
							</IonButton>
						</div>

						{/* {authError && <StyledFormError>{authError}</StyledFormError>} */}
						<IonToast
							isOpen={showErrorToast}
							onDidDismiss={() => setShowErrorToast(false)}
							message={authError}
							position="top"
							color="dark"
							keyboardClose={true}
							buttons={[
								{
									text: 'Dismiss',
									role: 'cancel',
								},
							]}
							duration={10000}
						/>
						<PushToEnd>
							<IonButton
								onClick={() => history.push('/login/forgot-password')}
								fill="clear"
								mode="ios"
								size="small"
							>
								<IonNote color="light" mode="ios">
									Forgot password
								</IonNote>
								<IonIcon icon={chevronForward} color="light" />
							</IonButton>
						</PushToEnd>
					</FormItem>
					<OrDivider>OR</OrDivider>

					<FormItem>
						<AlternativeLogins>
							<IonButton onClick={googleLogin} color="black" expand="block">
								<IonIcon slot="start" icon={logoGoogle} />
								Sign In with Google
							</IonButton>

							{/* {platform === 'ios' && ( */}
							<IonButton onClick={appleLogin} color="black" expand="block">
								<IonIcon slot="start" icon={logoApple} />
								Sign In with Apple
							</IonButton>
							{/* )} */}
						</AlternativeLogins>
					</FormItem>
					<Hoverable>
						<IonCard onClick={() => setShowSignupModal(true)}>
							<IonCardContent>
								<IonLabel>
									<NoAccount>Don't have an account?</NoAccount>
									<SignUp>Sign Up For Free</SignUp>
								</IonLabel>
							</IonCardContent>
						</IonCard>
					</Hoverable>
				</ContentWrapper>

				{/* <FixedContainer position='bottom' maxWidth={xs}> */}

				{/* </FixedContainer> */}
			</StyledIonContent>
		</Background>
	);
};

export default Login;

const ContentWrapper = styled.div`
	max-width: 30rem;
	margin: auto;
	width: 100%;
	padding-top: 10px;
`;

const StyledIonContent = styled(IonContent)`
	--background: none;
`;

const SignUp = styled.div`
	color: var(--ion-color-primary);
	font-weight: bold;
`;

const NoAccount = styled.p`
	color: var(--ion-color-dark) !important;
	font-weight: bold;
`;

const AlternativeLogins = styled.div`
	width: 100%;
`;

const PushToEnd = styled.div`
	display: flex;
	justify-content: flex-end;
`;
