import styled from '@emotion/styled';
import React from 'react';

interface Props {
	membershipType: string;
}

const UserBanner: React.FC<Props> = ({ membershipType }) => {
	if (!membershipType) {
		return <div></div>;
	}

	return (
		<BannerChip color={membershipType.toLowerCase()}>
			{membershipType.charAt(0).toUpperCase() +
				membershipType.slice(1).toLowerCase()}
		</BannerChip>
	);
};

export default React.memo(UserBanner);

const BannerChip = styled.div<{ color: string }>`
	font-weight: bold;
	padding: 4px;
	padding-left: 16px;
	padding-right: 16px;
	border-radius: 50px;
	display: inline-block;
	color: ${(props) => `var(--ion-color-${props.color}-contrast)`};
	background: ${(props) => `var(--ion-color-${props.color})`};
	font-size: 0.7rem;
	box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.3);
`;
