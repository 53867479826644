import styled from '@emotion/styled';
import { ErrorMessage } from '@hookform/error-message';
import { IonButton, IonSpinner } from '@ionic/react';

import { Eye } from 'iconsax-react';
import React from 'react';
import { Controller, FormProvider, UseFormMethods } from 'react-hook-form';
import PhotoUploadGallery from '../../components/AttachFiles/PhotoUploadGallery';
import {
	FormBottomPadding,
	FormError,
	FormInput,
	FormItem,
	FormLabel,
	FormTextarea,
	FormTopPadding,
} from '../../components/Form/FormComponents';
import { EditProfileModel } from './models/EditProfileModel';

interface Props {
	userId: string;
	onSubmit: (data: EditProfileModel) => any;
	form: UseFormMethods<EditProfileModel>;
	setIsUploadingPhotos: React.Dispatch<React.SetStateAction<boolean>>;
	isUploadingPhotos: boolean;
}

const EditProfileView: React.FC<Props> = ({
	userId,
	onSubmit,
	form,
	setIsUploadingPhotos,
	isUploadingPhotos,
}) => {
	return (
		<FormProvider {...form}>
			<div className="flex justify-center py-2">
				<IonButton color="primary" href={`/profile/${userId}`}>
					<div className="flex items-center space-x-1">
						<Eye className="h-4" />
						<span>View public profile</span>
					</div>
				</IonButton>
			</div>
			<FormTopPadding />
			<FormItem>
				<FormLabel>Change Display Name</FormLabel>
				<Controller
					name="displayName"
					render={({ onChange, value }) => (
						<FormInput
							onIonChange={onChange}
							value={value}
							inputMode="text"
							autoCapitalize="on"
							mode="md"
						/>
					)}
				/>
				<ErrorMessage
					name="displayName"
					render={({ message }) => <FormError>{message}</FormError>}
				/>
			</FormItem>

			<FormItem>
				<FormLabel>New Profile Picture</FormLabel>
				<Controller
					name="photo"
					render={({ onChange }) => (
						<PhotoUploadGallery
							onChange={(photos) => onChange(photos[0])}
							onStatusChange={(status) =>
								status.loading
									? setIsUploadingPhotos(true)
									: setIsUploadingPhotos(false)
							}
							uploadBucketName="user_profile_photos"
							maxItems={1}
							options={{
								showClearButton: true,
								showUploadProgress: true,
								showAsCropped: true,
								// compressionFunction: sharpCompress, // Crop image to 300x300
							}}
						/>
					)}
				/>
				<ErrorMessage
					name="photo"
					render={({ message }) => <FormError>{message}</FormError>}
				/>
			</FormItem>

			<FormItem>
				<FormLabel>About Me</FormLabel>
				<Controller
					name="description"
					render={({ onChange, value }: any) => (
						<FormTextarea
							onIonChange={onChange}
							value={value}
							rows={6}
							inputMode="text"
							// placeholder="I've been skiing for a number of years. I usually ski for fun but occasionally will do some local events as-well. My favorites are..."
							autoCapitalize="true"
							autoCorrect="on"
							spellcheck
						/>
					)}
				/>
				<ErrorMessage
					name="description"
					render={({ message }) => <FormError>{message}</FormError>}
				/>
			</FormItem>
			<FormItem>
				<StyledIonButton
					onClick={form.handleSubmit(onSubmit)}
					color="primary"
					disabled={isUploadingPhotos || !form.formState.isDirty}
				>
					{!isUploadingPhotos ? (
						'Save Changes'
					) : (
						<>
							<IonSpinner name="crescent" duration={1000} />
							Loading...
						</>
					)}
				</StyledIonButton>
			</FormItem>
			<FormBottomPadding />
		</FormProvider>
	);
};

export default EditProfileView;

const StyledIonButton = styled(IonButton)`
	float: right;
`;
