import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useFormController } from '../../hooks/useFormController';

import { SkiRecommendation } from '../../types/TrailReportAttributes/SkiRecommendation';
import { SkiTime } from '../../types/TrailReportAttributes/SkiTime';
import { SnowType } from '../../types/TrailReportAttributes/SnowType';
import { WeatherType } from '../../types/TrailReportAttributes/WeatherType';

export type Option = {
	id: string;
	name: string;
	values: SnowType[] | WeatherType[] | SkiTime[] | SkiRecommendation[];
};

interface Props {
	tag: Option;
}

const IonChipSelect: React.FC<Props> = ({ tag }) => {
	const [selected, setSelected] = useState<string | undefined>();

	useFormController(`${tag.id}`, selected);

	const onSelect = (newValue: string) => {
		newValue === selected ? setSelected(undefined) : setSelected(newValue);
	};

	const isSelected = (value: string) => selected === value;

	return (
		<div className="flex flex-wrap items-center gap-3 p-2">
			{tag.values.map((value) => (
				<div
					key={value}
					onClick={() => onSelect(value)}
					className={twMerge(
						'cursor-pointer rounded-lg border-gray-300 border opacity-80 px-4 text-sm py-2 text-gray-500 flex flex-col justify-center items-center space-y-1 min-h-[60px]',
						'hover:border-primary-500 hover:text-primary-500 hover:bg-primary-500 hover:bg-opacity-10',
						isSelected(value) &&
							'bg-primary-500 bg-opacity-10 border-2 font-bold border-primary-500 text-primary-500'
					)}
				>
					{/* <div>{getIcon(value)}</div> */}
					<span>{value}</span>
				</div>
			))}
		</div>
	);
};

export default IonChipSelect;
