import { useIonViewDidEnter } from '@ionic/react';
import React, { useEffect, useRef } from 'react';
import { useContainer } from 'unstated-next';
import {
	CLUSTER_POINT_RADIUS_DESKTOP,
	CLUSTER_POINT_RADIUS_MOBILE,
	MAPBOX_ACCESS_TOKEN,
	MAX_ZOOM_TO_CREATE_CLUSTERS_DESKTOP,
	MAX_ZOOM_TO_CREATE_CLUSTERS_MOBILE,
} from '../../constants/consts';
import { ActiveTrail } from '../../containers/ActiveTrailContainer';
import { TrailsMap } from '../../containers/TrailsMapContainer';
import { UserLocation } from '../../containers/UserLocationContainer';
import { useWindowSize } from '../../hooks/useWindowSize';
import { TrailsMapFeatureCollection } from './TrailsMapApollo';

// Essential
import 'mapbox-gl/dist/mapbox-gl.css';
import { Map, MapRef, NavigationControl } from 'react-map-gl';
import { ClustersAndMarkers } from './components/ClustersAndMarkers';
import { map } from 'ts-pattern/dist/patterns';

interface Props {
	trails: TrailsMapFeatureCollection;
}

export const MapboxGLTrailsMap: React.FC<Props> = ({ trails }) => {
	const { viewport, setViewport } = useContainer(TrailsMap);
	const { setActiveTrail } = useContainer(ActiveTrail);
	const { isMobile } = useWindowSize();
	const mapRef = useRef<MapRef>(null);

	const resizeMap = () => {
		console.log('map resize called');
		const map = mapRef?.current?.getMap();
		if (map) map.resize();
	};

	useIonViewDidEnter(() => {
		if (mapRef.current) {
			const map = mapRef.current.getMap();
			resizeMap();

			// Fix issue w/ zoom by 1-finger double tapping then dragging: https://docs.mapbox.com/mapbox-gl-js/api/handlers/#touchzoomrotatehandler#isactive
			if (
				map.touchZoomRotate &&
				(map.touchZoomRotate as any)['_tapDragZoom'] &&
				(map.touchZoomRotate as any)['_tapDragZoom']['_enabled'] !== undefined
			) {
				(map.touchZoomRotate as any)['_tapDragZoom']['_enabled'] = false;
			}
		}
	});

	useEffect(() => {
		if (mapRef.current) {
			const map = mapRef.current.getMap();
			console.debug('spencerdebug', mapRef.current);
		}
	});

	const [isDragging, setIsDragging] = React.useState(false);

	return (
		<Map
			onDragStart={() => setIsDragging(true)}
			onDragEnd={() => setIsDragging(false)}
			ref={mapRef}
			{...viewport}
			reuseMaps
			onError={(err) => console.error('MAPERROR', err)}
			onMove={(evt) => {
				setViewport((cur) => ({
					...cur,
					longitude: evt.viewState.longitude,
					latitude: evt.viewState.latitude,
					zoom: evt.viewState.zoom,
				}));
			}}
			customAttribution="Skiwise LLC"
			attributionControl={false}
			pitchWithRotate={false}
			dragRotate={false}
			touchPitch={false}
			onClick={() => setActiveTrail(null)}
			mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
			style={{ width: '100%', height: '100%' }}
			onStyleData={() => {
				console.log('style data called');
				resizeMap();
			}}
			onLoad={() => {
				console.log('style data called');
				resizeMap();
			}}
			mapStyle="mapbox://styles/spencerpauly/clq9s999j004a01nt2f9y6nqm"
		>
			{!isMobile && <NavigationControl showCompass={false} />}
			<ClustersAndMarkers points={trails} />
		</Map>
	);
};
