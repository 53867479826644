import { gql } from '@apollo/client';
import styled from '@emotion/styled';
import { IonAlert, IonButton, IonIcon } from '@ionic/react';
import { notifications, notificationsOutline } from 'ionicons/icons';

import React, { useState } from 'react';
import { Push } from '../../../../containers/PushContainer';
import {
	UserFragment,
	useFollowTrailMutation,
	useUnfollowTrailMutation,
} from '../../../../generated/graphql';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { showErrorToast } from '../../../../utils/Toasts';

gql`
	mutation FollowTrail($userId: String!, $trailId: uuid!) {
		insertUserTrailFollow(object: { trailId: $trailId, userId: $userId }) {
			userId
		}
	}
`;

gql`
	mutation UnfollowTrail($userId: String!, $trailId: uuid!) {
		deleteUserTrailFollow(trailId: $trailId, userId: $userId) {
			userId
		}
	}
`;

interface Props {
	user: UserFragment;
	trailId: string;
}

const TrailAlertsButton: React.FC<Props> = ({ user, trailId }) => {
	const [showFollowAlert, setShowFollowAlert] = useState(false);
	const [showUnfollowAlert, setShowUnfollowAlert] = useState(false);
	const { width } = useWindowSize();

	const isFollowing = user.followingTrails?.some((t) => t.trailId === trailId);

	const { requestPushNotificationPermission } = Push.useContainer();

	const [followTrail] = useFollowTrailMutation({
		variables: { userId: user.id, trailId: trailId },
	});

	const [unfollowTrail] = useUnfollowTrailMutation({
		variables: { userId: user.id, trailId: trailId },
	});

	const handleAlertsToggle = () => {
		if (!isFollowing) {
			followTrail()
				.then(() => {
					setShowFollowAlert(true);
				})
				.catch((e) => {
					console.error(e);
					showErrorToast('Error enabling alerts. Please try again later.');
				});
			requestPushNotificationPermission();
		} else {
			unfollowTrail()
				.then(() => {
					setShowUnfollowAlert(true);
				})
				.catch((e) => {
					console.error(e);
					showErrorToast('Error disabling alerts. Please try again later.');
				});
		}
	};

	return (
		<IonButton
			expand={width && width > 500 ? undefined : 'block'}
			onClick={handleAlertsToggle}
		>
			<IonAlert
				isOpen={showFollowAlert}
				onDidDismiss={() => setShowFollowAlert(false)}
				header={'Alerts ON'}
				message={'Push Notifications have been turned on for this trail.'}
				buttons={['OK']}
			/>
			<IonAlert
				isOpen={showUnfollowAlert}
				onDidDismiss={() => setShowUnfollowAlert(false)}
				header={'Alerts OFF'}
				message={'Push notifications have been turned off for this trail.'}
				buttons={['OK']}
			/>
			<AlertButton>
				<span>{isFollowing ? 'Following' : 'Follow'}</span>
				<Bell icon={isFollowing ? notifications : notificationsOutline} />
			</AlertButton>
		</IonButton>
	);
};

export default TrailAlertsButton;

const AlertButton = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	span {
		padding-right: 14px;
		padding-left: 14px;
	}
`;

const Bell = styled(IonIcon)`
	font-size: 1.4em;
	vertical-align: end;
`;
