import styled from '@emotion/styled';
import {} from '@ionic/react';

import React from 'react';
import { DEFAULT_PROFILE_PICTURE } from '../constants/consts';

type SizeType = 'xs' | 'sm' | 'md' | 'lg';

const SIZES = {
	xs: {
		image: '1.8rem',
		border: '0px',
	},
	sm: {
		image: '3rem',
		border: '2px',
	},
	md: {
		image: '4.5rem',
		border: '3px',
	},
	lg: {
		image: '6rem',
		border: '3px',
	},
};

interface Props {
	size: SizeType;
	photoURL?: string;
}

const UserAvatar: React.FC<Props> = (props) => {
	const { size, photoURL, ...restprops } = props;

	return (
		<div {...restprops}>
			<Border imageSize={SIZES[size].image} borderSize={SIZES[size].border}>
				<Image src={photoURL || DEFAULT_PROFILE_PICTURE} alt="user avatar" />
			</Border>
		</div>
	);
};

export default React.memo(UserAvatar);

const Border = styled.div<{ imageSize: string; borderSize: string }>`
	width: ${(props) => (props.imageSize ? props.imageSize : '0rem')};
	height: ${(props) => (props.imageSize ? props.imageSize : '0rem')};
	overflow: hidden;
	border-radius: 50%;
	border: ${(props) => (props.borderSize ? props.borderSize : '0px')} solid
		var(--ion-color-light);
`;

const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;
