import {
	IonButton,
	IonButtons,
	IonHeader,
	IonIcon,
	IonModal,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { useCallback, useState } from 'react';
import SelectTrail from './components/SelectTrail';
// import SelectTrail from './components/SelectTrail';
import CreateTrailReportApollo from './CreateTrailReportApollo';

export interface TrailNameIdCombo {
	id: string;
	name?: string;
}

interface Props {
	defaultTrail?: TrailNameIdCombo;
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateTrailReportModal: React.FC<Props> = ({ defaultTrail, showModal, setShowModal }) => {
	const [trail, setTrail] = useState<undefined | TrailNameIdCombo>(defaultTrail);

	const handleClose = useCallback(() => {
		setTrail(defaultTrail);
		setShowModal(false);
	}, [defaultTrail, setShowModal]);

	return (
		<IonModal isOpen={showModal} onDidDismiss={() => setTrail(defaultTrail)}>
			<IonHeader>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonButton onClick={handleClose}>
							<IonIcon icon={close} />
						</IonButton>
					</IonButtons>
					<IonTitle>
						{trail?.id
							? `New Trail Report${trail?.name ? ` - ${trail.name}` : ''}`
							: `New Trail Report - Select Trail`}
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			{trail?.id ? (
				<CreateTrailReportApollo onClose={handleClose} trailId={trail.id} />
			) : (
				<SelectTrail onSelect={(data) => setTrail({ id: data.id, name: data.name })} />
			)}
		</IonModal>
	);
};

export default CreateTrailReportModal;
