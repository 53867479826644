import styled from '@emotion/styled';
import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonList,
	IonMenuButton,
	IonTitle,
	IonToolbar,
} from '@ionic/react';

import { Edit2 } from 'iconsax-react';
import React from 'react';
import {
	FormItem,
	FormLabel,
	FormTopPadding,
} from '../../components/Form/FormComponents';
import { Auth } from '../../containers/AuthContainer';
import { PublicProfileFragment } from '../../generated/graphql';
import { timeSince } from '../../utils/UtilityFunctions';
import ProfileHeader from '../profile/components/ProfileHeader';
import TrailReportModule from '../trail-details/trailreports/components/TrailReportModule';

interface Props {
	user: PublicProfileFragment;
	onProfileChange: () => void;
}

const PublicProfile: React.FC<Props> = ({ user, onProfileChange }) => {
	const { user: authUser } = Auth.useContainer();

	return (
		<>
			<IonHeader>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>{user.displayName}'s Profile</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<ProfileHeader user={user} />
				{authUser && authUser.id === user.id && (
					<div className="flex justify-center py-2">
						<IonButton color="primary" href={`/profile/`}>
							<div className="flex items-center space-x-1">
								<Edit2 className="h-4" />
								<span>Edit my profile</span>
							</div>
						</IonButton>
					</div>
				)}
				<FormTopPadding />

				<FormItem>
					<FormLabel>Joined: </FormLabel>
					<FormSubLabel>{timeSince(user.createdAt)}</FormSubLabel>
				</FormItem>
				<FormItem>
					<FormLabel>About Me</FormLabel>
					<DisplayInfoBox>
						{user.description || 'No description set.'}
					</DisplayInfoBox>
				</FormItem>
				<FormItem>
					<FormLabel>
						My Trail Reports (
						<span>{user.trailReports_aggregate.aggregate?.count || 0}</span>)
					</FormLabel>
					{/* <FormSubLabel */}
					<IonList>
						{user.trailReports.map((trailReport) => (
							<TrailReportLink key={trailReport.id}>
								<TrailReportModule
									onTrailReportChange={onProfileChange}
									trailReport={trailReport}
								/>
							</TrailReportLink>
						))}
					</IonList>
				</FormItem>
			</IonContent>
		</>
	);
};

export default PublicProfile;

const DisplayInfoBox = styled.div`
	margin-top: 5px;
	background: var(--ion-color-light);
	border-radius: 10px;
	padding: 10px;
	color: var(--ion-color-dark-tint);
`;

const FormSubLabel = styled.div`
	color: var(--ion-color-primary);
	margin-left: 5px;
	display: inline-block;
`;

const TrailReportLink = styled.div`
	& :hover {
		cursor: pointer;
	}
`;
