import Section from '../../components/Section/Section';

// const columns = [
// 	{ key: 'id', name: 'ID', width: 100 },
// 	{ key: 'email', name: 'Email', width: 300 },
// 	{ key: 'passType', name: 'Pass Type', width: 100 },
// 	{ key: 'isActive', name: 'Active', width: 50 },
// 	{ key: 'expiresAt', name: 'Expires At', minWidth: 150 },
// ];

// const rows = [
// 	{
// 		id: 12,
// 		name: 'Bobby Pierce',
// 		email: 'bobbypierce@gmail.com',
// 		passType: 'day',
// 		isActive: 'Yes',
// 		expiresAt: '2020-01-01',
// 	},
// 	{
// 		id: 5,
// 		name: 'Link Pierce',
// 		email: 'lp@gmail.com',
// 		passType: 'day',
// 		isActive: 'Yes',
// 		expiresAt: '2020-01-01',
// 	},
// 	{
// 		id: 6,
// 		name: 'Lemmon Leimg',
// 		email: 'llemo@gmail.com',
// 		passType: 'day',
// 		isActive: 'Yes',
// 		expiresAt: '2020-01-01',
// 	},
// 	{
// 		id: 4,
// 		name: 'La Dan',
// 		email: 'LADa@gmail.com',
// 		passType: 'day',
// 		isActive: 'Yes',
// 		expiresAt: '2020-01-01',
// 	},
// 	{
// 		id: 1,
// 		name: 'Timmy Memo',
// 		email: 'bobbypierce@gmail.com',
// 		passType: 'day',
// 		isActive: 'Yes',
// 		expiresAt: '2020-01-01',
// 	},
// 	{
// 		id: 2,
// 		name: 'Alice Xerox',
// 		email: 'alicex@gmail.com',
// 		passType: 'day',
// 		isActive: 'No',
// 		expiresAt: '2020-01-01',
// 	},
// 	{
// 		id: 3,
// 		name: 'Jonny Pierce',
// 		email: 'jp@gmail.com',
// 		passType: 'season',
// 		isActive: 'No',
// 		expiresAt: '2020-01-01',
// 	},
// ];

// gql`
// 	fragment TrailPassDetails on trail_pass {
// 		id
// 		userId
// 		trailId
// 		user {
// 			email
// 		}
// 		type
// 		purchasedAt
// 		expiresAt
// 	}
// `;

const AdminTrailPassesSection = () => {
	return (
		<Section>
			<Section.Header>Trail Passes</Section.Header>
			<Section.Subheader>Manage your skiers' trail passes.</Section.Subheader>
			<Section.Content>
				<div></div>
				{/* <DataGrid
					rowClass={(row) => (row.isActive === 'Yes' ? 'bg-green-100' : undefined)}
					className='rdg-light'
					columns={columns}
					rows={rows}
				/> */}
			</Section.Content>
		</Section>
	);
};

export default AdminTrailPassesSection;
