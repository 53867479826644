import styled from '@emotion/styled';
import { FeatureCollection, Point } from 'geojson';

import React, { useMemo } from 'react';
import ApolloErrorHandler from '../../components/ApolloErrorHandler';
import { useTrails } from '../../containers/TrailsContainer';
import { TrailFragment } from '../../generated/graphql';
import { MapboxGLTrailsMap } from './MapboxGLTrailsMap';
import { AUTH_TOKEN } from '../../containers/AuthContainer';

export type TrailsMapFeatureCollection = FeatureCollection<
	Point,
	TrailFragment & { cluster: boolean; isFollowing: boolean }
>;

const TrailsMapApollo: React.FC = () => {
	const { data, error, loading } = useTrails();

	const geoJSONTrailsData: TrailsMapFeatureCollection = useMemo(
		() => ({
			type: 'FeatureCollection',
			features:
				data?.trails.map((trail) => ({
					id: trail.id,
					type: 'Feature',
					properties: {
						...trail,
						cluster: false,
						isFollowing: (trail.userFollowers_aggregate.aggregate?.count || 0) >= 1,
					},
					geometry: {
						type: 'Point',
						coordinates: [trail.location.lng, trail.location.lat],
					},
				})) || [],
		}),
		[data?.trails]
	);

	if (error) return <ApolloErrorHandler error={error} />;

	return (
		<MapWrapper>
			<MapboxGLTrailsMap trails={geoJSONTrailsData} />
		</MapWrapper>
	);
};

export default TrailsMapApollo;

const MapWrapper = styled.div`
	width: 100vw;
	height: 100vh;
`;
