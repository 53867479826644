import styled from '@emotion/styled';

import React from 'react';
import { animated, useSpring } from 'react-spring';
import { FileUploadState } from '../../utils/fileUploadReducer';
import { PhotoGalleryOptions } from './PhotoUploadGallery';

interface Props {
	fileUploadState: FileUploadState;
	options: PhotoGalleryOptions;
}

const InProgressPhoto: React.FC<Props> = ({ fileUploadState, options }) => {
	const animateOverlay = useSpring({
		height: fileUploadState.loadingPercent
			? `${fileUploadState.loadingPercent}%`
			: '0%',
	});

	return (
		<ImageContainer
			success={options.showUploadProgress && !!fileUploadState.data}
			error={!!fileUploadState.error}
		>
			{options.showUploadProgress && <Overlay style={animateOverlay} />}
			<PreviewImage
				src={fileUploadState.localFileURL}
				showAsCropped={options.showAsCropped}
			/>
		</ImageContainer>
	);
};

export default InProgressPhoto;

const ImageContainer = styled.div<{ success: boolean; error: boolean }>`
	position: relative;
	margin: auto;
	width: 6rem;
	height: 6rem;
	border: ${(props) =>
		props.success
			? '2px solid var(--ion-color-success)'
			: props.error
			  ? '2px solid var(--ion-color-danger)'
			  : 'none'};
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--ion-color-light);
`;

const Overlay = styled(animated.div)`
	background: rgba(5, 146, 255, 0.3);
	width: 100%;
	z-index: 2;
	position: absolute;
	bottom: 0;
`;

const PreviewImage = styled.img<{ showAsCropped: boolean }>`
	width: 5.5rem;
	height: 5.5rem;
	object-fit: ${(props) => (props.showAsCropped ? 'cover' : 'contain')};
`;
