import useSupercluster from 'use-supercluster';
import { TrailsMapFeatureCollection } from '../TrailsMapApollo';
import { MapRef, Marker, useMap } from 'react-map-gl';
import React, { useEffect, useState } from 'react';
import { useContainer } from 'unstated-next';
import { TrailsMap } from '../../../containers/TrailsMapContainer';
import {
	MAX_MAP_ZOOM,
	MAX_ZOOM_TO_CREATE_CLUSTERS_DESKTOP,
	MAX_ZOOM_TO_CREATE_CLUSTERS_MOBILE,
} from '../../../constants/consts';
import { BBox } from 'geojson';
import ClusterMarker from './ClusterMarker';
import { ActiveTrail } from '../../../containers/ActiveTrailContainer';
import { getMostRecentTrailReportAge } from '../../../utils/UtilityFunctions';
import TrailMarker from './TrailMarker';
import { useWindowSize } from '../../../hooks/useWindowSize';

interface ClustersAndMarkersProps {
	points: TrailsMapFeatureCollection;
}

const ClustersAndMarkersComponent = ({ points }: ClustersAndMarkersProps) => {
	const map = useMap();
	const { viewport } = useContainer(TrailsMap);
	const { activeTrail, setActiveTrail } = useContainer(ActiveTrail);
	const { isMobile } = useWindowSize();

	const { clusters } = useSupercluster({
		points: points.features,
		bounds: map.current && (map.current.getBounds().toArray().flat() as BBox),
		zoom: viewport.zoom,
		options: {
			radius: 75,
			maxZoom: isMobile ? MAX_ZOOM_TO_CREATE_CLUSTERS_MOBILE : MAX_ZOOM_TO_CREATE_CLUSTERS_DESKTOP,
		},
	});
	return (
		<>
			{clusters.map((cluster) => {
				const [longitude, latitude] = cluster.geometry.coordinates;
				const { cluster: isCluster, point_count: pointCount } = cluster.properties;

				if (isCluster) {
					return (
						<Marker
							style={{ pointerEvents: 'none' }}
							key={`cluster-${cluster.id}`}
							longitude={longitude}
							latitude={latitude}
						>
							<ClusterMarker clusterSize={pointCount} />
						</Marker>
					);
				}

				if (isCluster) return null;

				return (
					<Marker
						onClick={(ev) => {
							// console.log(e);
							// e.preventDefault();
							ev.originalEvent.stopPropagation();
							setActiveTrail(cluster.properties);
						}}
						key={`${cluster.properties?.id}-${cluster.properties?.id === activeTrail?.id}`}
						longitude={cluster.geometry.coordinates[0]}
						latitude={cluster.geometry.coordinates[1]}
					>
						<TrailMarker
							isFollowing={cluster.properties.isFollowing}
							isActive={cluster.properties.id === activeTrail?.id}
							trailReportAge={getMostRecentTrailReportAge(cluster.properties.trailReports)}
						/>
					</Marker>
				);
			})}
		</>
	);
};

export const ClustersAndMarkers = React.memo(ClustersAndMarkersComponent);
