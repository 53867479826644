import {
	IonButtons,
	IonHeader,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import React from 'react';
import Login from './Login';

const LoginPage: React.FC = () => {
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Join Skiwise</IonTitle>
				</IonToolbar>
			</IonHeader>
			<Login />
		</IonPage>
	);
};

export default LoginPage;
