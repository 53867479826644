import { FetchResult } from '@apollo/client';
import {} from '@ionic/react';

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	EditProfileMutation,
	Photo_Obj_Rel_Insert_Input,
} from '../../generated/graphql';
import { showErrorToast, showSuccessToast } from '../../utils/Toasts';
import { removeUndefinedFields } from '../../utils/UtilityFunctions';
import { getValidationResolver } from '../../utils/ValidationResolver';
import EditProfileView from './EditProfileView';
import { EditProfileModel } from './models/EditProfileModel';
import { EditProfileSchema } from './models/EditProfileSchema';

interface Props {
	userId: string;
	defaultValues: EditProfileModel;
	editProfile: (
		photo: Photo_Obj_Rel_Insert_Input | undefined,
		displayName: string,
		description: string
	) => Promise<
		FetchResult<EditProfileMutation, Record<string, any>, Record<string, any>>
	>;
}

const EditProfileLogic: React.FC<Props> = ({
	userId,
	defaultValues,
	editProfile,
}) => {
	const form = useForm<EditProfileModel>({
		mode: 'onSubmit',
		defaultValues: defaultValues,
		resolver: getValidationResolver(EditProfileSchema, (error) => ({
			...error,
			message: `This field ${error.message}`,
		})),
	});

	const onSubmit = (data: EditProfileModel) => {
		const uploadData = removeUndefinedFields({
			displayName: data.displayName,
			description: data.description,
			photo: data.photo
				? {
						data: data.photo,
				  }
				: undefined,
		});
		console.log(uploadData);
		editProfile(
			uploadData.photo,
			uploadData.displayName,
			uploadData.description
		)
			.then((d) => {
				console.log(d);
				form.reset(data);
				showSuccessToast('Updated Profile Successfully');
			})
			.catch((e) => {
				console.error(e);
				showErrorToast('Error updating profile. Please try again later.');
			});
	};

	const [isUploadingPhotos, setIsUploadingPhotos] = useState(false);

	return (
		<EditProfileView
			userId={userId}
			form={form}
			onSubmit={onSubmit}
			isUploadingPhotos={isUploadingPhotos}
			setIsUploadingPhotos={setIsUploadingPhotos}
		/>
	);
};

export default EditProfileLogic;
