import * as Joi from 'joi';

// must be built properly before exporting
export const CreateTrailSchema = Joi.object({
	name: Joi.string().min(3).max(40).required(),
	location: Joi.object({
		city: Joi.string(),
		state: Joi.string(),
		country: Joi.string(),
		lat: Joi.number().required(),
		lng: Joi.number().required(),
	}).required(),
	isOwner: Joi.boolean(),
	businessEmail: Joi.string().email({ tlds: { allow: false } }),
});
