import { IonIcon } from '@ionic/react';

import styled from '@emotion/styled';
import { chevronUp } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import useMeasure from 'use-measure';
import { md } from '../../theme/style-system';

interface Props {
	children?: React.ReactNode;
	title?: string;
}

const FormSection: React.FC<Props> = ({ children, title }) => {
	const [isOpened, setIsOpened] = useState(false);
	const contentRef = useRef<any>();
	const { height } = useMeasure(contentRef);

	const animateContent = useSpring({
		height: isOpened ? `${height + 10}px` : '0px',
	});

	const animateIcon = useSpring({
		transform: isOpened ? `rotate(180deg)` : 'rotate(0deg)',
	});

	const clickHandler = () => {
		setIsOpened(!isOpened);
		// if (!isOpened) {
		// 	if (contentRef.current) {
		// 		setTimeout(() => {
		// 			contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		// 		}, 100);
		// 	}
		// }
	};

	return (
		<Wrapper>
			<Header onClick={clickHandler}>
				<span>{title ?? ''}</span>
				<div>
					<StyledAnimatedDiv style={animateIcon}>
						<IonIcon className="icon" icon={chevronUp} />
					</StyledAnimatedDiv>
				</div>
			</Header>
			<ContentWrapper style={animateContent}>
				<Content ref={contentRef}>
					{children}
					<Buffer />
				</Content>
				<Buffer />
			</ContentWrapper>
		</Wrapper>
	);
};

export default FormSection;

const Wrapper = styled.div`
	max-width: ${md};
	margin: auto;
`;

const Buffer = styled.div`
	height: 20px;
	width: 100%;
`;

const Header = styled.div`
	width: 95%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: var(--ion-color-primary-tint);
	color: white;
	font-weight: bold;
	margin: auto;
	padding-right: 6px;
	padding-top: 2px;
	padding-left: 10px;
	padding-bottom: 2px;
	border-radius: 6px;
	margin-bottom: 10px;
	.icon {
		font-size: 2rem;
	}
`;

const ContentWrapper = styled(animated.div)`
	width: 90%;
	margin: auto;
	overflow: hidden;
`;

const Content = styled.div`
	border-left: 2px solid var(--ion-color-primary-tint);
`;

const StyledAnimatedDiv = styled(animated.div)`
	/* display: flex;
	justify-content: center;
	align-items: center; */
`;
