import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonModal,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { useCallback, useEffect, useRef } from 'react';
import { ActiveTrail } from '../../containers/ActiveTrailContainer';
import { TrailsMap } from '../../containers/TrailsMapContainer';
import { TrailFragment } from '../../generated/graphql';
import { useWindowSize } from '../../hooks/useWindowSize';
import TrailSearch from './TrailSearch';

interface Props {
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const TrailSearchModal: React.FC<Props> = ({ showModal, setShowModal }) => {
	const { width } = useWindowSize();
	const isModalInWindowMode = width && width < 768 ? true : false;
	const handleClose = useCallback(() => {
		setShowModal(false);
	}, [setShowModal]);
	const { setActiveTrail } = ActiveTrail.useContainer();
	const { setViewport } = TrailsMap.useContainer();

	const inputRef = useRef<any>();

	const handleSearchItemClick = useCallback(
		(trail: TrailFragment) => {
			setActiveTrail(trail);
			setViewport((current) => ({
				...current,
				// zoom:
				// 	viewport.zoom > MAX_ZOOM_TO_CREATE_CLUSTERS ? viewport.zoom : MAX_ZOOM_TO_CREATE_CLUSTERS,
				latitude: trail.location.lat,
				longitude: trail.location.lng,
				zoom: 10,
			}));
			setShowModal(false);
		},
		[setActiveTrail, setShowModal, setViewport]
	);

	useEffect(() => {
		if (showModal) {
			if (inputRef.current) {
				setTimeout(
					() => {
						if (inputRef.current) {
							inputRef.current.focus();
						}
					},
					isModalInWindowMode ? 600 : 300
				);
			}
		}
	}, [showModal, isModalInWindowMode]);

	return (
		<IonModal
			isOpen={showModal}
			animated={isModalInWindowMode}
			mode="md"
			onDidDismiss={handleClose}
		>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButton onClick={handleClose}>
							<IonIcon icon={close} />
						</IonButton>
					</IonButtons>
					<IonTitle>Search Trails</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<TrailSearch inputRef={inputRef} onSearchItemClick={handleSearchItemClick} />
			</IonContent>
		</IonModal>
	);
};

export default TrailSearchModal;
