import { gql } from '@apollo/client';
import React from 'react';
import ApolloErrorHandler from '../../components/ApolloErrorHandler';
import { ErrorLabel } from '../../components/ErrorLabel';
import SkeletonSpinner from '../../components/Skeletons/SkeletonSpinner';
import { Auth } from '../../containers/AuthContainer';
import {
	Trail_Report_Insert_Input,
	useTrailReportEditableQuery,
	useUploadTrailReportMutation,
} from '../../generated/graphql';
import CreateTrailReport from './CreateTrailReport';
import { TrailReportFormModel } from './models/TrailReportFormModel';

gql`
	mutation UploadTrailReport($object: trail_report_insert_input!) {
		insertTrailReport(object: $object) {
			...TrailReport
		}
	}
`;

const defaultTrailReport: Partial<TrailReportFormModel> = {
	rating: undefined,
	skiRecommendation: undefined,
	skiTime: undefined,
	snowType: undefined,
	weather: undefined,
	comment: undefined,
	photos: [],
	isGroomingReport: false,
};

interface Props {
	trailId: string;
	onClose?: () => any;
}

const CreateTrailReportApollo: React.FC<Props> = ({ trailId, onClose }) => {
	const { data, loading, error } = useTrailReportEditableQuery({
		variables: { trailId: trailId },
	});

	const [uploadTrailReportMutation] = useUploadTrailReportMutation();

	const { user } = Auth.useContainer();

	const uploadTrailReport = (data: Trail_Report_Insert_Input) => {
		return uploadTrailReportMutation({
			variables: {
				object: { ...data, trailId: trailId, ownerId: user?.id },
			},
		});
	};

	if (error) return <ApolloErrorHandler error={error} />;
	if (loading || !data) return <SkeletonSpinner />;
	if (!data.trail) return <ErrorLabel>Invalid trail selected.</ErrorLabel>;

	const trailReport = defaultTrailReport;

	return (
		<>
			{/* <IonLoading isOpen={uploadStatus.loading} message='Uploading Trail Report...' /> */}
			<CreateTrailReport
				initialTrailReport={trailReport}
				isGroomer={
					data.trail.admins.find((admin) => admin.user.id === user?.id)
						? true
						: false
				}
				uploadTrailReport={uploadTrailReport}
				onClose={() => {
					if (onClose) {
						onClose();
					}
				}}
			/>
		</>
	);
};

export default CreateTrailReportApollo;
