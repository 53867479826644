import { IonLabel, IonToggle } from '@ionic/react';

import styled from '@emotion/styled';
import React from 'react';

const IonToggleExtended: React.FC<any> = (props: any) => {
	return (
		<Wrapper isSelected={props.checked}>
			<IonLabel className="first">No</IonLabel>
			<IonToggle color="primary" {...props} />
			<IonLabel className="last">Yes</IonLabel>
		</Wrapper>
	);
};

export default IonToggleExtended;

const Wrapper = styled.div<{ isSelected?: boolean }>`
	display: flex;
	padding: 10px;
	align-items: center;
	.first {
		padding-right: 10px;
		color: ${(props) =>
			props.isSelected
				? 'var(--ion-color-light-shade)'
				: 'var(--ion-color-dark)'};
		font-weight: bold;
	}

	.last {
		padding-left: 10px;
		color: ${(props) =>
			props.isSelected
				? 'var(--ion-color-primary)'
				: 'var(--ion-color-light-shade)'};
		font-weight: bold;
	}
`;
