import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Star } from '../icons/Icons';

interface Props {
	count: number;
	size: number;
	selectedColor: string;
	deselectedColor: string;
	starIcon: string;
	onChange?: any;
}

const StarRatingSelector: React.FC<Props> = ({ count, size, onChange }) => {
	const [selectedCount, setSelectedCount] = useState<number>(0);
	const [hoverCount, setHoverCount] = useState<number | null>();
	function handleSelect(index: number) {
		setSelectedCount(index);
		if (onChange) {
			onChange(index);
		}
	}

	const stars = () => {
		let stars = [];
		for (let i = 1; i < count + 1; i++) {
			stars.push(
				<div
					onMouseEnter={() => setHoverCount(i)}
					onMouseLeave={() => setHoverCount(null)}
					className="p-2"
				>
					<StarItem
						index={i}
						onClick={handleSelect}
						isHovered={Boolean(hoverCount && hoverCount >= i)}
						isSelected={selectedCount >= i}
					/>
				</div>
			);
		}
		return stars;
	};

	return <div className="flex space-x-0">{stars()}</div>;
};

export default StarRatingSelector;

interface StarItemProps {
	index: number;
	onClick: (index: number) => void;
	isSelected: boolean;
	isHovered: boolean;
}

const StarItem = ({ index, onClick, isSelected, isHovered }: StarItemProps) => {
	return (
		<Star
			onClick={() => onClick(index)}
			className={twMerge(
				'w-12 h-12  text-gray-300 cursor-pointer rounded',
				isHovered && 'text-primary-500 opacity-50',
				isSelected && 'text-primary-500 opacity-100'
			)}
		/>
	);
};
