import {
	differenceInDays,
	differenceInHours,
	differenceInMinutes,
	differenceInMonths,
	differenceInSeconds,
	differenceInYears,
	formatDistanceStrict,
} from 'date-fns';
import { Bounds } from 'google-map-react';
import { TrailReportFragment } from '../generated/graphql';

//Check if an array is empty OR doesn't exist
export const isEmpty = (array: any[]): boolean => {
	return !array || array.length === 0;
};

export const isEmptyObj = (obj: Object) => {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
};

//Remove empty fields from an object
export const removeUndefinedFields = <T extends Record<string, any>>(obj: T): T => {
	return Object.keys(obj).reduce((acc, key) => {
		if (obj[key] !== undefined) {
			if (typeof obj[key] === 'object') {
				acc[key as keyof T] = removeUndefinedFields(obj[key]);
			} else {
				acc[key as keyof T] = obj[key];
			}
		}
		return acc;
	}, {} as T);
};

export function getSqueezedBoundingBox(bounds: Bounds, maxBoxSize: number) {
	const x1 = bounds.se.lng;
	const x0 = bounds.sw.lng;
	const y0 = bounds.sw.lat;
	const y1 = bounds.nw.lat;

	let xBound = {
		lng0: bounds.nw.lng,
		lng1: bounds.se.lng,
	};

	let yBound = {
		lat0: bounds.se.lat,
		lat1: bounds.nw.lat,
	};

	if (Math.abs(x1 - x0) > maxBoxSize) {
		// too big on x axis
		const xCenter = (x1 - x0) / 2 + x0;
		xBound = {
			lng0: xCenter - maxBoxSize / 2,
			lng1: xCenter + maxBoxSize / 2,
		};
	}

	if (Math.abs(y1 - y0) > maxBoxSize) {
		const yCenter = (y1 - y0) / 2 + y0;
		yBound = {
			lat0: yCenter - maxBoxSize / 2,
			lat1: yCenter + maxBoxSize / 2,
		};
	}

	return [xBound.lng0, yBound.lat0, xBound.lng1, yBound.lat1];
}

interface TimeSinceOptions {
	mini?: boolean;
}

export function timeSince(timestamptz: string, options: TimeSinceOptions = {}) {
	const timestamp = new Date(timestamptz);
	const now = new Date();

	if (!options.mini)
		return `${formatDistanceStrict(timestamp, now, {
			roundingMethod: 'floor',
			addSuffix: true,
		})}`;

	const yearsDiff = differenceInYears(now, timestamp);
	const monthsDiff = differenceInMonths(now, timestamp);
	const daysDiff = differenceInDays(now, timestamp);
	const hoursDiff = differenceInHours(now, timestamp);
	const minutesDiff = differenceInMinutes(now, timestamp);
	const secondsDiff = differenceInSeconds(now, timestamp);

	if (yearsDiff > 0) return `${yearsDiff}y`;
	if (monthsDiff > 0) return `${monthsDiff}mo`;
	if (daysDiff > 0) return `${daysDiff}d`;
	if (hoursDiff > 0) return `${hoursDiff}h`;
	if (minutesDiff > 0) return `${minutesDiff}m`;
	if (secondsDiff > 10) return `${secondsDiff}s`;

	return `just now`;
}

// a season = july XXXX -> june XXXY
export function groupTrailReportsBySeason(trailReports: TrailReportFragment[]) {
	let groupings: { [key: string]: TrailReportFragment[] } = {};
	trailReports.forEach((tr) => {
		const date = new Date(tr.createdAt);
		const isPartOfPreviousSeason = date.getMonth() <= 6;
		if (isPartOfPreviousSeason) {
			const index = `${date.getUTCFullYear() - 1} - ${date.getUTCFullYear()} Season`;
			if (!groupings[index]) groupings[index] = [];
			groupings[index].push(tr);
		} else {
			const index = `${date.getUTCFullYear()} - ${date.getUTCFullYear() + 1} Season`;
			if (!groupings[index]) groupings[index] = [];
			groupings[index].push(tr);
		}
	});

	return groupings;
}

export const getMostRecentTrailReportAge = (trailReports: { id: string; createdAt: string }[]) => {
	if (!trailReports || trailReports.length === 0) {
		return undefined;
	}

	const createdAt = new Date(trailReports[0].createdAt);

	if (createdAt < new Date(Date.now() - 1000 * 60 * 60 * 24 * 180)) {
		// Older than 180 days
		return undefined;
	}

	return trailReports[0].createdAt;
};
