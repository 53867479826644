import React from 'react';
import FixedContainer from '../../../components/FixedContainer';
import { ActiveTrail } from '../../../containers/ActiveTrailContainer';
import { xs } from '../../../theme/style-system';
import TrailCard from './TrailCard';
import { Transition } from '@headlessui/react';

const ActiveTrailCard: React.FC = () => {
	const { activeTrail } = ActiveTrail.useContainer();

	return (
		<Transition
			show={activeTrail !== null}
			enter="transition-opacity duration-200"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="transition-opacity"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			<FixedContainer maxWidth={xs}>
				{activeTrail && <TrailCard trail={activeTrail} />}
			</FixedContainer>
		</Transition>
	);
};

export default ActiveTrailCard;
