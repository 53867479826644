import styled from '@emotion/styled';
import {} from '@ionic/react';

import React from 'react';

interface Props {
	children?: React.ReactNode;
	img: string;
	overlay?: string;
	blur?: number;
}

const Background: React.FC<Props> = ({ children, img, overlay, blur = 0 }) => {
	return (
		<BackgroundWrapper img={img}>
			<BackgroundOverlay overlay={overlay} blur={blur}>
				{children}
			</BackgroundOverlay>
		</BackgroundWrapper>
	);
};

export default Background;

const BackgroundWrapper = styled.div<{ img: string }>`
	background-image: ${(props) => `url(${props.img})`};
	background-size: cover;
	background-position: center;
	height: 100%;
	width: 100%;
`;

const BackgroundOverlay = styled.div<{ overlay?: string; blur: number }>`
	background-color: ${(props) => (props.overlay ? props.overlay : 'none')};
	height: 100%;
	width: 100%;
	backdrop-filter: ${(props) => `blur(${props.blur}px)`};
`;
