import {} from '@ionic/react';

import React from 'react';
import ApolloErrorHandler from '../../components/ApolloErrorHandler';
import SkeletonSpinner from '../../components/Skeletons/SkeletonSpinner';
import { useTrailFeedQuery } from '../../generated/graphql';
import FavoritesFeed from './FavoritesFeed';

interface Props {
	userId: string;
}

const FavoritesFeedApollo: React.FC<Props> = ({ userId }) => {
	const { data, loading, error } = useTrailFeedQuery({
		variables: { userId: userId },
		fetchPolicy: 'cache-and-network',
	});

	if (error) return <ApolloErrorHandler error={error} />;
	if (!data || loading) return <SkeletonSpinner />;

	return <FavoritesFeed data={data}></FavoritesFeed>;
};

export default FavoritesFeedApollo;
