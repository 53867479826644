import { fetchAndActivate, getAll } from 'firebase/remote-config';
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { remoteConfig } from '../firebase';
import { App } from '@capacitor/app';

remoteConfig.settings = {
	minimumFetchIntervalMillis: 3600000,
	fetchTimeoutMillis: 3600000,
};

remoteConfig.defaultConfig = {
	max_recent_searches_for_searchbar: 3,
	show_share_banner: false,
	share_banner_text: 'We could use your help!',
};

const useConfig = () => {
	const [config, setConfig] = useState(getAll(remoteConfig));

	useEffect(() => {
		let listener = App.addListener('appStateChange', () => {
			fetchAndActivate(remoteConfig)
				.then(() => {
					console.debug(
						'[Remote config] fetch successful. ',
						getAll(remoteConfig)
					);
					setConfig(getAll(remoteConfig));
				})
				.catch((e) => console.error('[Remote config] fetch error: ', e));
		});

		return () => {
			listener?.remove();
		};
	}, []);

	return { config };
};

export const Config = createContainer(useConfig);
