import styled from '@emotion/styled';
import { IonLabel, IonList } from '@ionic/react';
import Collapse, { Panel } from 'rc-collapse';

import React from 'react';
import ErrorPage from '../../../components/ErrorPage';
import { FormBottomPadding } from '../../../components/Form/FormComponents';
import { TrailDetailsFragment } from '../../../generated/graphql';
import { sm } from '../../../theme/style-system';
import { groupTrailReportsBySeason } from '../../../utils/UtilityFunctions';
import TrailReportInfiniteList from './components/TrailReportInfiniteList';

interface Props {
	trail: TrailDetailsFragment;
}

const TrailReports: React.FC<Props> = ({ trail }) => {
	const groupedTrailReports = groupTrailReportsBySeason(trail.trailReports);

	return (
		<Wrapper>
			<IonList>
				{trail.trailReports.length !== 0 ? (
					<div className="flex flex-col px-2 py-4 space-y-4">
						{Object.entries(groupedTrailReports).map(([key, values], index) =>
							index === 0 ? (
								<div key={key}>
									<h2 className="pt-4 pb-2 pl-10 text-lg font-bold">
										{key} ({values.length} report{values.length !== 1 && 's'})
									</h2>
									<TrailReportInfiniteList
										key={key}
										title={key}
										trailReports={values}
									/>
								</div>
							) : (
								<Collapse key={key}>
									<Panel
										header={
											<IonLabel>
												{key} ({values.length} report
												{values.length !== 1 && 's'})
											</IonLabel>
										}
									>
										<TrailReportInfiniteList
											key={key}
											title={key}
											trailReports={values}
										/>
									</Panel>
								</Collapse>
							)
						)}
					</div>
				) : (
					<ErrorPage>
						There's no trail reports for this trail. Create one now!
					</ErrorPage>
				)}
			</IonList>
			<FormBottomPadding />
		</Wrapper>
	);
};

export default TrailReports;

const Wrapper = styled.div`
	max-width: ${sm};
	margin: auto;
`;
