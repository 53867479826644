import styled from '@emotion/styled';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { createContainer } from 'unstated-next';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

// Necessary swiperjs styles
import 'swiper/css';
import 'swiper/css/pagination';
import '@ionic/react/css/ionic-swiper.css';

function usePhotoViewer() {
	const [photos, setPhotos] = useState<string[]>([]);
	const [show, setShow] = useState(false);
	const [initialPhoto, setInitialPhoto] = useState(0);

	const showPhotoReel = useCallback(
		(photos: string[] | undefined, activeIndex: number) => {
			if (photos) {
				setPhotos(photos);
				setInitialPhoto(activeIndex);
				setShow(true);
			}
		},
		[]
	);

	const hidePhotoReel = () => setShow(false);

	return { show, initialPhoto, photos, hidePhotoReel, showPhotoReel };
}

export const PhotoViewer = createContainer(usePhotoViewer);

interface Props {
	children?: React.ReactNode;
}

const PhotoViewerContainer: React.FC<Props> = ({ children }) => {
	return (
		<PhotoViewer.Provider>
			<Viewer>{children}</Viewer>
		</PhotoViewer.Provider>
	);
};

export default PhotoViewerContainer;

const Viewer: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	const slidesRef = useRef<any>(null);
	const viewer = PhotoViewer.useContainer();
	const { show, photos, initialPhoto, hidePhotoReel } = viewer;

	const slideOpts = useMemo(() => {
		const options: SwiperProps = {
			initialSlide: initialPhoto,
			centeredSlides: true,
			// slidesPerView: 1.1,
			slidesPerView: 1.1,
			breakpoints: {
				600: {
					slidesPerView: 2.1,
				},
			},
		};

		return options;
	}, [initialPhoto]);

	return (
		<>
			{show && (
				<Overlay onClick={() => hidePhotoReel()}>
					<StyledIonSlides
						ref={slidesRef}
						modules={[Pagination]}
						pagination={true}
						{...slideOpts}
					>
						{photos.map((photo, index) => (
							<SwiperSlide key={photo}>
								<SlideContainer>
									<Img src={photo} onClick={(e) => e.stopPropagation()} />
								</SlideContainer>
							</SwiperSlide>
						))}
					</StyledIonSlides>
				</Overlay>
			)}
			{children}
		</>
	);
};

const Overlay = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	z-index: 100;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
`;

const StyledIonSlides = styled(Swiper)`
	width: 100%;
`;

const Img = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
	margin: auto;
	float: middle;
	/* padding: 40px; */
`;

const SlideContainer = styled.div`
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`;
