// import {
// 	AdMob,
// 	AdOptions,
// 	BannerAdOptions as BannerAdOptionsSchema,
// 	BannerAdPosition,
// 	BannerAdSize,
// } from '@capacitor-community/admob';

// import { Capacitor } from '@capacitor/core';
// import { useEffect, useState } from 'react';
// import { useLocation } from 'react-router';
import { createContainer } from 'unstated-next';
// import { IS_ADS_DISABLED_BY_DEFAULT } from '../constants/consts';
// const isAvailable = Capacitor.isPluginAvailable('AdMob');

// const ADMOB_PROJECT_ID = process.env.REACT_APP_ADMOB_PROJECT_ID;
// if (!ADMOB_PROJECT_ID) {
// 	throw Error('Error: No environment variable set. [REACT_APP_PROJECT_ID]');
// }

// const BANNER_AD_ID = process.env.REACT_APP_BANNER_AD_ID;
// if (!BANNER_AD_ID) {
// 	throw Error('Error: No environment variable set. [REACT_APP_BANNER_AD_ID]');
// }

// const REWARD_VIDEO_AD_ID = process.env.REACT_APP_REWARD_VIDEO_AD_ID;
// if (!REWARD_VIDEO_AD_ID) {
// 	throw Error('Error: No environment variable set. [REACT_APP_REWARD_VIDEO_AD_ID]');
// }

// const INTERSTITIAL_VIDEO_AD_ID = process.env.REACT_APP_INTERSTITIAL_VIDEO_AD_ID;
// if (!INTERSTITIAL_VIDEO_AD_ID) {
// 	throw Error('Error: No environment variable set. [REACT_APP_INTERSTITIAL_VIDEO_AD_ID]');
// }

// const BannerAdOptions: BannerAdOptionsSchema = {
// 	// adId: 'ca-app-pub-3288576213247441/5071809490',
// 	adId: BANNER_AD_ID, //Test ad account
// 	adSize: BannerAdSize.BANNER,
// 	position: BannerAdPosition.BOTTOM_CENTER,
// };

// const RewardVideoAdOptions: AdOptions = {
// 	//Reward Video
// 	adId: REWARD_VIDEO_AD_ID, //Test ad account
// };

// const InterstitialVideoAdOptions: AdOptions = {
// 	adId: INTERSTITIAL_VIDEO_AD_ID,
// };

// const BANNER_AD_LOCATION = '/trails';
// const REWARD_VIDEO_AD_LOCATION = '/never-going-to-hit-this-route';
// const INTERSTITIAL_VIDEO_AD_LOCATION = '/trails/';

// const AD_FREQUENCY = 0; // Show 1 in every N times.

const useAdManager = () => {
	// const [showBanner, setShowBanner] = useState(false);
	// const [adShowingsCount, setAdShowingsCount] = useState(0);
	// const [isAdsDisabled] = useState(IS_ADS_DISABLED_BY_DEFAULT);

	// //Initialization
	// useEffect(() => {
	// 	if (isAvailable && !isAdsDisabled) {
	// 		AdMob.initialize({});
	// 		console.debug('[Admob] initialized');

	// 		AdMob.prepareInterstitial(InterstitialVideoAdOptions).then(console.debug, console.error);

	// 		AdMob.prepareRewardVideoAd(RewardVideoAdOptions).then(console.debug, console.error);
	// 	}
	// }, [isAdsDisabled]);

	// const { pathname } = useLocation();

	// //On Route Change
	// useEffect(() => {
	// 	if (isAvailable && !isAdsDisabled) {
	// 		if (pathname === BANNER_AD_LOCATION) {
	// 			showBannerAd();
	// 		} else {
	// 			hideBannerAd();
	// 		}

	// 		if (pathname === REWARD_VIDEO_AD_LOCATION) {
	// 			showVideoAd();
	// 		}

	// 		if (pathname.startsWith(INTERSTITIAL_VIDEO_AD_LOCATION)) {
	// 			if (adShowingsCount % AD_FREQUENCY === 1) {
	// 				console.debug('Showing interstitial.');
	// 				showInterstitialAd();
	// 			} else {
	// 				console.debug('Ad skipped this time.', adShowingsCount);
	// 			}
	// 			setAdShowingsCount((adCount) => adCount + 1);
	// 		}
	// 	} else {
	// 		console.debug('Not available');
	// 	}
	// }, [pathname, isAdsDisabled]);

	// // AdMob.addListener('onAdLoaded', () => {
	// // 	setShowBanner(true);
	// // });

	// // AdMob.addListener('onAdFailedToLoad', () => {
	// // 	setShowBanner(false);
	// // });

	// //Execute methods
	// const showBannerAd = () => {
	// 	AdMob.showBanner(BannerAdOptions).then(
	// 		(value) => {
	// 			setShowBanner(true);
	// 		},
	// 		(error: any) => console.error(error)
	// 	);
	// };

	// const hideBannerAd = () => {
	// 	AdMob.hideBanner().then(
	// 		(value: any) => setShowBanner(false), // true
	// 		(error: any) => console.error(error) // show error
	// 	);
	// };

	// const showVideoAd = () => {
	// 	AdMob.showRewardVideoAd().then(() => {
	// 		AdMob.prepareRewardVideoAd(RewardVideoAdOptions).then(console.debug, console.error);
	// 	}, console.error);
	// };

	// const showInterstitialAd = () => {
	// 	AdMob.showInterstitial().then(() => {
	// 		AdMob.prepareInterstitial(InterstitialVideoAdOptions).then(console.debug, console.error);
	// 	}, console.error);
	// };

	return {
		// showBannerAd,
		// hideBannerAd,
		showBanner: false,
	};
};

export const AdManager = createContainer(useAdManager);
