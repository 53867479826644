import { IonContent, IonHeader, IonLoading, IonPage, IonTitle, IonToolbar } from '@ionic/react';

import { gql } from '@apollo/client';
import React from 'react';
import { Redirect } from 'react-router';
import SkeletonSpinner from '../../components/Skeletons/SkeletonSpinner';
import { DEFAULT_USER_PHOTO_ID, SKIWISE_LOGO } from '../../constants/consts';
import { Auth } from '../../containers/AuthContainer';
import {
	useCheckIfAccountExistsLazyQuery,
	useCreateAccountMutation,
} from '../../generated/graphql';
import { showErrorToast, showSuccessToast } from '../../utils/Toasts';
import { LogoContainer } from '../onboarding/components/LogoContainer';
import CreateAccount, { CreateAccountFormModel } from './CreateAccount';

//todo - remove photoId from here
gql`
	mutation CreateAccount(
		$id: String!
		$displayName: String!
		$photoId: uuid!
		$email: String
		$disabled: Boolean
	) {
		insertUser(
			object: {
				id: $id
				displayName: $displayName
				photoId: $photoId
				email: $email
				disabled: $disabled
			}
		) {
			id
			displayName
		}
	}
`;

const CreateAccountPage: React.FC = () => {
	const { user } = Auth.useContainer();
	const [createAccount, uploadStatus] = useCreateAccountMutation();
	const [fetchUserAccount, { data, loading }] = useCheckIfAccountExistsLazyQuery({
		variables: { id: user?.id || '' },
		fetchPolicy: 'network-only',
	});

	if (loading) return <SkeletonSpinner />;
	if (data?.user?.id) return <Redirect to={'/'} />;

	const handleSubmit = (data: CreateAccountFormModel) => {
		// executeCall(formData);
		createAccount({
			variables: {
				id: user?.id || 'ERROR_SOMETHING_WENT_WRONG',
				displayName: data.displayName,
				photoId: DEFAULT_USER_PHOTO_ID,
				email: user?.data.email || undefined,
				disabled: false,
			},
		}).then(() => fetchUserAccount({ variables: { id: user?.id || '' } }));
	};

	if (uploadStatus.error && !uploadStatus.loading) {
		console.error(uploadStatus.error.message);
		showErrorToast('Error creating user. Please try again later.');
	}

	if (uploadStatus.data && !uploadStatus.loading) {
		console.log(uploadStatus);
		showSuccessToast(`Welcome ${uploadStatus.data.insertUser?.displayName || 'skier'}!`);
	}

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color="primary">
					<IonTitle>Create Your Account</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonLoading isOpen={uploadStatus.loading} message={'Creating Profile...'} />

				<LogoContainer>
					<img src={SKIWISE_LOGO} alt="skiwise logo" />
				</LogoContainer>

				<CreateAccount onSubmit={handleSubmit} />
			</IonContent>
		</IonPage>
	);
};

export default CreateAccountPage;
