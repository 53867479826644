import { IonButton, IonIcon, IonItem } from '@ionic/react';
import { chevronDown } from 'ionicons/icons';
import React, { useState } from 'react';
import { TrailFragment } from '../../../generated/graphql';
import { IonNoteSmall } from '../../Form/FormComponents';
import SearchListItem from '../TrailSearchItem';
import { useTrails } from '../../../containers/TrailsContainer';

interface Props {
	noItemsLabel: string;
	onSearchItemClick: (trail: TrailFragment) => void;
	limit: number;
}

const TrailsSearchItems: React.FC<Props> = ({ noItemsLabel, onSearchItemClick, limit }) => {
	const { data } = useTrails();
	const [itemsShown, setItemsShown] = useState(limit);

	// const trailsSortedByReportDate = (data?.trails || []).sort((a, b) => {
	// 	console.log(a, b);

	// 	if (
	// 		(!a?.trailReports || a.trailReports.length == 0) &&
	// 		(!b?.trailReports[0] || a.trailReports.length == 0)
	// 	)
	// 		return 0;
	// 	if (!a?.trailReports || a.trailReports.length == 0) return 1;
	// 	if (!b?.trailReports || b.trailReports.length == 0) return -1;

	// 	const createdAtA = new Date(a.trailReports[0].createdAt).getTime();
	// 	const createdAtB = new Date(b.trailReports[0].createdAt).getTime();

	// 	// Sort by createdAt date in descending order
	// 	return createdAtB - createdAtA;
	// });

	const trailsSearchItems = (
		data?.recentTrailSearches && data.recentTrailSearches.length > 0
			? data?.recentTrailSearches
			: []
	).map((trail) => ({
		key: trail.id,
		trail: trail,
	}));

	return (
		<>
			{trailsSearchItems.length > 0 ? (
				<>
					{trailsSearchItems.slice(0, itemsShown).map((item) => (
						<SearchListItem key={item.key} item={item} onSearchItemClick={onSearchItemClick} />
					))}
					{trailsSearchItems.length > itemsShown && (
						<IonButton size="small" fill="clear" onClick={() => setItemsShown(itemsShown + 70)}>
							Show more
							<IonIcon icon={chevronDown} />
						</IonButton>
					)}{' '}
				</>
			) : (
				<IonItem lines="none">
					<IonNoteSmall>{noItemsLabel}</IonNoteSmall>
				</IonItem>
			)}
		</>
	);
};

export default TrailsSearchItems;
