import { FetchResult } from '@apollo/client';
import styled from '@emotion/styled';
import { ErrorMessage } from '@hookform/error-message';
import { joiResolver } from '@hookform/resolvers';
import { IonButton, IonContent } from '@ionic/react';
import { RateApp } from 'capacitor-rate-app';

import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import PhotoUploadGallery from '../../components/AttachFiles/PhotoUploadGallery';
import {
	FormBottomPadding,
	FormError,
	FormItem,
	FormLabel,
	FormTextarea,
	FormTopPadding,
} from '../../components/Form/FormComponents';
import IonChipSelect from '../../components/Form/IonChipSelect';
import IonToggleExtended from '../../components/Form/IonToggleExtended';
import StarRatingSelector from '../../components/Form/StarRatingSelector';
import { STAR_ICON } from '../../constants/consts';
import { Trail_Report_Insert_Input, UploadTrailReportMutation } from '../../generated/graphql';
import { SkiRecommendation } from '../../types/TrailReportAttributes/SkiRecommendation';
import { SkiTime } from '../../types/TrailReportAttributes/SkiTime';
import { SnowType } from '../../types/TrailReportAttributes/SnowType';
import { WeatherType } from '../../types/TrailReportAttributes/WeatherType';
import { showErrorToast, showSuccessToast } from '../../utils/Toasts';
import { removeUndefinedFields } from '../../utils/UtilityFunctions';
import { TrailReportFormModel } from './models/TrailReportFormModel';
import { TrailReportFormSchema } from './models/TrailReportFormSchema';

const SELECTIONS = [
	{
		id: 'snowType',
		name: 'Snow Type',
		values: ['Packed Snow', 'Icy', 'Slushie', 'Powder'] as SnowType[],
	},
	{
		id: 'weather',
		name: 'Weather',
		values: ['Sunny', 'Snowing', 'Raining'] as WeatherType[],
	},
	{
		id: 'skiTime',
		name: 'Ski Time',
		values: ['Morning', 'Daytime', 'Night'] as SkiTime[],
	},
	{
		id: 'skiRecommendation',
		name: 'Ski Recommendation',
		values: ['Use A-Skis', 'Use B-Skis', 'Use Rock-Skis'] as SkiRecommendation[],
	},
];

interface Props {
	initialTrailReport: Partial<TrailReportFormModel>;
	isGroomer: boolean;
	uploadTrailReport: (
		data: Trail_Report_Insert_Input
	) => Promise<FetchResult<UploadTrailReportMutation, Record<string, any>, Record<string, any>>>;
	onClose: () => any;
}

const CreateTrailReport: React.FC<Props> = ({
	initialTrailReport,
	uploadTrailReport,
	onClose,
	isGroomer,
}) => {
	const form = useForm<TrailReportFormModel>({
		resolver: joiResolver(TrailReportFormSchema),
		defaultValues: initialTrailReport,
	});

	const [isUploadingPhotos, setIsUploadingPhotos] = useState(false);

	const onSubmit = (data: TrailReportFormModel) => {
		const uploadData = removeUndefinedFields({
			rating: data.rating,
			skiRecommendation: data.skiRecommendation,
			skiTime: data.skiTime,
			snowType: data.snowType,
			weather: data.weather,
			comment: data.comment,
			photos:
				data.photos && data.photos.length > 0
					? {
							data: data.photos.map((photo) => ({
								photo: {
									data: photo,
								},
							})),
					  }
					: undefined,
			isGroomingReport: data.isGroomingReport,
		});

		console.log(uploadData);

		uploadTrailReport(uploadData)
			.then(() => {
				showSuccessToast('Trail Report Submitted Successfully');
				if (RateApp) RateApp.requestReview();
				if (onClose) onClose();
			})
			.catch((err) => {
				console.error(err);
				showErrorToast('Error submitting trail report. Please try again later.');
			});
	};
	return (
		<>
			<IonContent>
				<FormProvider {...form}>
					<FormTopPadding></FormTopPadding>
					<ErrorMessage
						name="trailName"
						render={() => (
							<FormItem>
								<FormError>
									Error connecting to database right now. Please try again later.
								</FormError>
							</FormItem>
						)}
					/>

					<FormItem>
						<FormLabel>Trail Conditions</FormLabel>
						<RatingBox>
							<Controller
								name="rating"
								render={({ onChange }) => (
									<StarRatingSelector
										onChange={onChange}
										count={5}
										size={50}
										selectedColor="var(--ion-color-primary)"
										deselectedColor="var(--ion-color-medium)"
										starIcon={STAR_ICON}
									/>
								)}
							/>
						</RatingBox>
						<ErrorMessage
							name="rating"
							render={({ message }) => <FormError>{message}</FormError>}
						/>
					</FormItem>

					{isGroomer && (
						<FormItem>
							<FormLabel>Is This A Grooming Report?</FormLabel>
							<Controller
								name="isGroomingReport"
								render={({ onChange, value }) => (
									<IonToggleExtended
										onIonChange={(e: any) => onChange(e.detail.checked)}
										checked={value}
									/>
								)}
							/>
							<ErrorMessage
								name="isGroomingReport"
								render={({ message }) => <FormError>{message}</FormError>}
							/>
						</FormItem>
					)}

					<FormItem>
						<FormLabel>Trail Report Message</FormLabel>
						<Controller
							name="comment"
							render={({ onChange, value }) => (
								<FormTextarea
									onIonChange={(e: any) => onChange(e.detail.value)}
									value={value}
									inputMode="text"
									mode="md"
									rows={8}
									placeholder="How was your ski today?..."
									autocapitalize="true"
									spellcheck
									autoCorrect="on"
								/>
							)}
						/>
						<ErrorMessage
							name="comment"
							render={({ message }) => <FormError>{message}</FormError>}
						/>
					</FormItem>

					<FormItem>
						<FormLabel>Upload Pictures</FormLabel>

						<Controller
							name="photos"
							render={({ onChange }) => (
								<PhotoUploadGallery
									onChange={onChange}
									onStatusChange={(status) =>
										status.loading ? setIsUploadingPhotos(true) : setIsUploadingPhotos(false)
									}
									uploadBucketName="trailreport_photos"
									maxItems={8}
									options={{
										showClearButton: true,
										showUploadProgress: true,
										showAsCropped: false,
									}}
								/>
							)}
						/>
						<ErrorMessage
							name="photos"
							render={({ message }) => <FormError>{message}</FormError>}
						/>
					</FormItem>

					{SELECTIONS.map((tag) => (
						<FormItem key={tag.id}>
							<FormLabel>{tag.name}</FormLabel>
							<IonChipSelect tag={tag} />
							<ErrorMessage
								name={tag.id}
								render={({ message }) => <FormError>{message}</FormError>}
							/>
						</FormItem>
					))}

					<FormItem>
						<IonButton
							disabled={isUploadingPhotos}
							style={{ width: '100%' }}
							expand="block"
							fill="solid"
							onClick={form.handleSubmit(onSubmit)}
							// onClick={() => console.log(form.getValues())}
						>
							Post Trail Report
						</IonButton>
					</FormItem>
					<FormBottomPadding />
				</FormProvider>
			</IonContent>
			{/* <FixedContainer position='bottom'>
				<WrapperWrapper>
					<BottomButtonWrapper>
						<IonButton
							disabled={isUploadingPhotos}
							style={{ width: '100%' }}
							expand='block'
							fill='solid'
							onClick={form.handleSubmit(onSubmit)}
							// onClick={() => console.log(form.getValues())}
						>
							Post Trail Report
						</IonButton>
					</BottomButtonWrapper>
				</WrapperWrapper>
			</FixedContainer> */}
		</>
	);
};

export default CreateTrailReport;

const RatingBox = styled.div`
	display: flex;
	justify-content: center;
`;
