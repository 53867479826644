import { gql } from '@apollo/client';
import { IonLoading } from '@ionic/react';

import React from 'react';
import ApolloErrorHandler from '../../components/ApolloErrorHandler';
import { ErrorLabel } from '../../components/ErrorLabel';
import SkeletonSpinner from '../../components/Skeletons/SkeletonSpinner';
import {
	Trail_Set_Input,
	useEditTrailMutation,
	useTrailForEditingQuery,
} from '../../generated/graphql';
import { removeUndefinedFields } from '../../utils/UtilityFunctions';
import EditTrail from './EditTrail';

gql`
	mutation EditTrail($id: uuid!, $object: trail_set_input) {
		updateTrail(pk_columns: { id: $id }, _set: $object) {
			id
			name
			isClassicAllowed
			isDogsAllowed
			isLighted
			isSkateAllowed
			isSnowmaking
			description
			passDayCost
			passIsRequired
			passPurchaseURL
			passSeasonCost
			photoId
			trailsLength
			websiteURL
			facebookURL
			instagramURL
			owner {
				id
				displayName
				# photo {
				# 	downloadURL
				# }
				membershipType
			}
		}
	}
`;

interface Props {
	trailId: string;
	pageRef: any;
	onClose: () => any;
}

const EditTrailApollo: React.FC<Props> = ({ trailId, pageRef, onClose }) => {
	const { data, loading, error } = useTrailForEditingQuery({
		variables: { id: trailId },
	});

	const [editTrailMutation, { loading: uploading }] = useEditTrailMutation();

	const editTrail = (object: Trail_Set_Input) => {
		return editTrailMutation({ variables: { id: trailId, object } });
	};

	if (error) return <ApolloErrorHandler error={error} />;
	if (loading || !data) return <SkeletonSpinner />;
	if (!data.trail)
		return (
			<ErrorLabel>
				This trail could not be found. If you think this is an error please fill
				out a bug report.
			</ErrorLabel>
		);

	const trail = data.trail;

	const defaultValues = {
		name: trail.name,
		location: {
			lat: trail.location.lat,
			lng: trail.location.lng,
		},
		...removeUndefinedFields({
			//Basic Info
			description: trail.description ?? undefined,
			isClassicAllowed: trail.isClassicAllowed,
			isSkateAllowed: trail.isSkateAllowed,
			trailsLength: trail.trailsLength ?? undefined,
			isLighted: trail.isLighted,
			isSnowmaking: trail.isSnowmaking,
			isDogsAllowed: trail.isDogsAllowed,
			//Pass
			passIsRequired: trail.passIsRequired,
			passDayCost: trail.passDayCost ?? undefined,
			passSeasonCost: trail.passSeasonCost ?? undefined,
			passPurchaseURL: trail.passPurchaseURL ?? undefined,
			//Links
			websiteURL: trail.websiteURL ?? undefined,
			facebookURL: trail.facebookURL ?? undefined,
			instagramURL: trail.instagramURL ?? undefined,

			businessEmail: trail.businessEmail ?? undefined,
		}),
	};

	return (
		<>
			<IonLoading isOpen={uploading} message="Updating Trail..." />
			<EditTrail
				pageRef={pageRef}
				defaultValues={defaultValues}
				editTrail={editTrail}
				onClose={onClose}
			/>
		</>
	);
};

export default EditTrailApollo;
