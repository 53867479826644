import styled from '@emotion/styled';
import { IonIcon } from '@ionic/react';
import { chevronForward, trailSign } from 'ionicons/icons';

import React from 'react';
import { useHistory } from 'react-router';
import UserAvatar from '../../../components/UserAvatar';
import { STAR_ICON } from '../../../constants/consts';
import { TrailFragment, useTrailDetailsQuery } from '../../../generated/graphql';
import { timeSince } from '../../../utils/UtilityFunctions';
import { Auth } from '../../../containers/AuthContainer';

interface Props {
	trail: TrailFragment;
}

const TrailCard: React.FC<Props> = ({ trail }) => {
	const history = useHistory();

	const { user } = Auth.useContainer();

	// Start preloading the trail details query
	useTrailDetailsQuery({
		variables: { id: trail.id, userId: user?.id || '__NOT_AUTHENTICATED__' },
		fetchPolicy: 'cache-and-network',
	});

	function getStars(quantity: number) {
		const stars = [];
		for (let i = 0; i < quantity; i++) {
			stars.push(
				<Star key={i}>
					<IonIcon icon={STAR_ICON} />
				</Star>
			);
		}
		return stars;
	}

	return (
		<div
			className="bg-white m-3 rounded-lg shadow-card overflow-hidden cursor-pointer hover:scale-[1.01] hover:bg-gray-50 duration-200"
			onClick={() => history.push(`/trails/${trail.id}`)}
		>
			<div className="flex">
				<InfoSection>
					<div className="font-bold">{trail.name}</div>
					<div className="flex flex-row pb-1 ">
						{trail.trailsLength && (
							<div className="flex flex-row items-center mr-2 text-sm text-gray-600">
								<IonIcon icon={trailSign} className="pr-1" />
								<span className="">{trail.trailsLength}km of trails</span>
							</div>
						)}
					</div>
					{trail.trailReports[0] && (
						<Report>
							<UserAvatar size="sm" photoURL={trail.trailReports[0].owner.photo?.downloadURL} />
							<ReportContent>
								<Stars>{getStars(trail.trailReports[0].rating)}</Stars>
								<Desc>
									{timeSince(trail.trailReports[0].createdAt)} by:{' '}
									<Name>{trail.trailReports[0].owner.displayName}</Name>
								</Desc>
							</ReportContent>
						</Report>
					)}
				</InfoSection>
				<ArrowSection>
					<div>
						<IonIcon icon={chevronForward} />
					</div>
				</ArrowSection>
			</div>
		</div>
	);
};

export default React.memo(TrailCard);

const Section = styled.div`
	padding-top: 1rem;
	padding-bottom: 1rem;
`;

const InfoSection = styled(Section)`
	padding-right: 1rem;
	padding-left: 1rem;
	flex-grow: 1;
`;

const ArrowSection = styled(Section)`
	display: flex;
	font-size: 1.5rem;
	background: var(--ion-color-primary);
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	flex-grow: 0;
	color: white;
	align-items: center;
`;

const Report = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 0.5rem;
`;

const ReportContent = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 0.5rem;
`;

const Stars = styled.div`
	display: flex;
`;

const Star = styled.div`
	padding-right: 0.2rem;
	color: var(--ion-color-primary);
`;

const Desc = styled.div`
	font-weight: bold;
`;

const Name = styled.span`
	color: var(--ion-color-primary);
`;
