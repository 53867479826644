import React, { useEffect, useState } from 'react';
import { getIcon } from '../../../../utils/svgWeatherIcons';

interface Props {
	trailName: string;
	lat: number;
	lng: number;
}

const API_KEY = 'f45a164496605585c1a1471ef8a285e8';

interface DailyWeatherData {
	dt: number;
	temp: {
		day: number;
		min: number;
		max: number;
	};
	weather: {
		id: number;
		main: string; // clouds, rain, snow, etc
		description: string; // light rain, snow, etc
		icon: string;
	};
}
interface WeatherData {
	lat: number;
	lon: number;
	daily: DailyWeatherData[];
	current: {
		dt: number;
		temp: number;
		feels_like: number;
		weather: {
			id: number;
			main: string; // clouds, rain, snow, etc
			description: string; // light rain, snow, etc
			icon: string;
		}[];
	};
}

const WeatherModule: React.FC<Props> = ({ trailName, lat, lng }) => {
	const [weather, setWeather] = useState<WeatherData | undefined>();

	useEffect(() => {
		fetch(
			`https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lng}&exclude=${`minutely,hourly,alerts`}&units=${`imperial`}&appid=${API_KEY}`
		)
			.then((res) => res.json())
			.then((data) => setWeather(data));
	}, [lat, lng]);

	if (!weather) {
		return (
			<div className="text-sm text-gray-700">
				Unable to fetch weather data at this moment.
			</div>
		);
	}

	const currentWeather = weather.current;

	const icon = getIcon(currentWeather.weather[0].icon);

	return (
		<div className="flex flex-col items-center space-y-1">
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				width={60}
				height={60}
				viewBox={'0 -5 35 40'}
			>
				<title>{currentWeather.weather[0].description}</title>
				<path d={icon} />
			</svg>
			<div className="flex flex-col font-bold">
				{Math.round(currentWeather.temp)}°F
			</div>
			<div className="flex flex-col text-sm text-gray-800">
				Feels like: {Math.round(currentWeather.feels_like)}°F
			</div>
			{/* {weather.daily.map((day, i) => (
				<div className='flex flex-col text-xs'>
					<div>
						{i === 0 ? 'Today' : i === 1 ? 'Tomorrow' : i === 2 ? `In 1 Day` : `In ${i - 1} Days`}
					</div>
					<div>{day.temp.day}</div>
				</div>
			))} */}
		</div>
	);
};

export default WeatherModule;
