import { ApolloError } from '@apollo/client';

import React from 'react';
import { ErrorLabel } from './ErrorLabel';

interface Props {
	error?: ApolloError;
	render?: React.ReactNode;
}

const ApolloErrorHandler: React.FC<Props> = ({ error, render }) => {
	console.warn('(Spencer) Error caught: ', error);

	return (
		<>
			{render ?? (
				<ErrorLabel>
					Whoops! Looks like something went wrong. Your error has been reported!
				</ErrorLabel>
			)}
		</>
	);
};

export default ApolloErrorHandler;
