import { SplashScreen } from '@capacitor/splash-screen';
import { IonApp, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import './tailwind.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

import React from 'react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import AppRoutes from './AppRoutes';
// import { AdManager } from './containers/AdmobContainer';
import LoginModalContainer from './containers/LoginModalContainer';
import Store from './containers/Store';
/* Leaflet Map CSS */
import MainMenu from './pages/main-menu/MainMenu';
/* Theme variables */
import { AdManager } from './containers/AdmobContainer';
import './theme/variables.css';

setupIonicReact({
	mode: 'md',
});

SplashScreen.hide();

const App: React.FC = () => {
	return (
		<Store>
			<IonApp>
				<IonReactRouter>
					<LoginModalContainer>
						<QueryParamProvider adapter={ReactRouter5Adapter}>
							<AdManager.Provider>
								<IonSplitPane contentId="main" disabled>
									<MainMenu />
									<AppRoutes />
								</IonSplitPane>
							</AdManager.Provider>
						</QueryParamProvider>
					</LoginModalContainer>
				</IonReactRouter>
			</IonApp>
		</Store>
	);
};

export default React.memo(App);
