import styled from '@emotion/styled';
import { IonSpinner } from '@ionic/react';

const SkeletonSpinner = () => {
	return (
		<Wrapper>
			<Spinner name="crescent" duration={1000} />
		</Wrapper>
	);
};

export default SkeletonSpinner;

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Spinner = styled(IonSpinner)`
	width: 2.5rem;
	height: 2.5rem;
	color: var(--ion-color-primary);
`;
