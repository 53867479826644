import { modalController, toastController } from '@ionic/core/components';
import OrDivider from '../components/OrDivider';

export const showSuccessToast = (successMessage: string) => {
	toastController
		.create({
			message: successMessage,
			duration: 8000,
			position: 'top',
			color: 'dark',
			keyboardClose: true,
			buttons: [
				{
					text: 'Done',
					role: 'cancel',
				},
			],
		})
		.then((toast) => {
			toast.present();
		});
};

export const showErrorToast = (errorMessage: string) => {
	toastController
		.create({
			message: errorMessage,
			position: 'top',
			color: 'dark',
			keyboardClose: true,
			buttons: [
				{
					text: 'Dismiss',
					role: 'cancel',
				},
			],
			duration: 10000,
		})
		.then((toast) => {
			toast.present();
		});
};

export const showTestModal = () => {
	modalController
		.create({
			component: OrDivider,
			cssClass: 'my-custom-modal-css',
		})
		.then((modal) => {
			modal.present();
		});
};
