import * as Joi from 'joi';

export const TrailReportFormSchema = Joi.object({
	rating: Joi.number().min(1).max(5).required(),
	skiRecommendation: Joi.string(),
	skiTime: Joi.string(),
	snowType: Joi.string(),
	weather: Joi.string(),
	comment: Joi.string().max(2000).required(),
	photos: Joi.array().items(Joi.any()).required(), //Minimum of [] is required
	isGroomingReport: Joi.boolean(),
});
