import { IonInput } from '@ionic/react';

import styled from '@emotion/styled';
import React from 'react';

const AdvancedIonInput: React.FC<any> = (props) => {
	const inputRef = React.createRef<any>();
	const { prefix, suffix, onClick, ...restprops } = props;

	const focusPicker = () => {
		if (inputRef.current) {
			inputRef.current.setFocus();
		}
	};

	const handleClick = () => {
		focusPicker();
		if (onClick) {
			onClick();
		}
	};
	return (
		<>
			<Wrapper onClick={() => handleClick()}>
				{prefix && <Prefix>{prefix}</Prefix>}
				<Middle>
					<StyledIonInput ref={inputRef} {...restprops}></StyledIonInput>
				</Middle>
				{suffix && (
					<Suffix>
						<div>{suffix}</div>
					</Suffix>
				)}
			</Wrapper>
		</>
	);
};

export default AdvancedIonInput;

const Wrapper = styled.div`
	display: flex;
	border-radius: 10px;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: 3rem;
	overflow: hidden;
`;

const Middle = styled.div`
	background: var(--ion-color-light);
	padding-left: 10px;
	padding-right: 10px;
	flex-grow: 1;
	height: 100%;
`;

const StyledIonInput = styled(IonInput)`
	display: flex;
	justify-content: left;
	align-items: center;
`;

const Prefix = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	/* border-radius: 10px 0px 0px 10px; */
	height: 100%;
	font-size: 1.3rem;
	width: 60px;
	background: var(--ion-color-primary);
	color: white;
`;

const Suffix = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	/* border-radius: 0px 10px 10px 0px; */
	padding-right: 10px;
	height: 100%;
	padding-left: 10px;
	background: var(--ion-color-primary);
	color: white;
`;
