import styled from '@emotion/styled';
import { IonCard, IonIcon } from '@ionic/react';
import { chevronForward } from 'ionicons/icons';

import React from 'react';
import ApolloErrorHandler from '../../components/ApolloErrorHandler';
import { ErrorLabel } from '../../components/ErrorLabel';
import SkeletonSpinner from '../../components/Skeletons/SkeletonSpinner';
import { STAR_ICON } from '../../constants/consts';
import { useTrailStatusQuery } from '../../generated/graphql';
import { timeSince } from '../../utils/UtilityFunctions';

interface Props {
	trailId: string;
}

const TrailStatusCard: React.FC<Props> = ({ trailId }) => {
	const { data, loading, error } = useTrailStatusQuery({
		variables: { id: trailId },
		fetchPolicy: 'cache-and-network',
	});

	if (error)
		return (
			<ApolloErrorHandler
				error={error}
				render={<ErrorLabel>Error loading trail: {trailId}</ErrorLabel>}
			/>
		);
	if (!data || loading) return <SkeletonSpinner />;
	if (!data.trail) return <ErrorLabel>No trail found.</ErrorLabel>;

	function getStars(quantity: number) {
		const stars = [];
		for (let i = 0; i < quantity; i++) {
			stars.push(
				<Star key={i}>
					<IonIcon icon={STAR_ICON} />
				</Star>
			);
		}
		return stars;
	}

	const trail = data.trail;
	const newestTrailReport = data.trail.trailReports[0];

	return (
		<StyledIonCard>
			<Header>
				<div>{trail.name}</div>
				<div>{timeSince(newestTrailReport.createdAt)}</div>
			</Header>
			<Content>
				<p>
					{getStars(5)} "{newestTrailReport.comment}"
				</p>
			</Content>

			<Footer>
				<ShowMore>
					More on Skiwise
					<IonIcon icon={chevronForward} />
				</ShowMore>
			</Footer>
		</StyledIonCard>
	);
};

export default TrailStatusCard;

const BUFFER_START = '8px';
const BUFFER_END = '8px';

const StyledIonCard = styled(IonCard)`
	margin: 2px;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	align-content: stretch;
	flex-grow: 1;
`;

const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-left: ${BUFFER_START};
	padding-right: ${BUFFER_END};
	padding-top: 2px;
	padding-bottom: 2px;
	background: var(--ion-color-primary);
	color: white;
	font-weight: bold;
	font-size: 12px;
`;

const Content = styled.div`
	padding-left: ${BUFFER_START};
	padding-right: ${BUFFER_END};
	padding-top: 8px;
	padding-bottom: 8px;
	font-size: 12px;
	overflow-y: hidden;
	color: white;
	flex-grow: 1;
	background: var(--ion-color-primary-tint);
	& p {
		line-clamp: 2;
	}
`;

const Footer = styled.div`
	background: var(--ion-color-primary);
`;

const ShowMore = styled.div`
	padding: 1px;
	padding-left: ${BUFFER_START};
	padding-right: ${BUFFER_END};
	display: flex;
	align-items: center;
	font-weight: bold;
	font-size: 12px;
	color: white;
	float: right;
	vertical-align: middle;
`;

// const GradientBox = styled.div`
// 	width: 80px;
// 	/* background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgba(30, 157, 255, 1)); */
// 	position: absolute;
// 	right: 110px;
// 	bottom: 0;
// 	height: 18px;
// `;

const Star = styled.span`
	display: inline;
	padding-right: 2px;
`;
