import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { DEFAULT_MAP_CENTER, INITIAL_ZOOM_LEVEL } from '../constants/consts';
import { UserLocation } from './UserLocationContainer';
import { ViewState } from 'react-map-gl';

function useTrailsMap() {
	const { userLocation } = UserLocation.useContainer();
	const [viewport, setViewport] = useState<ViewState>({
		latitude: userLocation?.lat || DEFAULT_MAP_CENTER.lat,
		longitude: userLocation?.lng || DEFAULT_MAP_CENTER.lng,
		zoom: INITIAL_ZOOM_LEVEL,
		bearing: 0,
		pitch: 0,
		padding: { top: 0, right: 0, bottom: 0, left: 0 },
	});

	return {
		viewport,
		setViewport,
	};
}

export const TrailsMap = createContainer(useTrailsMap);
