import styled from '@emotion/styled';
import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenu,
	IonMenuButton,
	IonMenuToggle,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import {
	AddCircle,
	Map1,
	MessageText1,
	Messages2,
	Profile,
	Setting3,
} from 'iconsax-react';
import { lockClosed, logoFacebook, logoInstagram } from 'ionicons/icons';
import React, { useState } from 'react';
import { RouteComponentProps, useLocation, withRouter } from 'react-router';
import { LINK_FACEBOOK, LINK_INSTAGRAM } from '../../constants/consts';
import { showErrorToast } from '../../utils/Toasts';
import CreateTrailReportModal from '../create-trail-report/CreateTrailReportModal';
import ProfileHeader from '../profile/components/ProfileHeader';
import ShareModal from '../share/ShareModal';
import MainMenuNotLoggedIn from './MainMenyHeaderNotLoggedIn';
import { useUser } from '../../containers/UserContainer';

const routes = {
	appPages: [
		{
			private: false,
			type: 'link',
			title: 'Skiwise Live Trail Map',
			path: '/trails',
			icon: <Map1 />,
		},
		// {
		// 	private: false,
		// 	type: 'link',
		// 	title: 'Quick Feed',
		// 	path: '/feed',
		// 	icon: cafe,
		// },
		{
			private: true,
			type: 'modal',
			title: 'Create Trail Report',
			path: '',
			icon: <AddCircle />,
		},

		{
			private: true,
			type: 'link',
			title: 'My Profile',
			path: '/profile',
			icon: <Profile />,
		},
		// { type: 'link', title: 'Premium', path: '/premium', icon: STAR_ICON },
		{
			private: true,
			type: 'link',
			title: 'Request New Trail',
			path: '/create-trail',
			icon: <MessageText1 />,
		},
		{
			private: true,
			type: 'link',
			title: 'Give Us Feedback',
			path: '/give-feedback',
			icon: <Messages2 />,
		},
		{
			private: false,
			type: 'link',
			title: 'Settings',
			path: '/settings',
			icon: <Setting3 />,
			isAdminOnly: false, //
		},
		// {
		// 	private: true,
		// 	isAdminOnly: true,
		// 	type: 'link',
		// 	title: 'Admin',
		// 	path: '/admin',
		// 	icon: cog,
		// },
	],
	// loggedInPages: [
	// 	{ title: 'Account', path: '/account', icon: person },
	// 	{ title: 'Support', path: '/support', icon: help },
	// 	{ title: 'Logout', path: '/logout', icon: logOut },
	// ],
	// loggedOutPages: [
	// 	{ title: 'Login', path: '/login', icon: logIn },
	// 	{ title: 'Support', path: '/support', icon: help },
	// 	{ title: 'Signup', path: '/signup', icon: personAdd },
	// ],
};

interface Props extends RouteComponentProps {}

const MainMenu: React.FC<Props> = ({ history }) => {
	const location = useLocation();
	let menuRef: React.RefObject<any> = React.createRef();
	const [showModal, setShowModal] = useState(false);
	const [showShareModal, setShowShareModal] = useState(false);

	const { data } = useUser();

	const isAdmin = false;

	const filteredAppPages = routes.appPages.filter((route) =>
		route.isAdminOnly ? isAdmin : true
	);
	return (
		<IonMenu
			ref={menuRef}
			side="start"
			contentId="main"
			disabled={false}
			type="overlay"
			swipeGesture={false}
		>
			<CreateTrailReportModal
				showModal={showModal}
				setShowModal={setShowModal}
			/>
			<ShareModal showModal={showShareModal} setShowModal={setShowShareModal} />

			<IonHeader>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Menu</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{data?.user ? (
					<Hoverable
						onClick={() => {
							if (menuRef.current) {
								menuRef.current.close();
							}
							history.replace(`/profile/${data.user.id}`);
						}}
					>
						<ProfileHeader user={data.user} />
					</Hoverable>
				) : (
					<MainMenuNotLoggedIn
						onButtonClick={() => {
							if (menuRef.current) {
								menuRef.current.close();
							}
							history.push(`/login`);
						}}
					/>
				)}
				<StyledIonList>
					{/* {config['show_share_banner'].asBoolean() && (
						<AlertBanner lines='none' detail={true} onClick={() => setShowShareModal(true)}>
							<b>{config['share_banner_text'].asString()}</b>
						</AlertBanner>
					)} */}

					<StyledDivider />

					{filteredAppPages.map((route, index) => (
						<IonMenuToggle key={route.title} auto-hide="false">
							{index === 2 && <StyledDivider />}
							{index === 3 && <StyledDivider />}
							{route.private && !data?.user?.id ? (
								<HrefIonItem
									lines="none"
									detail={true}
									onClick={() => {
										showErrorToast('You must be logged in to view that page.');
										history.replace('/login');
									}}
								>
									{typeof route.icon === 'string' ? (
										<IonIcon icon={route.icon} color="dark" />
									) : (
										route.icon
									)}
									<StyledText>
										{route.title}{' '}
										<StyledIonIcon color="dark" icon={lockClosed} />
									</StyledText>
								</HrefIonItem>
							) : route.type === 'link' ? (
								<IonItem
									lines="none"
									detail={true}
									routerLink={route.path}
									routerDirection="none"
								>
									{typeof route.icon === 'string' ? (
										<IonIcon
											icon={route.icon}
											color={
												location.pathname === route.path ? 'primary' : 'dark'
											}
										/>
									) : (
										route.icon
									)}
									<StyledText
										color={
											location.pathname === route.path ? 'primary' : 'dark'
										}
									>
										{route.title}
									</StyledText>
								</IonItem>
							) : (
								<HrefIonItem
									lines="none"
									detail={true}
									onClick={() => setShowModal(true)}
								>
									{typeof route.icon === 'string' ? (
										<IonIcon icon={route.icon} color="dark" />
									) : (
										route.icon
									)}
									<StyledText>{route.title}</StyledText>
								</HrefIonItem>
							)}
						</IonMenuToggle>
					))}
					<StyledDivider />
					<div className="flex justify-center py-2">
						<IonButtons>
							<IonButton size="large" target="_blank" href={LINK_FACEBOOK}>
								<IonIcon className="text-blue-800" icon={logoFacebook} />
							</IonButton>
							<IonButton size="large" target="_blank" href={LINK_INSTAGRAM}>
								<IonIcon className="text-pink-600" icon={logoInstagram} />
							</IonButton>
						</IonButtons>
					</div>
				</StyledIonList>
			</IonContent>
		</IonMenu>
	);
};

export default withRouter(MainMenu);

const StyledDivider = styled.div`
	width: 100%;
	height: 1rem;
	background: var(--ion-color-light);
`;

const StyledText = styled(IonLabel)`
	padding-left: 10px;
`;

const StyledIonList = styled(IonList)`
	padding-top: 0;
	padding-bottom: 10px;
`;

const HrefIonItem = styled(IonItem)`
	cursor: pointer;
	&:hover {
		--background: var(--ion-color-light);
	}
`;

const Hoverable = styled.div`
	cursor: pointer;
`;

const StyledIonIcon = styled(IonIcon)`
	font-size: 0.8rem;
`;
