import { IonLabel, IonList } from '@ionic/react';

import styled from '@emotion/styled';
import React from 'react';
import UserAvatar from '../../../../components/UserAvatar';
import UserBanner from '../../../../components/UserBanner';
import { TrailDetailsFragment } from '../../../../generated/graphql';

interface Props {
	admins: TrailDetailsFragment['admins'];
}

const AdminsInfoModule: React.FC<Props> = ({ admins }) => {
	return (
		<>
			{admins.length === 0 ? (
				<div className="flex flex-col w-full p-6 space-y-2 text-sm border-2 border-gray-200 border-dashed rounded-lg bg-gray-50">
					<p className="flex space-x-2">
						<span>This trail has no administrators.</span>
					</p>{' '}
					<p className="text-sm">
						Are you the groomer, trail owner, or manager?{' '}
						<a
							className="hover:text-blue-600"
							href="/give-feedback?type=OWNERSHIP_CLAIM"
						>
							Click here to claim ownership of this trail.
						</a>
					</p>
				</div>
			) : (
				<IonList>
					{admins.map(({ user }) => (
						<Wrapper key={user.id}>
							<UserAvatar size="sm" photoURL={user.photo?.downloadURL} />
							<Content>
								<DisplayName>{user.displayName}</DisplayName>
								<div>
									<UserBanner membershipType={user.membershipType} />
								</div>
							</Content>
						</Wrapper>
					))}
				</IonList>
			)}
		</>
	);
};

export default AdminsInfoModule;

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
`;

const Content = styled.div`
	padding-left: 0.5rem;
`;

const DisplayName = styled(IonLabel)`
	color: var(--ion-color-dark);
	font-weight: bold;
`;
