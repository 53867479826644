import classNames from 'classnames';
import React, { useState } from 'react';
import { createContainer } from 'unstated-next';

function useSection() {
	const [x, setX] = useState(false);
	return [x, setX];
}

const SectionContainer = createContainer(useSection);

interface Props extends React.ComponentPropsWithoutRef<'div'> {}

export const Section = (props: Props) => {
	const { children, className, ...rest } = props;

	return (
		<SectionContainer.Provider>
			<div
				className={classNames('p-6 bg-white rounded shadow-card', className)}
				{...rest}
			>
				{children}
			</div>
		</SectionContainer.Provider>
	);
};

// <Section.Header>
interface SectionHeaderProps extends React.ComponentPropsWithoutRef<'h2'> {}

Section.Header = (props: SectionHeaderProps) => {
	const { children, className, ...rest } = props;

	return (
		<h2
			className={classNames('text-lg font-bold text-gray-900', className)}
			{...rest}
		>
			{children}
		</h2>
	);
};

// <Section.Subheader>
interface SectionSubheaderProps extends React.ComponentPropsWithoutRef<'div'> {
	children: React.ReactNode;
}

Section.Subheader = (props: SectionSubheaderProps) => {
	const { children, className, ...rest } = props;

	return (
		<div className={classNames('text-sm text-gray-600', className)} {...rest}>
			{children}
		</div>
	);
};

// <Section.Content>
interface SectionContentProps extends React.ComponentPropsWithoutRef<'div'> {
	children: React.ReactNode;
}

Section.Content = (props: SectionContentProps) => {
	const { children, className, ...rest } = props;

	return (
		<div className={classNames('pt-8 pb-4', className)} {...rest}>
			{children}
		</div>
	);
};

export default Section;
