import { User } from '@capacitor-firebase/authentication';
import { useReducer } from 'react';

// ACTION TYPES
const SUBMITTING = `SUBMITTING`;
const SUCCESS = `SUCCESS`;
const ERROR = `ERROR`;
const RESET = `RESET`;

// ACTION CREATORS
type SubmittingResponseType = { type: typeof SUBMITTING };
type SuccessResponseType = {
	type: typeof SUCCESS;
	payload: AuthUser | undefined;
};
type ErrorResponseType = { type: typeof ERROR; payload: string };
type ResetResponseType = { type: typeof RESET; payload: AuthState };

export const submitting = (): SubmittingResponseType => ({ type: SUBMITTING });
export const success = (payload: AuthUser | undefined): SuccessResponseType => ({
	type: SUCCESS,
	payload,
});
export const error = (payload: string): ErrorResponseType => ({
	type: ERROR,
	payload,
});
export const reset = (payload: AuthState): ResetResponseType => ({
	type: RESET,
	payload,
});

type Action = SubmittingResponseType | SuccessResponseType | ErrorResponseType | ResetResponseType;

// STATE
export type AuthState = {
	user: AuthUser | undefined;
	isAuthenticating: boolean;
	authError: string;
};

type AuthUser = {
	token: string;
	id: string;
	data: User;
};

function authReducer(state: AuthState, action: Action) {
	switch (action.type) {
		case SUBMITTING: {
			return {
				user: undefined,
				isAuthenticating: true,
				authError: '',
			};
		}

		case SUCCESS: {
			return {
				...state,
				user: action.payload,
				isAuthenticating: false,
				authError: '',
			};
		}

		case ERROR: {
			return {
				...state,
				isAuthenticating: false,
				authError: action.payload,
			};
		}

		case RESET: {
			return action.payload;
		}

		default:
			return state;
	}
}

export function useAuthReducer(initialState: AuthState) {
	const response = useReducer(authReducer, initialState);
	return response;
}
