import 'firebase/auth';
import { Redirect, Route } from 'react-router-dom';
import SkeletonSpinner from '../components/Skeletons/SkeletonSpinner';
import { Auth } from '../containers/AuthContainer';
import { useUser } from '../containers/UserContainer';

const PrivateRoute = ({ component, ...rest }: any) => {
	const { user, isAuthenticating } = Auth.useContainer();
	if (isAuthenticating) return <SkeletonSpinner />;
	if (!user) return <Redirect to={'/login'} />;

	return <Internals component={component} userId={user.id} {...rest} />;
};

export default PrivateRoute;

const Internals = ({ component: Component, userId, ...rest }: any) => {
	const { data, loading } = useUser();

	if (loading) return <SkeletonSpinner />;
	if (!data) return <Redirect to={`/login`} />;
	if (!data.user) return <Redirect to={`/create-account`} />;

	return <Route {...rest} render={(props) => <Component {...props} />} />;
};
