import styled from '@emotion/styled';
import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonLabel,
	IonModal,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';

import React, { useCallback } from 'react';
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	TwitterIcon,
	TwitterShareButton,
} from 'react-share';
import { FormItem } from '../../components/Form/FormComponents';
import { Config } from '../../containers/ConfigContainer';
interface Props {
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShareModal: React.FC<Props> = ({ showModal, setShowModal }) => {
	const handleClose = useCallback(() => {
		setShowModal(false);
	}, [setShowModal]);

	const { config } = Config.useContainer();

	const SHARE_URL = 'https://skiwise-app.com';

	return (
		<IonModal isOpen={showModal} onDidDismiss={handleClose}>
			<IonHeader>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonButton onClick={handleClose}>
							<IonIcon icon={close} />
						</IonButton>
					</IonButtons>
					<IonTitle>{config['share_banner_text'].asString()}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<FormItem>
					<StyledIonLabel>Hello there!</StyledIonLabel>
					<StyledIonLabel>
						Did you know each skiers knows on average <b>26.3</b> other skiers?
						Well besides that 3/10ths of a skier, I'm sure all of them would
						love to hear about Skiwise!
					</StyledIonLabel>
					<StyledIonLabel>
						As a small development team (if you can call one person a team), we
						can't possibly reach all the skiers who'd love to use this app.
						That's why we need your help!
					</StyledIonLabel>
					<StyledIonLabel>
						With your help we can grow the community and have a bigger bank of
						up-to-date trail conditions for everyone to benefit from.
					</StyledIonLabel>
					<StyledIonLabel>
						So please consider sharing the app with your friends!
					</StyledIonLabel>
					<StyledIonLabel>
						Here's some one-click buttons to make your job a lot easier:
					</StyledIonLabel>
				</FormItem>
				<FormItem>
					{/* <EmailShareButton
						subject='I wanted to share this with you.'
						separator=' '
						body={`It's an app I think you'll enjoy. It's called Skiwise and lets you view trail conditions. https://skiwise-app.com`}
					/> */}
					{/*// @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
					<TwitterShareButton
						url={SHARE_URL}
						title={`I found this awesome app for cross country skiers that lets you share trail conditions straight from your phone. 
            
If you ski you should try it out!

`}
						via="SkiwiseApp"
						related={['SkiwiseApp']}
					>
						<TwitterIcon size={32} round />
					</TwitterShareButton>
					<FacebookShareButton
						url={SHARE_URL}
						quote={`If you're a cross country skier you should check out the Skiwise app. It lets you view trail conditions straight from your phone.`}
						hashtag="#SkiwiseApp"
					>
						<FacebookIcon size={32} round />
					</FacebookShareButton>
					<EmailShareButton
						url={SHARE_URL}
						subject={`I think you'd be interested in this.`}
						body={`I found an app that lets you view trail conditions for the local ski trails. I thought you'd be interested. Link: https://skiwise-app.com`}
					>
						<EmailIcon size={32} round />
					</EmailShareButton>
				</FormItem>
			</IonContent>
		</IonModal>
	);
};

export default ShareModal;

const StyledIonLabel = styled(IonLabel)`
	color: var(--ion-color-dark-tint);
	display: block;
	padding-bottom: 2rem;
`;
