import styled from '@emotion/styled';
import { IonSpinner } from '@ionic/react';
import { useState } from 'react';
import useInfiniteLoader from 'react-use-infinite-loader';
import { TrailReportFragment } from '../../../../generated/graphql';
import { Module } from '../../trailinfo/components/Module';
import TrailReportModule from './TrailReportModule';

require('rc-collapse/assets/index.css');

interface AccordionProps {
	title: string;
	trailReports: TrailReportFragment[];
}

let PAGE_SIZE = 10;

function TrailReportInfiniteList({ title, trailReports }: AccordionProps) {
	const [limit, setLimit] = useState(PAGE_SIZE);

	const [canLoadMore, setCanLoadMore] = useState(true);

	if (canLoadMore && limit > trailReports.length) {
		setCanLoadMore(false);
		setLimit(trailReports.length * 2);
	}

	const loadMore = () => {
		if (!trailReports) {
			return;
		}
		const lastElement = trailReports[trailReports.length - 1];
		if (!lastElement) {
			return;
		}

		setLimit(limit + PAGE_SIZE);
	};

	console.log('limit', limit);

	const { loaderRef } = useInfiniteLoader<HTMLDivElement>({
		loadMore,
		canLoadMore,
	});

	return (
		<>
			{trailReports.slice(0, limit).map((trailReport) => (
				<TrailReportContainer key={trailReport.id}>
					<TrailReportModule trailReport={trailReport} />
				</TrailReportContainer>
			))}
			{canLoadMore && (
				<LoadingMessage ref={loaderRef}>
					<IonSpinner name="crescent" duration={1000} />{' '}
					<span className="message">Loading more...</span>
				</LoadingMessage>
			)}
		</>
	);
}

export default TrailReportInfiniteList;

const TrailReportContainer = styled(Module)`
	padding-top: 0;
	padding-bottom: 0;
`;

const LoadingMessage = styled.div`
	width: 100%;
	min-height: 12px;
	display: flex;
	justify-content: center;
	padding-top: 30px;
	padding-bottom: 30px;
	.message {
		padding-left: 10px;
		font-size: 0.9rem;
		font-weight: bold;
	}
`;
