import { IonAlert } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { createContainer } from 'unstated-next';
import { Auth } from './AuthContainer';

function useLoginModal() {
	const [showModal, setShowModal] = useState<string | null>(null);

	const { user } = Auth.useContainer();

	//todo - is this needed?
	useEffect(() => {
		if (user?.id) {
			setShowModal(null);
		}
	}, [user?.id]);

	const showLoginScreenPopup = (message: string) => {
		if (!user?.id) {
			setShowModal(message);
		}
	};

	const hideLoginScreenPopup = () => setShowModal(null);

	return { showModal, showLoginScreenPopup, hideLoginScreenPopup };
}

export const LoginModal = createContainer(useLoginModal);

interface Props {
	children?: React.ReactNode;
}

const LoginModalContainer: React.FC<Props> = ({ children }) => {
	return (
		<LoginModal.Provider>
			<Modal />
			{children}
		</LoginModal.Provider>
	);
};

export default LoginModalContainer;

const Modal: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	const { showModal, hideLoginScreenPopup } = LoginModal.useContainer();
	const history = useHistory();

	return (
		<IonAlert
			isOpen={showModal !== null}
			onDidDismiss={hideLoginScreenPopup}
			header={'Login'}
			message={showModal || 'You must be logged in to do this.'}
			buttons={[
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'Login or Signup',
					handler: () => history.push('/login'),
				},
			]}
		/>
	);
};
