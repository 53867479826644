import { IonList } from '@ionic/react';

import React from 'react';
import { Auth } from '../../containers/AuthContainer';
import { TrailFragment } from '../../generated/graphql';
import { IonListHeaderSmall } from '../Form/FormComponents';
import FollowingTrailsItems from './SearchSections/FollowingTrailsItems';
import FuseFilteredTrailsItems from './SearchSections/FuseFilteredTrailsItems';
import TrailsSearchItems from './SearchSections/TrailsSearchItems';

interface Props {
	currentSearch: string;
	onSearchItemClick: (trail: TrailFragment) => void;
}

const TrailSearchList: React.FC<Props> = ({ currentSearch, onSearchItemClick }) => {
	const isSearchEmpty = currentSearch === '';

	const { user } = Auth.useContainer();

	return (
		<IonList>
			{isSearchEmpty ? (
				<>
					{user?.id ? (
						<>
							<IonListHeaderSmall mode="ios">Following Trails</IonListHeaderSmall>
							<FollowingTrailsItems
								userId={user.id}
								noItemsLabel={'No trails found.'}
								onSearchItemClick={onSearchItemClick}
							/>
						</>
					) : (
						<>
							<IonListHeaderSmall mode="ios">Trails</IonListHeaderSmall>
							<TrailsSearchItems
								noItemsLabel={'No trails found.'}
								onSearchItemClick={onSearchItemClick}
								limit={11}
							/>
						</>
					)}
				</>
			) : (
				<>
					<IonListHeaderSmall mode="ios">Trails</IonListHeaderSmall>
					<FuseFilteredTrailsItems
						noItemsLabel={'No trails found.'}
						currentSearch={currentSearch}
						onSearchItemClick={onSearchItemClick}
						limit={11}
					/>
				</>
			)}
		</IonList>
	);
};

export default TrailSearchList;
