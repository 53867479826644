import React from 'react';
import styled from '@emotion/styled';
import { ErrorLabel } from './ErrorLabel';

interface Props {
	children: React.ReactNode;
}

const ErrorPage: React.FC<Props> = ({ children }) => {
	return (
		<ErrorBox>
			<ErrorLabel>{children}</ErrorLabel>
		</ErrorBox>
	);
};

export default ErrorPage;

const ErrorBox = styled.div`
	padding: 20px;
	max-width: 600px;
	margin: auto;
`;
