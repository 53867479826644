import * as Joi from 'joi';

// This is the basic Joi Validation resolver used throughout the application.
// Example use case:

// resolver: getValidationResolver(UpdateProfileSchema, (error) => ({
// 	...error,
// 	message: `This field ${error.message}`,
// })),

export const getValidationResolver =
	<T>(
		schema: Joi.ObjectSchema<any>,
		errorFormatter?: (error: Joi.ValidationErrorItem) => Joi.ValidationErrorItem
	) =>
	async (data: T) => {
		const { error, value: values } = schema.validate(data, {
			abortEarly: false,
			errors: {
				label: false,
			},
		});

		const returnValues = {
			values: error ? {} : values,
			errors: error
				? error.details.reduce((previous: any, currentError: any) => {
						return {
							...previous,
							[`${currentError.path.join('.')}`]: errorFormatter
								? errorFormatter(currentError)
								: {
										...error,
										message: `${currentError.path.join('.')} ${
											currentError.message
										}`,
								  },
						};
				  }, {})
				: {},
		};
		return returnValues;
	};
