import { IonCol } from '@ionic/react';

import styled from '@emotion/styled';

const IonColNoPadding = styled(IonCol)`
	padding: 0;
	margin: 0;
`;

export default IonColNoPadding;
