import {
	IonButtons,
	IonContent,
	IonHeader,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react';

import React from 'react';
import { Auth } from '../../containers/AuthContainer';
import FavoritesFeedApollo from './FavoritesFeedApollo';

interface Props {}

const FeedPage: React.FC<Props> = () => {
	const { user } = Auth.useContainer();
	return (
		<IonPage className="profile">
			<IonHeader translucent>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Quick View</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen={true}>
				{user?.id && <FavoritesFeedApollo userId={user.id} />}
			</IonContent>
		</IonPage>
	);
};

export default FeedPage;
