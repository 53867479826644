import { gql } from '@apollo/client';
import { IonLoading } from '@ionic/react';
import React from 'react';
import ApolloErrorHandler from '../../components/ApolloErrorHandler';
import { ErrorLabel } from '../../components/ErrorLabel';
import SkeletonSpinner from '../../components/Skeletons/SkeletonSpinner';
import {
	Photo_Obj_Rel_Insert_Input,
	useEditProfileMutation,
	useProfileEditableQuery,
} from '../../generated/graphql';
import EditProfileLogic from './EditProfileLogic';
import { EditProfileModel } from './models/EditProfileModel';

gql`
	mutation EditProfile(
		$id: String!
		$photo: photo_obj_rel_insert_input
		$displayName: String!
		$description: String!
	) {
		insertUser(
			object: {
				id: $id
				photo: $photo
				displayName: $displayName
				description: $description
			}
			on_conflict: {
				constraint: user_pkey
				update_columns: [displayName, description, photoId]
			}
		) {
			...ProfileEditableFragment
		}
	}
`;

interface Props {
	userId: string;
}

const EditProfileApollo: React.FC<Props> = ({ userId }) => {
	const { data, loading, error } = useProfileEditableQuery({
		variables: { id: userId },
		fetchPolicy: 'cache-and-network',
	});
	const [editProfileMutation, { loading: uploading }] =
		useEditProfileMutation();

	if (error) return <ApolloErrorHandler error={error} />;
	if (!data || loading) return <SkeletonSpinner />;
	if (!data.user) return <ErrorLabel>No user found.</ErrorLabel>;

	const editProfile = (
		photo: Photo_Obj_Rel_Insert_Input | undefined,
		displayName: string,
		description: string
	) => {
		return editProfileMutation({
			variables: { id: userId, photo, displayName, description },
		});
	};

	const defaultValues: EditProfileModel = {
		displayName: data.user.displayName,
		photo: undefined,
		description: data.user.description || '',
	};

	return (
		<>
			<IonLoading isOpen={uploading} message="Updating Profile..." />
			<EditProfileLogic
				userId={userId}
				defaultValues={defaultValues}
				editProfile={editProfile}
			/>
		</>
	);
};

export default EditProfileApollo;
