import { IonPage } from '@ionic/react';

import React, { useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import EditTrailApollo from './EditTrailApollo';

interface Props extends RouteComponentProps<{ id: string }> {}

const EditTrailPage: React.FC<Props> = ({ history, match }) => {
	const trailId = match.params.id;
	const pageRef = useRef<any>();

	const handleClose = () => {
		history.replace(`/trails/${trailId}`);
	};

	return (
		<IonPage id="edit-trail-page" ref={pageRef}>
			<EditTrailApollo
				trailId={trailId}
				onClose={handleClose}
				pageRef={pageRef}
			/>
		</IonPage>
	);
};

export default withRouter(EditTrailPage);
