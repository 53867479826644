import { IonButton } from '@ionic/react';
import React from 'react';
import Section from '../../components/Section/Section';

const AdminTrailInfoSection = () => {
	return (
		<Section>
			<Section.Header>Trail Info</Section.Header>
			<Section.Subheader>
				Manage the information shown on your trail page.
			</Section.Subheader>
			<Section.Content>
				<IonButton href={`/edit-trail/${'TRAILID'}`} fill="outline">
					<span className="pl-2">Edit trail info</span>
				</IonButton>
			</Section.Content>
		</Section>
	);
};

export default AdminTrailInfoSection;
