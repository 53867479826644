import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

import { useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { error, reset, submitting, success, useAuthReducer } from '../utils/authReducer';
import { getErrorMessage } from '../utils/getErrorMessage';
import { jwtDecode } from 'jwt-decode';
import { useApolloClient } from '@apollo/client';

const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;
if (!GRAPHQL_ENDPOINT) {
	throw Error('Error: No environment variable set. [REACT_APP_GRAPHQL_ENDPOINT]');
}

const GRAPHQL_WEBSOCKET_ENDPOINT = process.env.REACT_APP_GRAPHQL_WEBSOCKET_ENDPOINT;
if (!GRAPHQL_WEBSOCKET_ENDPOINT) {
	throw Error('Error: No environment variable set. [REACT_APP_GRAPHQL_WEBSOCKET_ENDPOINT]');
}

const CLOUD_FUNCTION_ENDPOINT = process.env.REACT_APP_CLOUD_FUNCTION_ENDPOINT;
if (!CLOUD_FUNCTION_ENDPOINT) {
	throw Error('Error: No  environment variable set. [REACT_APP_CLOUD_FUNCTION_ENDPOINT]');
}

function getRefinedFirebaseAuthErrorMessage(errorMesssage: string): string {
	return errorMesssage.replace('Firebase: ', '');
}

const initialState = {
	user: undefined,
	isAuthenticating: true,
	authError: '',
};

export const AUTH_TOKEN = 'idToken';

export const useAuth = () => {
	const [state, dispatch] = useAuthReducer(initialState);

	const handleAuthLogout = () => {
		console.debug('[AuthStateChange] User Logged out.');
		localStorage.removeItem(AUTH_TOKEN);
	};

	const client = useApolloClient();

	useEffect(() => {
		// Listen for auth state changes
		const createListener = async () => {
			return await FirebaseAuthentication.addListener(
				'authStateChange',
				async ({ user: firebaseUser }) => {
					console.debug('[AuthStateChange] initiated...', firebaseUser);
					dispatch(submitting());

					if (!firebaseUser) {
						handleAuthLogout();
						dispatch(success(undefined));
						return;
					}

					console.debug('[AuthStateChange] Logging in initated...');
					const { token } = await FirebaseAuthentication.getIdToken();
					const claims = jwtDecode<{ [key: string]: any }>(token);

					if (claims && claims['https://hasura.io/jwt/claims']) {
						console.debug('[AuthStateChange] Hasura claims validated...');

						localStorage.setItem(AUTH_TOKEN, token);
						dispatch(
							success({
								id: firebaseUser.uid,
								token: token,
								data: firebaseUser,
							})
						);
						return;
					}

					console.debug('[AuthStateChange] Hasura claims not found. Refreshing auth token...');

					const refreshResult = await fetch(
						`${CLOUD_FUNCTION_ENDPOINT}/refreshToken-refreshToken?uid=${firebaseUser.uid}`
					);

					if (refreshResult.status === 200) {
						const validTokenAfterRefresh = await FirebaseAuthentication.getIdToken({
							forceRefresh: true,
						});

						if (!validTokenAfterRefresh) {
							console.error(`This shouldn't happen, something went wrong.`);
							return;
						}

						localStorage.setItem(AUTH_TOKEN, validTokenAfterRefresh.token);
						dispatch(
							success({
								id: firebaseUser.uid,
								token: validTokenAfterRefresh.token,
								data: firebaseUser,
							})
						);
					} else {
						return refreshResult.json().then((e) => {
							throw e;
						});
					}
				}
			);
		};

		createListener();
		return () => {
			FirebaseAuthentication.removeAllListeners();
		};
	}, [dispatch]);

	const resetAuthenticationStatus = () => {
		dispatch(reset(initialState));
	};

	const formLogin = async (email: string, password: string) => {
		dispatch(submitting());
		await FirebaseAuthentication.signInWithEmailAndPassword({
			email,
			password,
		}).catch((err) => {
			console.error(err);
			dispatch(error(getRefinedFirebaseAuthErrorMessage(err.message)));
		});
	};

	const googleLogin = async () => {
		await FirebaseAuthentication.signInWithGoogle().catch((err) => {
			console.error(err);
			dispatch(error(getRefinedFirebaseAuthErrorMessage(err.message)));
		});
	};

	const appleLogin = async () => {
		await FirebaseAuthentication.signInWithApple().catch((err) => {
			console.error(err);
			dispatch(error(getRefinedFirebaseAuthErrorMessage(err.message)));
		});
	};

	const formSignup = async (
		email: string,
		password: string,
		_firstName: string,
		_lastName: string
	) => {
		dispatch(submitting());

		if (!email || !password) {
			dispatch(error('Email and password fields are required.'));
			return;
		}

		try {
			await FirebaseAuthentication.createUserWithEmailAndPassword({
				email,
				password,
			}).then((result) => {
				if (!result.user) {
					throw new Error('No uid returned from signup method');
				}
			});
		} catch (err) {
			console.error(err);
			dispatch(error(getRefinedFirebaseAuthErrorMessage(getErrorMessage(err))));
		}
	};

	async function logout() {
		await FirebaseAuthentication.signOut();
		await client.clearStore();
		// await client.reset
	}

	return {
		formLogin,
		googleLogin,
		appleLogin,
		formSignup,
		resetAuthenticationStatus,
		logout,
		user: state.user,
		isAuthenticating: state.isAuthenticating,
		authError: state.authError,
	};
};

export const Auth = createContainer(useAuth);
