import React from 'react';
import ApolloErrorHandler from '../../components/ApolloErrorHandler';
import { ErrorLabel } from '../../components/ErrorLabel';
import SkeletonSpinner from '../../components/Skeletons/SkeletonSpinner';
import { usePublicProfilePageDetailsQuery } from '../../generated/graphql';
import PublicProfile from './PublicProfile';

interface Props {
	userId: string;
}

const PublicProfileApollo: React.FC<Props> = ({ userId }) => {
	const { data, loading, error, refetch } = usePublicProfilePageDetailsQuery({
		variables: { id: userId },
		fetchPolicy: 'cache-and-network',
	});

	if (error) return <ApolloErrorHandler error={error} />;
	if (!data || loading) return <SkeletonSpinner />;
	if (!data.user) return <ErrorLabel>No user found.</ErrorLabel>;

	return <PublicProfile user={data.user} onProfileChange={refetch} />;
};

export default PublicProfileApollo;
