import styled from '@emotion/styled';
import { IonButton, IonList } from '@ionic/react';

import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormBottomPadding } from '../../../components/Form/FormComponents';
import { Auth } from '../../../containers/AuthContainer';
import { TrailDetailsFragment } from '../../../generated/graphql';
import { sm } from '../../../theme/style-system';
import AdminsInfoModule from './components/AdminsInfoModule';
import ChipsModule from './components/ChipsModule';
import LikeModule from './components/LikeModule';
import { Module } from './components/Module';
import SocialLinksModule from './components/SocialLinksModule';
import TextModule from './components/TextModule';
import WeatherModule from './components/WeatherModule';

interface Props extends RouteComponentProps {
	trail: TrailDetailsFragment;
}

const TrailInfo: React.FC<Props> = ({ trail }) => {
	const { user } = Auth.useContainer();

	const generateChips = () => {
		const chips = [
			trail.isClassicAllowed ? 'Classic Offered' : 'No Classic Offered',
			trail.isSkateAllowed ? 'Skate Offered' : 'No Skate Offered',
			trail.trailsLength ? `${trail.trailsLength} km of trails` : undefined,
			trail.isDogsAllowed ? 'Dogs Allowed' : undefined,
			trail.isLighted ? 'Lighted at Night' : undefined,
			trail.isSnowmaking ? 'Snowmaking' : undefined,
		];

		return chips.filter((el) => el != null) as string[];
	};

	return (
		<Wrapper>
			<ModuleList>
				<ModuleCard>
					<LikeModule
						userId={user?.id}
						trailId={trail.id}
						name={trail.name}
						length={trail.trailsLength || undefined}
					/>
				</ModuleCard>

				<ModuleCard>
					<div className="mt-2 mb-2 font-bold text-md">Description</div>
					<TextModule maxLength={600} textColor="var(--ion-color-dark-tint)" buttonColor="primary">
						{trail.description || 'N/A'}
					</TextModule>
				</ModuleCard>
				<ModuleCard>
					<div className="mt-2 mb-2 font-bold text-md">Basic Info</div>
					<ChipsModule chips={generateChips()}></ChipsModule>
				</ModuleCard>
				<ModuleCard>
					<div className="mt-2 mb-2 font-bold text-md">Current Weather</div>
					<WeatherModule trailName={trail.name} lat={trail.location.lat} lng={trail.location.lng} />
				</ModuleCard>
				<ModuleCard>
					<div className="mt-2 mb-2 font-bold text-md">Trail Administrators</div>
					<AdminsInfoModule admins={trail.admins} />
				</ModuleCard>
				<Module>
					<SocialLinksModule
						facebookURL={trail.facebookURL}
						instagramURL={trail.instagramURL}
						websiteURL={trail.websiteURL}
					/>
				</Module>

				{trail.admins.find((admin) => admin.user.id === user?.id) && (
					<IonButton routerLink={`/edit-trail/${trail.id}`} routerDirection="forward">
						Edit
					</IonButton>
				)}

				<FormBottomPadding />
			</ModuleList>
		</Wrapper>
	);
};

export default withRouter(TrailInfo);

const Wrapper = styled.div``;

const ModuleList = styled(IonList)`
	padding: 10px 10px;
	display: flex;
	flex-direction: column;
	max-width: ${sm};
	margin: auto;
	> * {
		/* margin-bottom: 10px; */
		padding-top: 0px;
		padding-bottom: 0px;
	}
`;

const ModuleCard = styled.div`
	box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.1);
	margin: 12px;
	border-radius: 8px;
	padding: 18px;
	/* padding-bottom: 28px; */
`;
