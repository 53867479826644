import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  money: { input: any; output: any; }
  numeric: { input: number; output: number; }
  timestamptz: { input: string; output: string; }
  uuid: { input: string; output: string; }
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SampleInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  _nlike?: InputMaybe<Scalars['String']['input']>;
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "feedback" */
export type Feedback_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Feedback_Max_Order_By>;
  min?: InputMaybe<Feedback_Min_Order_By>;
};

/** input type for inserting array relation for remote table "feedback" */
export type Feedback_Arr_Rel_Insert_Input = {
  data: Array<Feedback_Insert_Input>;
  on_conflict?: InputMaybe<Feedback_On_Conflict>;
};

/** Boolean expression to filter rows from the table "feedback". All fields are combined with a logical 'AND'. */
export type Feedback_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Feedback_Bool_Exp>>>;
  _not?: InputMaybe<Feedback_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Feedback_Bool_Exp>>>;
  adminResponse?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  ownerId?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "feedback" */
export enum Feedback_Constraint {
  /** unique or primary key constraint */
  FeedbackPkey = 'feedback_pkey'
}

/** input type for inserting data into table "feedback" */
export type Feedback_Insert_Input = {
  adminResponse?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by max() on columns of table "feedback" */
export type Feedback_Max_Order_By = {
  adminResponse?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  ownerId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "feedback" */
export type Feedback_Min_Order_By = {
  adminResponse?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  ownerId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "feedback" */
export type Feedback_Obj_Rel_Insert_Input = {
  data: Feedback_Insert_Input;
  on_conflict?: InputMaybe<Feedback_On_Conflict>;
};

/** on conflict condition type for table "feedback" */
export type Feedback_On_Conflict = {
  constraint: Feedback_Constraint;
  update_columns: Array<Feedback_Update_Column>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};

/** ordering options when selecting data from "feedback" */
export type Feedback_Order_By = {
  adminResponse?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  ownerId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "feedback" */
export type Feedback_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "feedback" */
export enum Feedback_Select_Column {
  /** column name */
  AdminResponse = 'adminResponse',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "feedback" */
export type Feedback_Set_Input = {
  adminResponse?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "feedback" */
export enum Feedback_Update_Column {
  /** column name */
  AdminResponse = 'adminResponse',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** order by aggregate values of table "location" */
export type Location_Aggregate_Order_By = {
  avg?: InputMaybe<Location_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Location_Max_Order_By>;
  min?: InputMaybe<Location_Min_Order_By>;
  stddev?: InputMaybe<Location_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Location_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Location_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Location_Sum_Order_By>;
  var_pop?: InputMaybe<Location_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Location_Var_Samp_Order_By>;
  variance?: InputMaybe<Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "location" */
export type Location_Arr_Rel_Insert_Input = {
  data: Array<Location_Insert_Input>;
  on_conflict?: InputMaybe<Location_On_Conflict>;
};

/** order by avg() on columns of table "location" */
export type Location_Avg_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "location". All fields are combined with a logical 'AND'. */
export type Location_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Location_Bool_Exp>>>;
  _not?: InputMaybe<Location_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Location_Bool_Exp>>>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lat?: InputMaybe<Numeric_Comparison_Exp>;
  lng?: InputMaybe<Numeric_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "location" */
export enum Location_Constraint {
  /** unique or primary key constraint */
  LocationIdKey = 'location_id_key',
  /** unique or primary key constraint */
  LocationPkey = 'location_pkey'
}

/** input type for incrementing integer column in table "location" */
export type Location_Inc_Input = {
  lat?: InputMaybe<Scalars['numeric']['input']>;
  lng?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "location" */
export type Location_Insert_Input = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lat?: InputMaybe<Scalars['numeric']['input']>;
  lng?: InputMaybe<Scalars['numeric']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "location" */
export type Location_Max_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "location" */
export type Location_Min_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "location" */
export type Location_Obj_Rel_Insert_Input = {
  data: Location_Insert_Input;
  on_conflict?: InputMaybe<Location_On_Conflict>;
};

/** on conflict condition type for table "location" */
export type Location_On_Conflict = {
  constraint: Location_Constraint;
  update_columns: Array<Location_Update_Column>;
  where?: InputMaybe<Location_Bool_Exp>;
};

/** ordering options when selecting data from "location" */
export type Location_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "location" */
export type Location_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "location" */
export enum Location_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  State = 'state'
}

/** input type for updating data in table "location" */
export type Location_Set_Input = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lat?: InputMaybe<Scalars['numeric']['input']>;
  lng?: InputMaybe<Scalars['numeric']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "location" */
export type Location_Stddev_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "location" */
export type Location_Stddev_Pop_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "location" */
export type Location_Stddev_Samp_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "location" */
export type Location_Sum_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** update columns of table "location" */
export enum Location_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  State = 'state'
}

/** order by var_pop() on columns of table "location" */
export type Location_Var_Pop_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "location" */
export type Location_Var_Samp_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "location" */
export type Location_Variance_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** expression to compare columns of type money. All fields are combined with logical 'AND'. */
export type Money_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['money']['input']>;
  _gt?: InputMaybe<Scalars['money']['input']>;
  _gte?: InputMaybe<Scalars['money']['input']>;
  _in?: InputMaybe<Array<Scalars['money']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['money']['input']>;
  _lte?: InputMaybe<Scalars['money']['input']>;
  _neq?: InputMaybe<Scalars['money']['input']>;
  _nin?: InputMaybe<Array<Scalars['money']['input']>>;
};

/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** order by aggregate values of table "photo" */
export type Photo_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Photo_Max_Order_By>;
  min?: InputMaybe<Photo_Min_Order_By>;
};

/** input type for inserting array relation for remote table "photo" */
export type Photo_Arr_Rel_Insert_Input = {
  data: Array<Photo_Insert_Input>;
  on_conflict?: InputMaybe<Photo_On_Conflict>;
};

/** Boolean expression to filter rows from the table "photo". All fields are combined with a logical 'AND'. */
export type Photo_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Photo_Bool_Exp>>>;
  _not?: InputMaybe<Photo_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Photo_Bool_Exp>>>;
  bucketName?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  downloadURL?: InputMaybe<String_Comparison_Exp>;
  downloadURLThumbnail?: InputMaybe<String_Comparison_Exp>;
  fileName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "photo" */
export enum Photo_Constraint {
  /** unique or primary key constraint */
  PhotoPkey = 'photo_pkey'
}

/** input type for inserting data into table "photo" */
export type Photo_Insert_Input = {
  bucketName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadURL?: InputMaybe<Scalars['String']['input']>;
  downloadURLThumbnail?: InputMaybe<Scalars['String']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "photo" */
export type Photo_Max_Order_By = {
  bucketName?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  downloadURL?: InputMaybe<Order_By>;
  downloadURLThumbnail?: InputMaybe<Order_By>;
  fileName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "photo" */
export type Photo_Min_Order_By = {
  bucketName?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  downloadURL?: InputMaybe<Order_By>;
  downloadURLThumbnail?: InputMaybe<Order_By>;
  fileName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "photo" */
export type Photo_Obj_Rel_Insert_Input = {
  data: Photo_Insert_Input;
  on_conflict?: InputMaybe<Photo_On_Conflict>;
};

/** on conflict condition type for table "photo" */
export type Photo_On_Conflict = {
  constraint: Photo_Constraint;
  update_columns: Array<Photo_Update_Column>;
  where?: InputMaybe<Photo_Bool_Exp>;
};

/** ordering options when selecting data from "photo" */
export type Photo_Order_By = {
  bucketName?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  downloadURL?: InputMaybe<Order_By>;
  downloadURLThumbnail?: InputMaybe<Order_By>;
  fileName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
};

/** primary key columns input for table: "photo" */
export type Photo_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "photo" */
export enum Photo_Select_Column {
  /** column name */
  BucketName = 'bucketName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DownloadUrl = 'downloadURL',
  /** column name */
  DownloadUrlThumbnail = 'downloadURLThumbnail',
  /** column name */
  FileName = 'fileName',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "photo" */
export type Photo_Set_Input = {
  bucketName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadURL?: InputMaybe<Scalars['String']['input']>;
  downloadURLThumbnail?: InputMaybe<Scalars['String']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "photo" */
export enum Photo_Update_Column {
  /** column name */
  BucketName = 'bucketName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DownloadUrl = 'downloadURL',
  /** column name */
  DownloadUrlThumbnail = 'downloadURLThumbnail',
  /** column name */
  FileName = 'fileName',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** order by aggregate values of table "push_token" */
export type Push_Token_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Push_Token_Max_Order_By>;
  min?: InputMaybe<Push_Token_Min_Order_By>;
};

/** input type for inserting array relation for remote table "push_token" */
export type Push_Token_Arr_Rel_Insert_Input = {
  data: Array<Push_Token_Insert_Input>;
  on_conflict?: InputMaybe<Push_Token_On_Conflict>;
};

/** Boolean expression to filter rows from the table "push_token". All fields are combined with a logical 'AND'. */
export type Push_Token_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Push_Token_Bool_Exp>>>;
  _not?: InputMaybe<Push_Token_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Push_Token_Bool_Exp>>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isExpired?: InputMaybe<Boolean_Comparison_Exp>;
  platform?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "push_token" */
export enum Push_Token_Constraint {
  /** unique or primary key constraint */
  PushTokenPkey = 'push_token_pkey'
}

/** input type for inserting data into table "push_token" */
export type Push_Token_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isExpired?: InputMaybe<Scalars['Boolean']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "push_token" */
export type Push_Token_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  platform?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "push_token" */
export type Push_Token_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  platform?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "push_token" */
export type Push_Token_Obj_Rel_Insert_Input = {
  data: Push_Token_Insert_Input;
  on_conflict?: InputMaybe<Push_Token_On_Conflict>;
};

/** on conflict condition type for table "push_token" */
export type Push_Token_On_Conflict = {
  constraint: Push_Token_Constraint;
  update_columns: Array<Push_Token_Update_Column>;
  where?: InputMaybe<Push_Token_Bool_Exp>;
};

/** ordering options when selecting data from "push_token" */
export type Push_Token_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isExpired?: InputMaybe<Order_By>;
  platform?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "push_token" */
export type Push_Token_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "push_token" */
export enum Push_Token_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsExpired = 'isExpired',
  /** column name */
  Platform = 'platform',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "push_token" */
export type Push_Token_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isExpired?: InputMaybe<Scalars['Boolean']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "push_token" */
export enum Push_Token_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsExpired = 'isExpired',
  /** column name */
  Platform = 'platform',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** order by aggregate values of table "search_event" */
export type Search_Event_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Search_Event_Max_Order_By>;
  min?: InputMaybe<Search_Event_Min_Order_By>;
};

/** input type for inserting array relation for remote table "search_event" */
export type Search_Event_Arr_Rel_Insert_Input = {
  data: Array<Search_Event_Insert_Input>;
  on_conflict?: InputMaybe<Search_Event_On_Conflict>;
};

/** Boolean expression to filter rows from the table "search_event". All fields are combined with a logical 'AND'. */
export type Search_Event_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Search_Event_Bool_Exp>>>;
  _not?: InputMaybe<Search_Event_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Search_Event_Bool_Exp>>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  trail?: InputMaybe<Trail_Bool_Exp>;
  trailId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "search_event" */
export enum Search_Event_Constraint {
  /** unique or primary key constraint */
  SearchEventPkey = 'search_event_pkey'
}

/** input type for inserting data into table "search_event" */
export type Search_Event_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  trail?: InputMaybe<Trail_Obj_Rel_Insert_Input>;
  trailId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "search_event" */
export type Search_Event_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  trailId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "search_event" */
export type Search_Event_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  trailId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "search_event" */
export type Search_Event_Obj_Rel_Insert_Input = {
  data: Search_Event_Insert_Input;
  on_conflict?: InputMaybe<Search_Event_On_Conflict>;
};

/** on conflict condition type for table "search_event" */
export type Search_Event_On_Conflict = {
  constraint: Search_Event_Constraint;
  update_columns: Array<Search_Event_Update_Column>;
  where?: InputMaybe<Search_Event_Bool_Exp>;
};

/** ordering options when selecting data from "search_event" */
export type Search_Event_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  trail?: InputMaybe<Trail_Order_By>;
  trailId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "search_event" */
export type Search_Event_Pk_Columns_Input = {
  trailId: Scalars['uuid']['input'];
  userId: Scalars['String']['input'];
};

/** select columns of table "search_event" */
export enum Search_Event_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TrailId = 'trailId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "search_event" */
export type Search_Event_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  trailId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "search_event" */
export enum Search_Event_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TrailId = 'trailId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** order by aggregate values of table "trail" */
export type Trail_Aggregate_Order_By = {
  avg?: InputMaybe<Trail_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Trail_Max_Order_By>;
  min?: InputMaybe<Trail_Min_Order_By>;
  stddev?: InputMaybe<Trail_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Trail_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Trail_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Trail_Sum_Order_By>;
  var_pop?: InputMaybe<Trail_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Trail_Var_Samp_Order_By>;
  variance?: InputMaybe<Trail_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "trail" */
export type Trail_Arr_Rel_Insert_Input = {
  data: Array<Trail_Insert_Input>;
  on_conflict?: InputMaybe<Trail_On_Conflict>;
};

/** order by avg() on columns of table "trail" */
export type Trail_Avg_Order_By = {
  passDayCost?: InputMaybe<Order_By>;
  passSeasonCost?: InputMaybe<Order_By>;
  trailsLength?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "trail". All fields are combined with a logical 'AND'. */
export type Trail_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Trail_Bool_Exp>>>;
  _not?: InputMaybe<Trail_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Trail_Bool_Exp>>>;
  admins?: InputMaybe<User_Trail_Administrate_Bool_Exp>;
  businessEmail?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  facebookURL?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  instagramURL?: InputMaybe<String_Comparison_Exp>;
  isClassicAllowed?: InputMaybe<Boolean_Comparison_Exp>;
  isDogsAllowed?: InputMaybe<Boolean_Comparison_Exp>;
  isLighted?: InputMaybe<Boolean_Comparison_Exp>;
  isSkateAllowed?: InputMaybe<Boolean_Comparison_Exp>;
  isSnowmaking?: InputMaybe<Boolean_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner?: InputMaybe<User_Bool_Exp>;
  ownerId?: InputMaybe<String_Comparison_Exp>;
  passDayCost?: InputMaybe<Money_Comparison_Exp>;
  passIsRequired?: InputMaybe<Boolean_Comparison_Exp>;
  passPurchaseURL?: InputMaybe<String_Comparison_Exp>;
  passSeasonCost?: InputMaybe<Money_Comparison_Exp>;
  photoId?: InputMaybe<Uuid_Comparison_Exp>;
  ssid?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  trailMapPhotoId?: InputMaybe<Uuid_Comparison_Exp>;
  trailReports?: InputMaybe<Trail_Report_Bool_Exp>;
  trailsLength?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userFollowers?: InputMaybe<User_Trail_Follow_Bool_Exp>;
  websiteURL?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "trail" */
export enum Trail_Constraint {
  /** unique or primary key constraint */
  TrailNameKey = 'trail_name_key',
  /** unique or primary key constraint */
  TrailPkey = 'trail_pkey',
  /** unique or primary key constraint */
  TrailSsidKey = 'trail_ssid_key'
}

/** input type for incrementing integer column in table "trail" */
export type Trail_Inc_Input = {
  passDayCost?: InputMaybe<Scalars['money']['input']>;
  passSeasonCost?: InputMaybe<Scalars['money']['input']>;
  trailsLength?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "trail" */
export type Trail_Insert_Input = {
  admins?: InputMaybe<User_Trail_Administrate_Arr_Rel_Insert_Input>;
  businessEmail?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  facebookURL?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  instagramURL?: InputMaybe<Scalars['String']['input']>;
  isClassicAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isDogsAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isLighted?: InputMaybe<Scalars['Boolean']['input']>;
  isSkateAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isSnowmaking?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<User_Obj_Rel_Insert_Input>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  passDayCost?: InputMaybe<Scalars['money']['input']>;
  passIsRequired?: InputMaybe<Scalars['Boolean']['input']>;
  passPurchaseURL?: InputMaybe<Scalars['String']['input']>;
  passSeasonCost?: InputMaybe<Scalars['money']['input']>;
  photoId?: InputMaybe<Scalars['uuid']['input']>;
  ssid?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  trailMapPhotoId?: InputMaybe<Scalars['uuid']['input']>;
  trailReports?: InputMaybe<Trail_Report_Arr_Rel_Insert_Input>;
  trailsLength?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userFollowers?: InputMaybe<User_Trail_Follow_Arr_Rel_Insert_Input>;
  websiteURL?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "trail" */
export type Trail_Max_Order_By = {
  businessEmail?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  facebookURL?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instagramURL?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ownerId?: InputMaybe<Order_By>;
  passDayCost?: InputMaybe<Order_By>;
  passPurchaseURL?: InputMaybe<Order_By>;
  passSeasonCost?: InputMaybe<Order_By>;
  photoId?: InputMaybe<Order_By>;
  ssid?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  trailMapPhotoId?: InputMaybe<Order_By>;
  trailsLength?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  websiteURL?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "trail" */
export type Trail_Min_Order_By = {
  businessEmail?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  facebookURL?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instagramURL?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ownerId?: InputMaybe<Order_By>;
  passDayCost?: InputMaybe<Order_By>;
  passPurchaseURL?: InputMaybe<Order_By>;
  passSeasonCost?: InputMaybe<Order_By>;
  photoId?: InputMaybe<Order_By>;
  ssid?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  trailMapPhotoId?: InputMaybe<Order_By>;
  trailsLength?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  websiteURL?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "trail" */
export type Trail_Obj_Rel_Insert_Input = {
  data: Trail_Insert_Input;
  on_conflict?: InputMaybe<Trail_On_Conflict>;
};

/** on conflict condition type for table "trail" */
export type Trail_On_Conflict = {
  constraint: Trail_Constraint;
  update_columns: Array<Trail_Update_Column>;
  where?: InputMaybe<Trail_Bool_Exp>;
};

/** ordering options when selecting data from "trail" */
export type Trail_Order_By = {
  admins_aggregate?: InputMaybe<User_Trail_Administrate_Aggregate_Order_By>;
  businessEmail?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  facebookURL?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instagramURL?: InputMaybe<Order_By>;
  isClassicAllowed?: InputMaybe<Order_By>;
  isDogsAllowed?: InputMaybe<Order_By>;
  isLighted?: InputMaybe<Order_By>;
  isSkateAllowed?: InputMaybe<Order_By>;
  isSnowmaking?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  locationId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner?: InputMaybe<User_Order_By>;
  ownerId?: InputMaybe<Order_By>;
  passDayCost?: InputMaybe<Order_By>;
  passIsRequired?: InputMaybe<Order_By>;
  passPurchaseURL?: InputMaybe<Order_By>;
  passSeasonCost?: InputMaybe<Order_By>;
  photoId?: InputMaybe<Order_By>;
  ssid?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  trailMapPhotoId?: InputMaybe<Order_By>;
  trailReports_aggregate?: InputMaybe<Trail_Report_Aggregate_Order_By>;
  trailsLength?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userFollowers_aggregate?: InputMaybe<User_Trail_Follow_Aggregate_Order_By>;
  websiteURL?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "trail" */
export type Trail_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** order by aggregate values of table "trail_report" */
export type Trail_Report_Aggregate_Order_By = {
  avg?: InputMaybe<Trail_Report_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Trail_Report_Max_Order_By>;
  min?: InputMaybe<Trail_Report_Min_Order_By>;
  stddev?: InputMaybe<Trail_Report_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Trail_Report_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Trail_Report_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Trail_Report_Sum_Order_By>;
  var_pop?: InputMaybe<Trail_Report_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Trail_Report_Var_Samp_Order_By>;
  variance?: InputMaybe<Trail_Report_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "trail_report" */
export type Trail_Report_Arr_Rel_Insert_Input = {
  data: Array<Trail_Report_Insert_Input>;
  on_conflict?: InputMaybe<Trail_Report_On_Conflict>;
};

/** order by avg() on columns of table "trail_report" */
export type Trail_Report_Avg_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "trail_report". All fields are combined with a logical 'AND'. */
export type Trail_Report_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Trail_Report_Bool_Exp>>>;
  _not?: InputMaybe<Trail_Report_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Trail_Report_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isGroomingReport?: InputMaybe<Boolean_Comparison_Exp>;
  owner?: InputMaybe<User_Bool_Exp>;
  ownerId?: InputMaybe<String_Comparison_Exp>;
  photos?: InputMaybe<Trail_Report_Photo_Own_Bool_Exp>;
  rating?: InputMaybe<Int_Comparison_Exp>;
  skiRecommendation?: InputMaybe<String_Comparison_Exp>;
  skiTime?: InputMaybe<String_Comparison_Exp>;
  snowType?: InputMaybe<String_Comparison_Exp>;
  trail?: InputMaybe<Trail_Bool_Exp>;
  trailId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  weather?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "trail_report" */
export enum Trail_Report_Constraint {
  /** unique or primary key constraint */
  TrailReportPkey = 'trailReport_pkey'
}

/** input type for incrementing integer column in table "trail_report" */
export type Trail_Report_Inc_Input = {
  rating?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "trail_report" */
export type Trail_Report_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isGroomingReport?: InputMaybe<Scalars['Boolean']['input']>;
  owner?: InputMaybe<User_Obj_Rel_Insert_Input>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  photos?: InputMaybe<Trail_Report_Photo_Own_Arr_Rel_Insert_Input>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  skiRecommendation?: InputMaybe<Scalars['String']['input']>;
  skiTime?: InputMaybe<Scalars['String']['input']>;
  snowType?: InputMaybe<Scalars['String']['input']>;
  trail?: InputMaybe<Trail_Obj_Rel_Insert_Input>;
  trailId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  weather?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "trail_report" */
export type Trail_Report_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ownerId?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  skiRecommendation?: InputMaybe<Order_By>;
  skiTime?: InputMaybe<Order_By>;
  snowType?: InputMaybe<Order_By>;
  trailId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  weather?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "trail_report" */
export type Trail_Report_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ownerId?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  skiRecommendation?: InputMaybe<Order_By>;
  skiTime?: InputMaybe<Order_By>;
  snowType?: InputMaybe<Order_By>;
  trailId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  weather?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "trail_report" */
export type Trail_Report_Obj_Rel_Insert_Input = {
  data: Trail_Report_Insert_Input;
  on_conflict?: InputMaybe<Trail_Report_On_Conflict>;
};

/** on conflict condition type for table "trail_report" */
export type Trail_Report_On_Conflict = {
  constraint: Trail_Report_Constraint;
  update_columns: Array<Trail_Report_Update_Column>;
  where?: InputMaybe<Trail_Report_Bool_Exp>;
};

/** ordering options when selecting data from "trail_report" */
export type Trail_Report_Order_By = {
  comment?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isGroomingReport?: InputMaybe<Order_By>;
  owner?: InputMaybe<User_Order_By>;
  ownerId?: InputMaybe<Order_By>;
  photos_aggregate?: InputMaybe<Trail_Report_Photo_Own_Aggregate_Order_By>;
  rating?: InputMaybe<Order_By>;
  skiRecommendation?: InputMaybe<Order_By>;
  skiTime?: InputMaybe<Order_By>;
  snowType?: InputMaybe<Order_By>;
  trail?: InputMaybe<Trail_Order_By>;
  trailId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  weather?: InputMaybe<Order_By>;
};

/** order by aggregate values of table "trail_report_photo_own" */
export type Trail_Report_Photo_Own_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Trail_Report_Photo_Own_Max_Order_By>;
  min?: InputMaybe<Trail_Report_Photo_Own_Min_Order_By>;
};

/** input type for inserting array relation for remote table "trail_report_photo_own" */
export type Trail_Report_Photo_Own_Arr_Rel_Insert_Input = {
  data: Array<Trail_Report_Photo_Own_Insert_Input>;
  on_conflict?: InputMaybe<Trail_Report_Photo_Own_On_Conflict>;
};

/** Boolean expression to filter rows from the table "trail_report_photo_own". All fields are combined with a logical 'AND'. */
export type Trail_Report_Photo_Own_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Trail_Report_Photo_Own_Bool_Exp>>>;
  _not?: InputMaybe<Trail_Report_Photo_Own_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Trail_Report_Photo_Own_Bool_Exp>>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  photo?: InputMaybe<Photo_Bool_Exp>;
  photoId?: InputMaybe<Uuid_Comparison_Exp>;
  trailReport?: InputMaybe<Trail_Report_Bool_Exp>;
  trailReportId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "trail_report_photo_own" */
export enum Trail_Report_Photo_Own_Constraint {
  /** unique or primary key constraint */
  TrailReportPhotoOwnPkey = 'trail_report_photo_own_pkey'
}

/** input type for inserting data into table "trail_report_photo_own" */
export type Trail_Report_Photo_Own_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  photo?: InputMaybe<Photo_Obj_Rel_Insert_Input>;
  photoId?: InputMaybe<Scalars['uuid']['input']>;
  trailReport?: InputMaybe<Trail_Report_Obj_Rel_Insert_Input>;
  trailReportId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by max() on columns of table "trail_report_photo_own" */
export type Trail_Report_Photo_Own_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  photoId?: InputMaybe<Order_By>;
  trailReportId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "trail_report_photo_own" */
export type Trail_Report_Photo_Own_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  photoId?: InputMaybe<Order_By>;
  trailReportId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "trail_report_photo_own" */
export type Trail_Report_Photo_Own_Obj_Rel_Insert_Input = {
  data: Trail_Report_Photo_Own_Insert_Input;
  on_conflict?: InputMaybe<Trail_Report_Photo_Own_On_Conflict>;
};

/** on conflict condition type for table "trail_report_photo_own" */
export type Trail_Report_Photo_Own_On_Conflict = {
  constraint: Trail_Report_Photo_Own_Constraint;
  update_columns: Array<Trail_Report_Photo_Own_Update_Column>;
  where?: InputMaybe<Trail_Report_Photo_Own_Bool_Exp>;
};

/** ordering options when selecting data from "trail_report_photo_own" */
export type Trail_Report_Photo_Own_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  photo?: InputMaybe<Photo_Order_By>;
  photoId?: InputMaybe<Order_By>;
  trailReport?: InputMaybe<Trail_Report_Order_By>;
  trailReportId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "trail_report_photo_own" */
export type Trail_Report_Photo_Own_Pk_Columns_Input = {
  photoId: Scalars['uuid']['input'];
  trailReportId: Scalars['uuid']['input'];
};

/** select columns of table "trail_report_photo_own" */
export enum Trail_Report_Photo_Own_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  PhotoId = 'photoId',
  /** column name */
  TrailReportId = 'trailReportId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "trail_report_photo_own" */
export type Trail_Report_Photo_Own_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  photoId?: InputMaybe<Scalars['uuid']['input']>;
  trailReportId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "trail_report_photo_own" */
export enum Trail_Report_Photo_Own_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  PhotoId = 'photoId',
  /** column name */
  TrailReportId = 'trailReportId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** primary key columns input for table: "trail_report" */
export type Trail_Report_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "trail_report" */
export enum Trail_Report_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsGroomingReport = 'isGroomingReport',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  Rating = 'rating',
  /** column name */
  SkiRecommendation = 'skiRecommendation',
  /** column name */
  SkiTime = 'skiTime',
  /** column name */
  SnowType = 'snowType',
  /** column name */
  TrailId = 'trailId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Weather = 'weather'
}

/** input type for updating data in table "trail_report" */
export type Trail_Report_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isGroomingReport?: InputMaybe<Scalars['Boolean']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  skiRecommendation?: InputMaybe<Scalars['String']['input']>;
  skiTime?: InputMaybe<Scalars['String']['input']>;
  snowType?: InputMaybe<Scalars['String']['input']>;
  trailId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  weather?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "trail_report" */
export type Trail_Report_Stddev_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "trail_report" */
export type Trail_Report_Stddev_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "trail_report" */
export type Trail_Report_Stddev_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "trail_report" */
export type Trail_Report_Sum_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** update columns of table "trail_report" */
export enum Trail_Report_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsGroomingReport = 'isGroomingReport',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  Rating = 'rating',
  /** column name */
  SkiRecommendation = 'skiRecommendation',
  /** column name */
  SkiTime = 'skiTime',
  /** column name */
  SnowType = 'snowType',
  /** column name */
  TrailId = 'trailId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Weather = 'weather'
}

/** order by var_pop() on columns of table "trail_report" */
export type Trail_Report_Var_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "trail_report" */
export type Trail_Report_Var_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "trail_report" */
export type Trail_Report_Variance_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** select columns of table "trail" */
export enum Trail_Select_Column {
  /** column name */
  BusinessEmail = 'businessEmail',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  FacebookUrl = 'facebookURL',
  /** column name */
  Id = 'id',
  /** column name */
  InstagramUrl = 'instagramURL',
  /** column name */
  IsClassicAllowed = 'isClassicAllowed',
  /** column name */
  IsDogsAllowed = 'isDogsAllowed',
  /** column name */
  IsLighted = 'isLighted',
  /** column name */
  IsSkateAllowed = 'isSkateAllowed',
  /** column name */
  IsSnowmaking = 'isSnowmaking',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  PassDayCost = 'passDayCost',
  /** column name */
  PassIsRequired = 'passIsRequired',
  /** column name */
  PassPurchaseUrl = 'passPurchaseURL',
  /** column name */
  PassSeasonCost = 'passSeasonCost',
  /** column name */
  PhotoId = 'photoId',
  /** column name */
  Ssid = 'ssid',
  /** column name */
  Status = 'status',
  /** column name */
  TrailMapPhotoId = 'trailMapPhotoId',
  /** column name */
  TrailsLength = 'trailsLength',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteUrl = 'websiteURL'
}

/** input type for updating data in table "trail" */
export type Trail_Set_Input = {
  businessEmail?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  facebookURL?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  instagramURL?: InputMaybe<Scalars['String']['input']>;
  isClassicAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isDogsAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isLighted?: InputMaybe<Scalars['Boolean']['input']>;
  isSkateAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isSnowmaking?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  passDayCost?: InputMaybe<Scalars['money']['input']>;
  passIsRequired?: InputMaybe<Scalars['Boolean']['input']>;
  passPurchaseURL?: InputMaybe<Scalars['String']['input']>;
  passSeasonCost?: InputMaybe<Scalars['money']['input']>;
  photoId?: InputMaybe<Scalars['uuid']['input']>;
  ssid?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  trailMapPhotoId?: InputMaybe<Scalars['uuid']['input']>;
  trailsLength?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  websiteURL?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "trail" */
export type Trail_Stddev_Order_By = {
  passDayCost?: InputMaybe<Order_By>;
  passSeasonCost?: InputMaybe<Order_By>;
  trailsLength?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "trail" */
export type Trail_Stddev_Pop_Order_By = {
  passDayCost?: InputMaybe<Order_By>;
  passSeasonCost?: InputMaybe<Order_By>;
  trailsLength?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "trail" */
export type Trail_Stddev_Samp_Order_By = {
  passDayCost?: InputMaybe<Order_By>;
  passSeasonCost?: InputMaybe<Order_By>;
  trailsLength?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "trail" */
export type Trail_Sum_Order_By = {
  passDayCost?: InputMaybe<Order_By>;
  passSeasonCost?: InputMaybe<Order_By>;
  trailsLength?: InputMaybe<Order_By>;
};

/** update columns of table "trail" */
export enum Trail_Update_Column {
  /** column name */
  BusinessEmail = 'businessEmail',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  FacebookUrl = 'facebookURL',
  /** column name */
  Id = 'id',
  /** column name */
  InstagramUrl = 'instagramURL',
  /** column name */
  IsClassicAllowed = 'isClassicAllowed',
  /** column name */
  IsDogsAllowed = 'isDogsAllowed',
  /** column name */
  IsLighted = 'isLighted',
  /** column name */
  IsSkateAllowed = 'isSkateAllowed',
  /** column name */
  IsSnowmaking = 'isSnowmaking',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  PassDayCost = 'passDayCost',
  /** column name */
  PassIsRequired = 'passIsRequired',
  /** column name */
  PassPurchaseUrl = 'passPurchaseURL',
  /** column name */
  PassSeasonCost = 'passSeasonCost',
  /** column name */
  PhotoId = 'photoId',
  /** column name */
  Ssid = 'ssid',
  /** column name */
  Status = 'status',
  /** column name */
  TrailMapPhotoId = 'trailMapPhotoId',
  /** column name */
  TrailsLength = 'trailsLength',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteUrl = 'websiteURL'
}

/** order by var_pop() on columns of table "trail" */
export type Trail_Var_Pop_Order_By = {
  passDayCost?: InputMaybe<Order_By>;
  passSeasonCost?: InputMaybe<Order_By>;
  trailsLength?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "trail" */
export type Trail_Var_Samp_Order_By = {
  passDayCost?: InputMaybe<Order_By>;
  passSeasonCost?: InputMaybe<Order_By>;
  trailsLength?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "trail" */
export type Trail_Variance_Order_By = {
  passDayCost?: InputMaybe<Order_By>;
  passSeasonCost?: InputMaybe<Order_By>;
  trailsLength?: InputMaybe<Order_By>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Max_Order_By>;
  min?: InputMaybe<User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Bool_Exp>>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Bool_Exp>>>;
  adminsTrails?: InputMaybe<User_Trail_Administrate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  disabled?: InputMaybe<Boolean_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  feedbacks?: InputMaybe<Feedback_Bool_Exp>;
  firstname?: InputMaybe<String_Comparison_Exp>;
  followingTrails?: InputMaybe<User_Trail_Follow_Bool_Exp>;
  hasRatedOnStore?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lastname?: InputMaybe<String_Comparison_Exp>;
  membershipType?: InputMaybe<String_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  ownedTrails?: InputMaybe<Trail_Bool_Exp>;
  photo?: InputMaybe<Photo_Bool_Exp>;
  photoId?: InputMaybe<Uuid_Comparison_Exp>;
  pushTokens?: InputMaybe<Push_Token_Bool_Exp>;
  trailReports?: InputMaybe<Trail_Report_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserDisplayNameKey = 'user_display_name_key',
  /** unique or primary key constraint */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  adminsTrails?: InputMaybe<User_Trail_Administrate_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  feedbacks?: InputMaybe<Feedback_Arr_Rel_Insert_Input>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  followingTrails?: InputMaybe<User_Trail_Follow_Arr_Rel_Insert_Input>;
  hasRatedOnStore?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  membershipType?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  ownedTrails?: InputMaybe<Trail_Arr_Rel_Insert_Input>;
  photo?: InputMaybe<Photo_Obj_Rel_Insert_Input>;
  photoId?: InputMaybe<Scalars['uuid']['input']>;
  pushTokens?: InputMaybe<Push_Token_Arr_Rel_Insert_Input>;
  trailReports?: InputMaybe<Trail_Report_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstname?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastname?: InputMaybe<Order_By>;
  membershipType?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  photoId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstname?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastname?: InputMaybe<Order_By>;
  membershipType?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  photoId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** ordering options when selecting data from "user" */
export type User_Order_By = {
  adminsTrails_aggregate?: InputMaybe<User_Trail_Administrate_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  disabled?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  feedbacks_aggregate?: InputMaybe<Feedback_Aggregate_Order_By>;
  firstname?: InputMaybe<Order_By>;
  followingTrails_aggregate?: InputMaybe<User_Trail_Follow_Aggregate_Order_By>;
  hasRatedOnStore?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastname?: InputMaybe<Order_By>;
  membershipType?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  ownedTrails_aggregate?: InputMaybe<Trail_Aggregate_Order_By>;
  photo?: InputMaybe<Photo_Order_By>;
  photoId?: InputMaybe<Order_By>;
  pushTokens_aggregate?: InputMaybe<Push_Token_Aggregate_Order_By>;
  trailReports_aggregate?: InputMaybe<Trail_Report_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "user" */
export type User_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  HasRatedOnStore = 'hasRatedOnStore',
  /** column name */
  Id = 'id',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  MembershipType = 'membershipType',
  /** column name */
  Note = 'note',
  /** column name */
  PhotoId = 'photoId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  hasRatedOnStore?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  membershipType?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  photoId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by aggregate values of table "user_trail_administrate" */
export type User_Trail_Administrate_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Trail_Administrate_Max_Order_By>;
  min?: InputMaybe<User_Trail_Administrate_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_trail_administrate" */
export type User_Trail_Administrate_Arr_Rel_Insert_Input = {
  data: Array<User_Trail_Administrate_Insert_Input>;
  on_conflict?: InputMaybe<User_Trail_Administrate_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_trail_administrate". All fields are combined with a logical 'AND'. */
export type User_Trail_Administrate_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Trail_Administrate_Bool_Exp>>>;
  _not?: InputMaybe<User_Trail_Administrate_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Trail_Administrate_Bool_Exp>>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  trail?: InputMaybe<Trail_Bool_Exp>;
  trailId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_trail_administrate" */
export enum User_Trail_Administrate_Constraint {
  /** unique or primary key constraint */
  UserTrailAdministratePkey = 'user_trail_administrate_pkey'
}

/** input type for inserting data into table "user_trail_administrate" */
export type User_Trail_Administrate_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  trail?: InputMaybe<Trail_Obj_Rel_Insert_Input>;
  trailId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "user_trail_administrate" */
export type User_Trail_Administrate_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  trailId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "user_trail_administrate" */
export type User_Trail_Administrate_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  trailId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "user_trail_administrate" */
export type User_Trail_Administrate_Obj_Rel_Insert_Input = {
  data: User_Trail_Administrate_Insert_Input;
  on_conflict?: InputMaybe<User_Trail_Administrate_On_Conflict>;
};

/** on conflict condition type for table "user_trail_administrate" */
export type User_Trail_Administrate_On_Conflict = {
  constraint: User_Trail_Administrate_Constraint;
  update_columns: Array<User_Trail_Administrate_Update_Column>;
  where?: InputMaybe<User_Trail_Administrate_Bool_Exp>;
};

/** ordering options when selecting data from "user_trail_administrate" */
export type User_Trail_Administrate_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  trail?: InputMaybe<Trail_Order_By>;
  trailId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "user_trail_administrate" */
export type User_Trail_Administrate_Pk_Columns_Input = {
  trailId: Scalars['uuid']['input'];
  userId: Scalars['String']['input'];
};

/** select columns of table "user_trail_administrate" */
export enum User_Trail_Administrate_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  TrailId = 'trailId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_trail_administrate" */
export type User_Trail_Administrate_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  trailId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_trail_administrate" */
export enum User_Trail_Administrate_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  TrailId = 'trailId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** order by aggregate values of table "user_trail_follow" */
export type User_Trail_Follow_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Trail_Follow_Max_Order_By>;
  min?: InputMaybe<User_Trail_Follow_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_trail_follow" */
export type User_Trail_Follow_Arr_Rel_Insert_Input = {
  data: Array<User_Trail_Follow_Insert_Input>;
  on_conflict?: InputMaybe<User_Trail_Follow_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_trail_follow". All fields are combined with a logical 'AND'. */
export type User_Trail_Follow_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Trail_Follow_Bool_Exp>>>;
  _not?: InputMaybe<User_Trail_Follow_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Trail_Follow_Bool_Exp>>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  trail?: InputMaybe<Trail_Bool_Exp>;
  trailId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_trail_follow" */
export enum User_Trail_Follow_Constraint {
  /** unique or primary key constraint */
  UserTrailPkey = 'user_trail_pkey'
}

/** input type for inserting data into table "user_trail_follow" */
export type User_Trail_Follow_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  trail?: InputMaybe<Trail_Obj_Rel_Insert_Input>;
  trailId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "user_trail_follow" */
export type User_Trail_Follow_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  trailId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "user_trail_follow" */
export type User_Trail_Follow_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  trailId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "user_trail_follow" */
export type User_Trail_Follow_Obj_Rel_Insert_Input = {
  data: User_Trail_Follow_Insert_Input;
  on_conflict?: InputMaybe<User_Trail_Follow_On_Conflict>;
};

/** on conflict condition type for table "user_trail_follow" */
export type User_Trail_Follow_On_Conflict = {
  constraint: User_Trail_Follow_Constraint;
  update_columns: Array<User_Trail_Follow_Update_Column>;
  where?: InputMaybe<User_Trail_Follow_Bool_Exp>;
};

/** ordering options when selecting data from "user_trail_follow" */
export type User_Trail_Follow_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  trail?: InputMaybe<Trail_Order_By>;
  trailId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "user_trail_follow" */
export type User_Trail_Follow_Pk_Columns_Input = {
  trailId: Scalars['uuid']['input'];
  userId: Scalars['String']['input'];
};

/** select columns of table "user_trail_follow" */
export enum User_Trail_Follow_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  TrailId = 'trailId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_trail_follow" */
export type User_Trail_Follow_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  trailId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_trail_follow" */
export enum User_Trail_Follow_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  TrailId = 'trailId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  HasRatedOnStore = 'hasRatedOnStore',
  /** column name */
  Id = 'id',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  MembershipType = 'membershipType',
  /** column name */
  Note = 'note',
  /** column name */
  PhotoId = 'photoId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

export type ProfileEditableFragmentFragment = { id: string, displayName: string, description?: string | undefined, photo?: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined, fileName?: string | undefined, bucketName?: string | undefined } | undefined };

export type PublicProfileFragment = { id: string, displayName: string, description?: string | undefined, createdAt: string, membershipType: string, photo?: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined } | undefined, trailReports_aggregate: { aggregate?: { count?: number | undefined } | undefined }, trailReports: Array<{ trailId: string, id: string, createdAt: string, comment: string, rating: number, skiRecommendation?: string | undefined, skiTime?: string | undefined, snowType?: string | undefined, weather?: string | undefined, isGroomingReport?: boolean | undefined, trail: { id: string, name: string }, photos: Array<{ photo: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined } }>, owner: { id: string, displayName: string, photo?: { downloadURL: string } | undefined } }> };

export type TrailDetailsFragment = { id: string, name: string, isClassicAllowed: boolean, isDogsAllowed: boolean, isLighted: boolean, isSkateAllowed: boolean, isSnowmaking: boolean, description?: string | undefined, passDayCost?: any | undefined, passIsRequired: boolean, passPurchaseURL?: string | undefined, passSeasonCost?: any | undefined, photoId?: string | undefined, trailsLength?: number | undefined, websiteURL?: string | undefined, facebookURL?: string | undefined, instagramURL?: string | undefined, location: { id: string, city?: string | undefined, state?: string | undefined, lat: number, lng: number }, userFollowers_aggregate: { aggregate?: { count?: number | undefined } | undefined }, trailReports: Array<{ id: string, createdAt: string, comment: string, rating: number, skiRecommendation?: string | undefined, skiTime?: string | undefined, snowType?: string | undefined, weather?: string | undefined, isGroomingReport?: boolean | undefined, trail: { id: string, name: string }, photos: Array<{ photo: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined } }>, owner: { id: string, displayName: string, photo?: { downloadURL: string } | undefined } }>, admins: Array<{ user: { id: string, displayName: string, createdAt: string, membershipType: string, photo?: { downloadURL: string } | undefined } }> };

export type TrailFragment = { id: string, name: string, trailsLength?: number | undefined, location: { id: string, city?: string | undefined, state?: string | undefined, lat: number, lng: number }, userFollowers_aggregate: { aggregate?: { count?: number | undefined } | undefined }, trailReports: Array<{ id: string, createdAt: string, comment: string, rating: number, skiRecommendation?: string | undefined, skiTime?: string | undefined, snowType?: string | undefined, weather?: string | undefined, isGroomingReport?: boolean | undefined, trail: { id: string, name: string }, photos: Array<{ photo: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined } }>, owner: { id: string, displayName: string, photo?: { downloadURL: string } | undefined } }> };

export type TrailReportFragment = { id: string, createdAt: string, comment: string, rating: number, skiRecommendation?: string | undefined, skiTime?: string | undefined, snowType?: string | undefined, weather?: string | undefined, isGroomingReport?: boolean | undefined, trail: { id: string, name: string }, photos: Array<{ photo: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined } }>, owner: { id: string, displayName: string, photo?: { downloadURL: string } | undefined } };

export type UserFragment = { id: string, displayName: string, description?: string | undefined, membershipType: string, photo?: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined, fileName?: string | undefined, bucketName?: string | undefined } | undefined, trailReports_aggregate: { aggregate?: { count?: number | undefined } | undefined }, ownedTrails: Array<{ id: string }>, followingTrails: Array<{ trailId: string }> };

export type AdminPageDetailsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type AdminPageDetailsQuery = { trails: Array<{ id: string, name: string }> };

export type CheckIfAccountExistsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type CheckIfAccountExistsQuery = { user?: { id: string } | undefined };

export type TrailFeedQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type TrailFeedQuery = { user?: { followingTrails: Array<{ trail: { id: string, name: string, trailReports: Array<{ id: string, createdAt: string, comment: string, rating: number, skiRecommendation?: string | undefined, skiTime?: string | undefined, snowType?: string | undefined, weather?: string | undefined, isGroomingReport?: boolean | undefined, trail: { id: string, name: string }, photos: Array<{ photo: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined } }>, owner: { id: string, displayName: string, photo?: { downloadURL: string } | undefined } }> } }> } | undefined };

export type ProfileEditableQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ProfileEditableQuery = { user?: { id: string, displayName: string, description?: string | undefined, photo?: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined, fileName?: string | undefined, bucketName?: string | undefined } | undefined } | undefined };

export type ProfileHeaderDetailsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ProfileHeaderDetailsQuery = { user?: { id: string, displayName: string, membershipType: string, photo?: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined } | undefined } | undefined };

export type PublicProfilePageDetailsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type PublicProfilePageDetailsQuery = { user?: { id: string, displayName: string, description?: string | undefined, createdAt: string, membershipType: string, photo?: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined } | undefined, trailReports_aggregate: { aggregate?: { count?: number | undefined } | undefined }, trailReports: Array<{ trailId: string, id: string, createdAt: string, comment: string, rating: number, skiRecommendation?: string | undefined, skiTime?: string | undefined, snowType?: string | undefined, weather?: string | undefined, isGroomingReport?: boolean | undefined, trail: { id: string, name: string }, photos: Array<{ photo: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined } }>, owner: { id: string, displayName: string, photo?: { downloadURL: string } | undefined } }> } | undefined };

export type TrailDetailsQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
  userId: Scalars['String']['input'];
}>;


export type TrailDetailsQuery = { trail?: { id: string, name: string, isClassicAllowed: boolean, isDogsAllowed: boolean, isLighted: boolean, isSkateAllowed: boolean, isSnowmaking: boolean, description?: string | undefined, passDayCost?: any | undefined, passIsRequired: boolean, passPurchaseURL?: string | undefined, passSeasonCost?: any | undefined, photoId?: string | undefined, trailsLength?: number | undefined, websiteURL?: string | undefined, facebookURL?: string | undefined, instagramURL?: string | undefined, location: { id: string, city?: string | undefined, state?: string | undefined, lat: number, lng: number }, userFollowers_aggregate: { aggregate?: { count?: number | undefined } | undefined }, trailReports: Array<{ id: string, createdAt: string, comment: string, rating: number, skiRecommendation?: string | undefined, skiTime?: string | undefined, snowType?: string | undefined, weather?: string | undefined, isGroomingReport?: boolean | undefined, trail: { id: string, name: string }, photos: Array<{ photo: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined } }>, owner: { id: string, displayName: string, photo?: { downloadURL: string } | undefined } }>, admins: Array<{ user: { id: string, displayName: string, createdAt: string, membershipType: string, photo?: { downloadURL: string } | undefined } }> } | undefined };

export type TrailForEditingQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type TrailForEditingQuery = { trail?: { businessEmail?: string | undefined, description?: string | undefined, facebookURL?: string | undefined, id: string, instagramURL?: string | undefined, isClassicAllowed: boolean, isDogsAllowed: boolean, isLighted: boolean, isSkateAllowed: boolean, isSnowmaking: boolean, name: string, passDayCost?: any | undefined, passIsRequired: boolean, passPurchaseURL?: string | undefined, passSeasonCost?: any | undefined, trailsLength?: number | undefined, websiteURL?: string | undefined, location: { lat: number, lng: number } } | undefined };

export type TrailReportEditableQueryVariables = Exact<{
  trailId: Scalars['uuid']['input'];
}>;


export type TrailReportEditableQuery = { trail?: { id: string, name: string, admins: Array<{ user: { id: string } }> } | undefined };

export type TrailStatusQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type TrailStatusQuery = { trail?: { id: string, name: string, trailReports: Array<{ id: string, createdAt: string, comment: string, rating: number }> } | undefined };

export type TrailsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type TrailsQuery = { trails: Array<{ id: string, name: string, trailsLength?: number | undefined, location: { id: string, city?: string | undefined, state?: string | undefined, lat: number, lng: number }, userFollowers_aggregate: { aggregate?: { count?: number | undefined } | undefined }, trailReports: Array<{ id: string, createdAt: string, comment: string, rating: number, skiRecommendation?: string | undefined, skiTime?: string | undefined, snowType?: string | undefined, weather?: string | undefined, isGroomingReport?: boolean | undefined, trail: { id: string, name: string }, photos: Array<{ photo: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined } }>, owner: { id: string, displayName: string, photo?: { downloadURL: string } | undefined } }> }> };

export type UserFollowingTrailQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  trailId: Scalars['uuid']['input'];
}>;


export type UserFollowingTrailQuery = { userTrailFollow?: { userId: string } | undefined };

export type UserQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type UserQuery = { user?: { id: string, displayName: string, description?: string | undefined, membershipType: string, photo?: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined, fileName?: string | undefined, bucketName?: string | undefined } | undefined, trailReports_aggregate: { aggregate?: { count?: number | undefined } | undefined }, ownedTrails: Array<{ id: string }>, followingTrails: Array<{ trailId: string }> } | undefined, searchEvents: Array<{ trail: { id: string, name: string, trailsLength?: number | undefined, location: { id: string, city?: string | undefined, state?: string | undefined, lat: number, lng: number }, userFollowers_aggregate: { aggregate?: { count?: number | undefined } | undefined }, trailReports: Array<{ id: string, createdAt: string, comment: string, rating: number, skiRecommendation?: string | undefined, skiTime?: string | undefined, snowType?: string | undefined, weather?: string | undefined, isGroomingReport?: boolean | undefined, trail: { id: string, name: string }, photos: Array<{ photo: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined } }>, owner: { id: string, displayName: string, photo?: { downloadURL: string } | undefined } }> } }> };

export type UploadRegisteredPushTokenMutationVariables = Exact<{
  object: Push_Token_Insert_Input;
}>;


export type UploadRegisteredPushTokenMutation = { insertPushToken?: { id: string } | undefined };

export type CreateAccountMutationVariables = Exact<{
  id: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  photoId: Scalars['uuid']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateAccountMutation = { insertUser?: { id: string, displayName: string } | undefined };

export type UploadTrailReportMutationVariables = Exact<{
  object: Trail_Report_Insert_Input;
}>;


export type UploadTrailReportMutation = { insertTrailReport?: { id: string, createdAt: string, comment: string, rating: number, skiRecommendation?: string | undefined, skiTime?: string | undefined, snowType?: string | undefined, weather?: string | undefined, isGroomingReport?: boolean | undefined, trail: { id: string, name: string }, photos: Array<{ photo: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined } }>, owner: { id: string, displayName: string, photo?: { downloadURL: string } | undefined } } | undefined };

export type CreateTrailMutationVariables = Exact<{
  name: Scalars['String']['input'];
  ownerId?: InputMaybe<Scalars['String']['input']>;
  location: Location_Obj_Rel_Insert_Input;
  businessEmail?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateTrailMutation = { insertTrail?: { id: string } | undefined };

export type EditTrailMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  object?: InputMaybe<Trail_Set_Input>;
}>;


export type EditTrailMutation = { updateTrail?: { id: string, name: string, isClassicAllowed: boolean, isDogsAllowed: boolean, isLighted: boolean, isSkateAllowed: boolean, isSnowmaking: boolean, description?: string | undefined, passDayCost?: any | undefined, passIsRequired: boolean, passPurchaseURL?: string | undefined, passSeasonCost?: any | undefined, photoId?: string | undefined, trailsLength?: number | undefined, websiteURL?: string | undefined, facebookURL?: string | undefined, instagramURL?: string | undefined, owner?: { id: string, displayName: string, membershipType: string } | undefined } | undefined };

export type CreateFeedbackMutationVariables = Exact<{
  type: Scalars['String']['input'];
  message: Scalars['String']['input'];
}>;


export type CreateFeedbackMutation = { insertFeedback?: { id: string } | undefined };

export type EditProfileMutationVariables = Exact<{
  id: Scalars['String']['input'];
  photo?: InputMaybe<Photo_Obj_Rel_Insert_Input>;
  displayName: Scalars['String']['input'];
  description: Scalars['String']['input'];
}>;


export type EditProfileMutation = { insertUser?: { id: string, displayName: string, description?: string | undefined, photo?: { id: string, downloadURL: string, downloadURLThumbnail?: string | undefined, fileName?: string | undefined, bucketName?: string | undefined } | undefined } | undefined };

export type FollowTrailMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  trailId: Scalars['uuid']['input'];
}>;


export type FollowTrailMutation = { insertUserTrailFollow?: { userId: string } | undefined };

export type UnfollowTrailMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  trailId: Scalars['uuid']['input'];
}>;


export type UnfollowTrailMutation = { deleteUserTrailFollow?: { userId: string } | undefined };

export type DeleteTrailReportMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type DeleteTrailReportMutation = { deleteTrailReport?: { id: string } | undefined };

export type AddTrailSearchEventMutationVariables = Exact<{
  trailId: Scalars['uuid']['input'];
}>;


export type AddTrailSearchEventMutation = { insertSearchEvent?: { id: string, updatedAt?: string | undefined, trail: { name: string } } | undefined };

export const ProfileEditableFragmentFragmentDoc = gql`
    fragment ProfileEditableFragment on user {
  id
  displayName
  photo {
    id
    downloadURL
    downloadURLThumbnail
    fileName
    bucketName
  }
  description
}
    `;
export const TrailReportFragmentDoc = gql`
    fragment TrailReport on trail_report {
  id
  createdAt
  comment
  rating
  skiRecommendation
  skiTime
  snowType
  weather
  isGroomingReport
  trail {
    id
    name
  }
  photos {
    photo {
      id
      downloadURL
      downloadURLThumbnail
    }
  }
  owner {
    id
    displayName
    photo {
      downloadURL
    }
  }
}
    `;
export const PublicProfileFragmentDoc = gql`
    fragment PublicProfile on user {
  id
  displayName
  description
  createdAt
  membershipType
  photo {
    id
    downloadURL
    downloadURLThumbnail
  }
  trailReports_aggregate {
    aggregate {
      count
    }
  }
  trailReports(order_by: {createdAt: desc}) {
    ...TrailReport
    trailId
  }
}
    ${TrailReportFragmentDoc}`;
export const TrailDetailsFragmentDoc = gql`
    fragment TrailDetails on trail {
  id
  name
  isClassicAllowed
  isDogsAllowed
  isLighted
  isSkateAllowed
  isSnowmaking
  description
  passDayCost
  passIsRequired
  passPurchaseURL
  passSeasonCost
  photoId
  trailsLength
  websiteURL
  facebookURL
  instagramURL
  location {
    id
    city
    state
    lat
    lng
  }
  userFollowers_aggregate(where: {userId: {_eq: $userId}}, limit: 1) {
    aggregate {
      count
    }
  }
  trailReports(order_by: {createdAt: desc}) {
    ...TrailReport
  }
  admins {
    user {
      id
      displayName
      createdAt
      photo {
        downloadURL
      }
      membershipType
    }
  }
}
    ${TrailReportFragmentDoc}`;
export const TrailFragmentDoc = gql`
    fragment Trail on trail {
  id
  name
  trailsLength
  location {
    id
    city
    state
    lat
    lng
  }
  userFollowers_aggregate(where: {userId: {_eq: $userId}}, limit: 1) {
    aggregate {
      count
    }
  }
  trailReports(limit: 1, order_by: {createdAt: desc}) {
    ...TrailReport
  }
}
    ${TrailReportFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on user {
  id
  displayName
  photo {
    id
    downloadURL
    downloadURLThumbnail
    fileName
    bucketName
  }
  description
  membershipType
  trailReports_aggregate {
    aggregate {
      count
    }
  }
  ownedTrails {
    id
  }
  followingTrails {
    trailId
  }
}
    `;
export const AdminPageDetailsDocument = gql`
    query AdminPageDetails($userId: String!) {
  trails(where: {admins: {userId: {_eq: $userId}}}) {
    id
    name
  }
}
    `;

/**
 * __useAdminPageDetailsQuery__
 *
 * To run a query within a React component, call `useAdminPageDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPageDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPageDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminPageDetailsQuery(baseOptions: Apollo.QueryHookOptions<AdminPageDetailsQuery, AdminPageDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminPageDetailsQuery, AdminPageDetailsQueryVariables>(AdminPageDetailsDocument, options);
      }
export function useAdminPageDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminPageDetailsQuery, AdminPageDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminPageDetailsQuery, AdminPageDetailsQueryVariables>(AdminPageDetailsDocument, options);
        }
export function useAdminPageDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AdminPageDetailsQuery, AdminPageDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminPageDetailsQuery, AdminPageDetailsQueryVariables>(AdminPageDetailsDocument, options);
        }
export type AdminPageDetailsQueryHookResult = ReturnType<typeof useAdminPageDetailsQuery>;
export type AdminPageDetailsLazyQueryHookResult = ReturnType<typeof useAdminPageDetailsLazyQuery>;
export type AdminPageDetailsSuspenseQueryHookResult = ReturnType<typeof useAdminPageDetailsSuspenseQuery>;
export type AdminPageDetailsQueryResult = Apollo.QueryResult<AdminPageDetailsQuery, AdminPageDetailsQueryVariables>;
export const CheckIfAccountExistsDocument = gql`
    query CheckIfAccountExists($id: String!) {
  user(id: $id) {
    id
  }
}
    `;

/**
 * __useCheckIfAccountExistsQuery__
 *
 * To run a query within a React component, call `useCheckIfAccountExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIfAccountExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIfAccountExistsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckIfAccountExistsQuery(baseOptions: Apollo.QueryHookOptions<CheckIfAccountExistsQuery, CheckIfAccountExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckIfAccountExistsQuery, CheckIfAccountExistsQueryVariables>(CheckIfAccountExistsDocument, options);
      }
export function useCheckIfAccountExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckIfAccountExistsQuery, CheckIfAccountExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckIfAccountExistsQuery, CheckIfAccountExistsQueryVariables>(CheckIfAccountExistsDocument, options);
        }
export function useCheckIfAccountExistsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CheckIfAccountExistsQuery, CheckIfAccountExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckIfAccountExistsQuery, CheckIfAccountExistsQueryVariables>(CheckIfAccountExistsDocument, options);
        }
export type CheckIfAccountExistsQueryHookResult = ReturnType<typeof useCheckIfAccountExistsQuery>;
export type CheckIfAccountExistsLazyQueryHookResult = ReturnType<typeof useCheckIfAccountExistsLazyQuery>;
export type CheckIfAccountExistsSuspenseQueryHookResult = ReturnType<typeof useCheckIfAccountExistsSuspenseQuery>;
export type CheckIfAccountExistsQueryResult = Apollo.QueryResult<CheckIfAccountExistsQuery, CheckIfAccountExistsQueryVariables>;
export const TrailFeedDocument = gql`
    query TrailFeed($userId: String!) {
  user(id: $userId) {
    followingTrails {
      trail {
        id
        name
        trailReports(limit: 10, order_by: {createdAt: asc}) {
          ...TrailReport
        }
      }
    }
  }
}
    ${TrailReportFragmentDoc}`;

/**
 * __useTrailFeedQuery__
 *
 * To run a query within a React component, call `useTrailFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrailFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrailFeedQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useTrailFeedQuery(baseOptions: Apollo.QueryHookOptions<TrailFeedQuery, TrailFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrailFeedQuery, TrailFeedQueryVariables>(TrailFeedDocument, options);
      }
export function useTrailFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrailFeedQuery, TrailFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrailFeedQuery, TrailFeedQueryVariables>(TrailFeedDocument, options);
        }
export function useTrailFeedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TrailFeedQuery, TrailFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TrailFeedQuery, TrailFeedQueryVariables>(TrailFeedDocument, options);
        }
export type TrailFeedQueryHookResult = ReturnType<typeof useTrailFeedQuery>;
export type TrailFeedLazyQueryHookResult = ReturnType<typeof useTrailFeedLazyQuery>;
export type TrailFeedSuspenseQueryHookResult = ReturnType<typeof useTrailFeedSuspenseQuery>;
export type TrailFeedQueryResult = Apollo.QueryResult<TrailFeedQuery, TrailFeedQueryVariables>;
export const ProfileEditableDocument = gql`
    query ProfileEditable($id: String!) {
  user(id: $id) {
    ...ProfileEditableFragment
  }
}
    ${ProfileEditableFragmentFragmentDoc}`;

/**
 * __useProfileEditableQuery__
 *
 * To run a query within a React component, call `useProfileEditableQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileEditableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileEditableQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfileEditableQuery(baseOptions: Apollo.QueryHookOptions<ProfileEditableQuery, ProfileEditableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileEditableQuery, ProfileEditableQueryVariables>(ProfileEditableDocument, options);
      }
export function useProfileEditableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileEditableQuery, ProfileEditableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileEditableQuery, ProfileEditableQueryVariables>(ProfileEditableDocument, options);
        }
export function useProfileEditableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProfileEditableQuery, ProfileEditableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProfileEditableQuery, ProfileEditableQueryVariables>(ProfileEditableDocument, options);
        }
export type ProfileEditableQueryHookResult = ReturnType<typeof useProfileEditableQuery>;
export type ProfileEditableLazyQueryHookResult = ReturnType<typeof useProfileEditableLazyQuery>;
export type ProfileEditableSuspenseQueryHookResult = ReturnType<typeof useProfileEditableSuspenseQuery>;
export type ProfileEditableQueryResult = Apollo.QueryResult<ProfileEditableQuery, ProfileEditableQueryVariables>;
export const ProfileHeaderDetailsDocument = gql`
    query ProfileHeaderDetails($id: String!) {
  user(id: $id) {
    id
    displayName
    photo {
      id
      downloadURL
      downloadURLThumbnail
    }
    membershipType
  }
}
    `;

/**
 * __useProfileHeaderDetailsQuery__
 *
 * To run a query within a React component, call `useProfileHeaderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileHeaderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileHeaderDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfileHeaderDetailsQuery(baseOptions: Apollo.QueryHookOptions<ProfileHeaderDetailsQuery, ProfileHeaderDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileHeaderDetailsQuery, ProfileHeaderDetailsQueryVariables>(ProfileHeaderDetailsDocument, options);
      }
export function useProfileHeaderDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileHeaderDetailsQuery, ProfileHeaderDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileHeaderDetailsQuery, ProfileHeaderDetailsQueryVariables>(ProfileHeaderDetailsDocument, options);
        }
export function useProfileHeaderDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProfileHeaderDetailsQuery, ProfileHeaderDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProfileHeaderDetailsQuery, ProfileHeaderDetailsQueryVariables>(ProfileHeaderDetailsDocument, options);
        }
export type ProfileHeaderDetailsQueryHookResult = ReturnType<typeof useProfileHeaderDetailsQuery>;
export type ProfileHeaderDetailsLazyQueryHookResult = ReturnType<typeof useProfileHeaderDetailsLazyQuery>;
export type ProfileHeaderDetailsSuspenseQueryHookResult = ReturnType<typeof useProfileHeaderDetailsSuspenseQuery>;
export type ProfileHeaderDetailsQueryResult = Apollo.QueryResult<ProfileHeaderDetailsQuery, ProfileHeaderDetailsQueryVariables>;
export const PublicProfilePageDetailsDocument = gql`
    query PublicProfilePageDetails($id: String!) {
  user(id: $id) {
    ...PublicProfile
  }
}
    ${PublicProfileFragmentDoc}`;

/**
 * __usePublicProfilePageDetailsQuery__
 *
 * To run a query within a React component, call `usePublicProfilePageDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicProfilePageDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicProfilePageDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublicProfilePageDetailsQuery(baseOptions: Apollo.QueryHookOptions<PublicProfilePageDetailsQuery, PublicProfilePageDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicProfilePageDetailsQuery, PublicProfilePageDetailsQueryVariables>(PublicProfilePageDetailsDocument, options);
      }
export function usePublicProfilePageDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicProfilePageDetailsQuery, PublicProfilePageDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicProfilePageDetailsQuery, PublicProfilePageDetailsQueryVariables>(PublicProfilePageDetailsDocument, options);
        }
export function usePublicProfilePageDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PublicProfilePageDetailsQuery, PublicProfilePageDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PublicProfilePageDetailsQuery, PublicProfilePageDetailsQueryVariables>(PublicProfilePageDetailsDocument, options);
        }
export type PublicProfilePageDetailsQueryHookResult = ReturnType<typeof usePublicProfilePageDetailsQuery>;
export type PublicProfilePageDetailsLazyQueryHookResult = ReturnType<typeof usePublicProfilePageDetailsLazyQuery>;
export type PublicProfilePageDetailsSuspenseQueryHookResult = ReturnType<typeof usePublicProfilePageDetailsSuspenseQuery>;
export type PublicProfilePageDetailsQueryResult = Apollo.QueryResult<PublicProfilePageDetailsQuery, PublicProfilePageDetailsQueryVariables>;
export const TrailDetailsDocument = gql`
    query TrailDetails($id: uuid!, $userId: String!) {
  trail(id: $id) {
    ...TrailDetails
  }
}
    ${TrailDetailsFragmentDoc}`;

/**
 * __useTrailDetailsQuery__
 *
 * To run a query within a React component, call `useTrailDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrailDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrailDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useTrailDetailsQuery(baseOptions: Apollo.QueryHookOptions<TrailDetailsQuery, TrailDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrailDetailsQuery, TrailDetailsQueryVariables>(TrailDetailsDocument, options);
      }
export function useTrailDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrailDetailsQuery, TrailDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrailDetailsQuery, TrailDetailsQueryVariables>(TrailDetailsDocument, options);
        }
export function useTrailDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TrailDetailsQuery, TrailDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TrailDetailsQuery, TrailDetailsQueryVariables>(TrailDetailsDocument, options);
        }
export type TrailDetailsQueryHookResult = ReturnType<typeof useTrailDetailsQuery>;
export type TrailDetailsLazyQueryHookResult = ReturnType<typeof useTrailDetailsLazyQuery>;
export type TrailDetailsSuspenseQueryHookResult = ReturnType<typeof useTrailDetailsSuspenseQuery>;
export type TrailDetailsQueryResult = Apollo.QueryResult<TrailDetailsQuery, TrailDetailsQueryVariables>;
export const TrailForEditingDocument = gql`
    query TrailForEditing($id: uuid!) {
  trail(id: $id) {
    businessEmail
    description
    facebookURL
    id
    instagramURL
    isClassicAllowed
    isDogsAllowed
    isLighted
    isSkateAllowed
    isSnowmaking
    location {
      lat
      lng
    }
    name
    passDayCost
    passIsRequired
    passPurchaseURL
    passSeasonCost
    trailsLength
    websiteURL
  }
}
    `;

/**
 * __useTrailForEditingQuery__
 *
 * To run a query within a React component, call `useTrailForEditingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrailForEditingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrailForEditingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrailForEditingQuery(baseOptions: Apollo.QueryHookOptions<TrailForEditingQuery, TrailForEditingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrailForEditingQuery, TrailForEditingQueryVariables>(TrailForEditingDocument, options);
      }
export function useTrailForEditingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrailForEditingQuery, TrailForEditingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrailForEditingQuery, TrailForEditingQueryVariables>(TrailForEditingDocument, options);
        }
export function useTrailForEditingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TrailForEditingQuery, TrailForEditingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TrailForEditingQuery, TrailForEditingQueryVariables>(TrailForEditingDocument, options);
        }
export type TrailForEditingQueryHookResult = ReturnType<typeof useTrailForEditingQuery>;
export type TrailForEditingLazyQueryHookResult = ReturnType<typeof useTrailForEditingLazyQuery>;
export type TrailForEditingSuspenseQueryHookResult = ReturnType<typeof useTrailForEditingSuspenseQuery>;
export type TrailForEditingQueryResult = Apollo.QueryResult<TrailForEditingQuery, TrailForEditingQueryVariables>;
export const TrailReportEditableDocument = gql`
    query TrailReportEditable($trailId: uuid!) {
  trail(id: $trailId) {
    id
    name
    admins {
      user {
        id
      }
    }
  }
}
    `;

/**
 * __useTrailReportEditableQuery__
 *
 * To run a query within a React component, call `useTrailReportEditableQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrailReportEditableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrailReportEditableQuery({
 *   variables: {
 *      trailId: // value for 'trailId'
 *   },
 * });
 */
export function useTrailReportEditableQuery(baseOptions: Apollo.QueryHookOptions<TrailReportEditableQuery, TrailReportEditableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrailReportEditableQuery, TrailReportEditableQueryVariables>(TrailReportEditableDocument, options);
      }
export function useTrailReportEditableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrailReportEditableQuery, TrailReportEditableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrailReportEditableQuery, TrailReportEditableQueryVariables>(TrailReportEditableDocument, options);
        }
export function useTrailReportEditableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TrailReportEditableQuery, TrailReportEditableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TrailReportEditableQuery, TrailReportEditableQueryVariables>(TrailReportEditableDocument, options);
        }
export type TrailReportEditableQueryHookResult = ReturnType<typeof useTrailReportEditableQuery>;
export type TrailReportEditableLazyQueryHookResult = ReturnType<typeof useTrailReportEditableLazyQuery>;
export type TrailReportEditableSuspenseQueryHookResult = ReturnType<typeof useTrailReportEditableSuspenseQuery>;
export type TrailReportEditableQueryResult = Apollo.QueryResult<TrailReportEditableQuery, TrailReportEditableQueryVariables>;
export const TrailStatusDocument = gql`
    query TrailStatus($id: uuid!) {
  trail(id: $id) {
    id
    name
    trailReports(order_by: {createdAt: desc}, limit: 1) {
      id
      createdAt
      comment
      rating
    }
  }
}
    `;

/**
 * __useTrailStatusQuery__
 *
 * To run a query within a React component, call `useTrailStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrailStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrailStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrailStatusQuery(baseOptions: Apollo.QueryHookOptions<TrailStatusQuery, TrailStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrailStatusQuery, TrailStatusQueryVariables>(TrailStatusDocument, options);
      }
export function useTrailStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrailStatusQuery, TrailStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrailStatusQuery, TrailStatusQueryVariables>(TrailStatusDocument, options);
        }
export function useTrailStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TrailStatusQuery, TrailStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TrailStatusQuery, TrailStatusQueryVariables>(TrailStatusDocument, options);
        }
export type TrailStatusQueryHookResult = ReturnType<typeof useTrailStatusQuery>;
export type TrailStatusLazyQueryHookResult = ReturnType<typeof useTrailStatusLazyQuery>;
export type TrailStatusSuspenseQueryHookResult = ReturnType<typeof useTrailStatusSuspenseQuery>;
export type TrailStatusQueryResult = Apollo.QueryResult<TrailStatusQuery, TrailStatusQueryVariables>;
export const TrailsDocument = gql`
    query Trails($userId: String!) {
  trails(where: {status: {_eq: "APPROVED"}}, order_by: {name: asc}) {
    ...Trail
  }
}
    ${TrailFragmentDoc}`;

/**
 * __useTrailsQuery__
 *
 * To run a query within a React component, call `useTrailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useTrailsQuery(baseOptions: Apollo.QueryHookOptions<TrailsQuery, TrailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrailsQuery, TrailsQueryVariables>(TrailsDocument, options);
      }
export function useTrailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrailsQuery, TrailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrailsQuery, TrailsQueryVariables>(TrailsDocument, options);
        }
export function useTrailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TrailsQuery, TrailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TrailsQuery, TrailsQueryVariables>(TrailsDocument, options);
        }
export type TrailsQueryHookResult = ReturnType<typeof useTrailsQuery>;
export type TrailsLazyQueryHookResult = ReturnType<typeof useTrailsLazyQuery>;
export type TrailsSuspenseQueryHookResult = ReturnType<typeof useTrailsSuspenseQuery>;
export type TrailsQueryResult = Apollo.QueryResult<TrailsQuery, TrailsQueryVariables>;
export const UserFollowingTrailDocument = gql`
    query UserFollowingTrail($userId: String!, $trailId: uuid!) {
  userTrailFollow(trailId: $trailId, userId: $userId) {
    userId
  }
}
    `;

/**
 * __useUserFollowingTrailQuery__
 *
 * To run a query within a React component, call `useUserFollowingTrailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFollowingTrailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFollowingTrailQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      trailId: // value for 'trailId'
 *   },
 * });
 */
export function useUserFollowingTrailQuery(baseOptions: Apollo.QueryHookOptions<UserFollowingTrailQuery, UserFollowingTrailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserFollowingTrailQuery, UserFollowingTrailQueryVariables>(UserFollowingTrailDocument, options);
      }
export function useUserFollowingTrailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserFollowingTrailQuery, UserFollowingTrailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserFollowingTrailQuery, UserFollowingTrailQueryVariables>(UserFollowingTrailDocument, options);
        }
export function useUserFollowingTrailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserFollowingTrailQuery, UserFollowingTrailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserFollowingTrailQuery, UserFollowingTrailQueryVariables>(UserFollowingTrailDocument, options);
        }
export type UserFollowingTrailQueryHookResult = ReturnType<typeof useUserFollowingTrailQuery>;
export type UserFollowingTrailLazyQueryHookResult = ReturnType<typeof useUserFollowingTrailLazyQuery>;
export type UserFollowingTrailSuspenseQueryHookResult = ReturnType<typeof useUserFollowingTrailSuspenseQuery>;
export type UserFollowingTrailQueryResult = Apollo.QueryResult<UserFollowingTrailQuery, UserFollowingTrailQueryVariables>;
export const UserDocument = gql`
    query User($userId: String!) {
  user(id: $userId) {
    ...User
  }
  searchEvents(
    where: {userId: {_eq: $userId}}
    order_by: {createdAt: desc}
    limit: 3
  ) {
    trail {
      ...Trail
    }
  }
}
    ${UserFragmentDoc}
${TrailFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export function useUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UploadRegisteredPushTokenDocument = gql`
    mutation UploadRegisteredPushToken($object: push_token_insert_input!) {
  insertPushToken(object: $object) {
    id
  }
}
    `;
export type UploadRegisteredPushTokenMutationFn = Apollo.MutationFunction<UploadRegisteredPushTokenMutation, UploadRegisteredPushTokenMutationVariables>;

/**
 * __useUploadRegisteredPushTokenMutation__
 *
 * To run a mutation, you first call `useUploadRegisteredPushTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadRegisteredPushTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadRegisteredPushTokenMutation, { data, loading, error }] = useUploadRegisteredPushTokenMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUploadRegisteredPushTokenMutation(baseOptions?: Apollo.MutationHookOptions<UploadRegisteredPushTokenMutation, UploadRegisteredPushTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadRegisteredPushTokenMutation, UploadRegisteredPushTokenMutationVariables>(UploadRegisteredPushTokenDocument, options);
      }
export type UploadRegisteredPushTokenMutationHookResult = ReturnType<typeof useUploadRegisteredPushTokenMutation>;
export type UploadRegisteredPushTokenMutationResult = Apollo.MutationResult<UploadRegisteredPushTokenMutation>;
export type UploadRegisteredPushTokenMutationOptions = Apollo.BaseMutationOptions<UploadRegisteredPushTokenMutation, UploadRegisteredPushTokenMutationVariables>;
export const CreateAccountDocument = gql`
    mutation CreateAccount($id: String!, $displayName: String!, $photoId: uuid!, $email: String, $disabled: Boolean) {
  insertUser(
    object: {id: $id, displayName: $displayName, photoId: $photoId, email: $email, disabled: $disabled}
  ) {
    id
    displayName
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      displayName: // value for 'displayName'
 *      photoId: // value for 'photoId'
 *      email: // value for 'email'
 *      disabled: // value for 'disabled'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const UploadTrailReportDocument = gql`
    mutation UploadTrailReport($object: trail_report_insert_input!) {
  insertTrailReport(object: $object) {
    ...TrailReport
  }
}
    ${TrailReportFragmentDoc}`;
export type UploadTrailReportMutationFn = Apollo.MutationFunction<UploadTrailReportMutation, UploadTrailReportMutationVariables>;

/**
 * __useUploadTrailReportMutation__
 *
 * To run a mutation, you first call `useUploadTrailReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadTrailReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadTrailReportMutation, { data, loading, error }] = useUploadTrailReportMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUploadTrailReportMutation(baseOptions?: Apollo.MutationHookOptions<UploadTrailReportMutation, UploadTrailReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadTrailReportMutation, UploadTrailReportMutationVariables>(UploadTrailReportDocument, options);
      }
export type UploadTrailReportMutationHookResult = ReturnType<typeof useUploadTrailReportMutation>;
export type UploadTrailReportMutationResult = Apollo.MutationResult<UploadTrailReportMutation>;
export type UploadTrailReportMutationOptions = Apollo.BaseMutationOptions<UploadTrailReportMutation, UploadTrailReportMutationVariables>;
export const CreateTrailDocument = gql`
    mutation CreateTrail($name: String!, $ownerId: String, $location: location_obj_rel_insert_input!, $businessEmail: String) {
  insertTrail(
    object: {businessEmail: $businessEmail, location: $location, name: $name, ownerId: $ownerId}
  ) {
    id
  }
}
    `;
export type CreateTrailMutationFn = Apollo.MutationFunction<CreateTrailMutation, CreateTrailMutationVariables>;

/**
 * __useCreateTrailMutation__
 *
 * To run a mutation, you first call `useCreateTrailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrailMutation, { data, loading, error }] = useCreateTrailMutation({
 *   variables: {
 *      name: // value for 'name'
 *      ownerId: // value for 'ownerId'
 *      location: // value for 'location'
 *      businessEmail: // value for 'businessEmail'
 *   },
 * });
 */
export function useCreateTrailMutation(baseOptions?: Apollo.MutationHookOptions<CreateTrailMutation, CreateTrailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTrailMutation, CreateTrailMutationVariables>(CreateTrailDocument, options);
      }
export type CreateTrailMutationHookResult = ReturnType<typeof useCreateTrailMutation>;
export type CreateTrailMutationResult = Apollo.MutationResult<CreateTrailMutation>;
export type CreateTrailMutationOptions = Apollo.BaseMutationOptions<CreateTrailMutation, CreateTrailMutationVariables>;
export const EditTrailDocument = gql`
    mutation EditTrail($id: uuid!, $object: trail_set_input) {
  updateTrail(pk_columns: {id: $id}, _set: $object) {
    id
    name
    isClassicAllowed
    isDogsAllowed
    isLighted
    isSkateAllowed
    isSnowmaking
    description
    passDayCost
    passIsRequired
    passPurchaseURL
    passSeasonCost
    photoId
    trailsLength
    websiteURL
    facebookURL
    instagramURL
    owner {
      id
      displayName
      membershipType
    }
  }
}
    `;
export type EditTrailMutationFn = Apollo.MutationFunction<EditTrailMutation, EditTrailMutationVariables>;

/**
 * __useEditTrailMutation__
 *
 * To run a mutation, you first call `useEditTrailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTrailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTrailMutation, { data, loading, error }] = useEditTrailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useEditTrailMutation(baseOptions?: Apollo.MutationHookOptions<EditTrailMutation, EditTrailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTrailMutation, EditTrailMutationVariables>(EditTrailDocument, options);
      }
export type EditTrailMutationHookResult = ReturnType<typeof useEditTrailMutation>;
export type EditTrailMutationResult = Apollo.MutationResult<EditTrailMutation>;
export type EditTrailMutationOptions = Apollo.BaseMutationOptions<EditTrailMutation, EditTrailMutationVariables>;
export const CreateFeedbackDocument = gql`
    mutation CreateFeedback($type: String!, $message: String!) {
  insertFeedback(object: {type: $type, message: $message}) {
    id
  }
}
    `;
export type CreateFeedbackMutationFn = Apollo.MutationFunction<CreateFeedbackMutation, CreateFeedbackMutationVariables>;

/**
 * __useCreateFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMutation, { data, loading, error }] = useCreateFeedbackMutation({
 *   variables: {
 *      type: // value for 'type'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCreateFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedbackMutation, CreateFeedbackMutationVariables>(CreateFeedbackDocument, options);
      }
export type CreateFeedbackMutationHookResult = ReturnType<typeof useCreateFeedbackMutation>;
export type CreateFeedbackMutationResult = Apollo.MutationResult<CreateFeedbackMutation>;
export type CreateFeedbackMutationOptions = Apollo.BaseMutationOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>;
export const EditProfileDocument = gql`
    mutation EditProfile($id: String!, $photo: photo_obj_rel_insert_input, $displayName: String!, $description: String!) {
  insertUser(
    object: {id: $id, photo: $photo, displayName: $displayName, description: $description}
    on_conflict: {constraint: user_pkey, update_columns: [displayName, description, photoId]}
  ) {
    ...ProfileEditableFragment
  }
}
    ${ProfileEditableFragmentFragmentDoc}`;
export type EditProfileMutationFn = Apollo.MutationFunction<EditProfileMutation, EditProfileMutationVariables>;

/**
 * __useEditProfileMutation__
 *
 * To run a mutation, you first call `useEditProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProfileMutation, { data, loading, error }] = useEditProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      photo: // value for 'photo'
 *      displayName: // value for 'displayName'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useEditProfileMutation(baseOptions?: Apollo.MutationHookOptions<EditProfileMutation, EditProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditProfileMutation, EditProfileMutationVariables>(EditProfileDocument, options);
      }
export type EditProfileMutationHookResult = ReturnType<typeof useEditProfileMutation>;
export type EditProfileMutationResult = Apollo.MutationResult<EditProfileMutation>;
export type EditProfileMutationOptions = Apollo.BaseMutationOptions<EditProfileMutation, EditProfileMutationVariables>;
export const FollowTrailDocument = gql`
    mutation FollowTrail($userId: String!, $trailId: uuid!) {
  insertUserTrailFollow(object: {trailId: $trailId, userId: $userId}) {
    userId
  }
}
    `;
export type FollowTrailMutationFn = Apollo.MutationFunction<FollowTrailMutation, FollowTrailMutationVariables>;

/**
 * __useFollowTrailMutation__
 *
 * To run a mutation, you first call `useFollowTrailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowTrailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followTrailMutation, { data, loading, error }] = useFollowTrailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      trailId: // value for 'trailId'
 *   },
 * });
 */
export function useFollowTrailMutation(baseOptions?: Apollo.MutationHookOptions<FollowTrailMutation, FollowTrailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FollowTrailMutation, FollowTrailMutationVariables>(FollowTrailDocument, options);
      }
export type FollowTrailMutationHookResult = ReturnType<typeof useFollowTrailMutation>;
export type FollowTrailMutationResult = Apollo.MutationResult<FollowTrailMutation>;
export type FollowTrailMutationOptions = Apollo.BaseMutationOptions<FollowTrailMutation, FollowTrailMutationVariables>;
export const UnfollowTrailDocument = gql`
    mutation UnfollowTrail($userId: String!, $trailId: uuid!) {
  deleteUserTrailFollow(trailId: $trailId, userId: $userId) {
    userId
  }
}
    `;
export type UnfollowTrailMutationFn = Apollo.MutationFunction<UnfollowTrailMutation, UnfollowTrailMutationVariables>;

/**
 * __useUnfollowTrailMutation__
 *
 * To run a mutation, you first call `useUnfollowTrailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowTrailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowTrailMutation, { data, loading, error }] = useUnfollowTrailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      trailId: // value for 'trailId'
 *   },
 * });
 */
export function useUnfollowTrailMutation(baseOptions?: Apollo.MutationHookOptions<UnfollowTrailMutation, UnfollowTrailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnfollowTrailMutation, UnfollowTrailMutationVariables>(UnfollowTrailDocument, options);
      }
export type UnfollowTrailMutationHookResult = ReturnType<typeof useUnfollowTrailMutation>;
export type UnfollowTrailMutationResult = Apollo.MutationResult<UnfollowTrailMutation>;
export type UnfollowTrailMutationOptions = Apollo.BaseMutationOptions<UnfollowTrailMutation, UnfollowTrailMutationVariables>;
export const DeleteTrailReportDocument = gql`
    mutation DeleteTrailReport($id: uuid!) {
  deleteTrailReport(id: $id) {
    id
  }
}
    `;
export type DeleteTrailReportMutationFn = Apollo.MutationFunction<DeleteTrailReportMutation, DeleteTrailReportMutationVariables>;

/**
 * __useDeleteTrailReportMutation__
 *
 * To run a mutation, you first call `useDeleteTrailReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrailReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrailReportMutation, { data, loading, error }] = useDeleteTrailReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTrailReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTrailReportMutation, DeleteTrailReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTrailReportMutation, DeleteTrailReportMutationVariables>(DeleteTrailReportDocument, options);
      }
export type DeleteTrailReportMutationHookResult = ReturnType<typeof useDeleteTrailReportMutation>;
export type DeleteTrailReportMutationResult = Apollo.MutationResult<DeleteTrailReportMutation>;
export type DeleteTrailReportMutationOptions = Apollo.BaseMutationOptions<DeleteTrailReportMutation, DeleteTrailReportMutationVariables>;
export const AddTrailSearchEventDocument = gql`
    mutation AddTrailSearchEvent($trailId: uuid!) {
  insertSearchEvent(
    object: {trailId: $trailId}
    on_conflict: {constraint: search_event_pkey, update_columns: updatedAt}
  ) {
    id
    trail {
      name
    }
    updatedAt
  }
}
    `;
export type AddTrailSearchEventMutationFn = Apollo.MutationFunction<AddTrailSearchEventMutation, AddTrailSearchEventMutationVariables>;

/**
 * __useAddTrailSearchEventMutation__
 *
 * To run a mutation, you first call `useAddTrailSearchEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrailSearchEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrailSearchEventMutation, { data, loading, error }] = useAddTrailSearchEventMutation({
 *   variables: {
 *      trailId: // value for 'trailId'
 *   },
 * });
 */
export function useAddTrailSearchEventMutation(baseOptions?: Apollo.MutationHookOptions<AddTrailSearchEventMutation, AddTrailSearchEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTrailSearchEventMutation, AddTrailSearchEventMutationVariables>(AddTrailSearchEventDocument, options);
      }
export type AddTrailSearchEventMutationHookResult = ReturnType<typeof useAddTrailSearchEventMutation>;
export type AddTrailSearchEventMutationResult = Apollo.MutationResult<AddTrailSearchEventMutation>;
export type AddTrailSearchEventMutationOptions = Apollo.BaseMutationOptions<AddTrailSearchEventMutation, AddTrailSearchEventMutationVariables>;