import styled from '@emotion/styled';
import { IonLabel } from '@ionic/react';

import React from 'react';
import UserAvatar from '../../../components/UserAvatar';
import UserBanner from '../../../components/UserBanner';
import {
	PublicProfileFragment,
	UserFragment,
} from '../../../generated/graphql';

interface Props {
	user: UserFragment | PublicProfileFragment;
}

const ProfileHeader: React.FC<Props> = ({ user }) => {
	return (
		<Wrapper>
			<UserAvatar size="lg" photoURL={user.photo?.downloadURL} />
			<DisplayName>{user.displayName}</DisplayName>
			<UserBanner membershipType={user.membershipType} />
		</Wrapper>
	);
};

export default ProfileHeader;

const Wrapper = styled.div`
	width: 100%;
	background: var(--ion-color-secondary);
	padding-top: 1rem;
	padding-bottom: 1rem;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-evenly;
	* {
		margin-top: 2px;
	}
`;

const DisplayName = styled(IonLabel)`
	color: var(--ion-color-dark);
	font-weight: bold;
`;
