import React from 'react';

interface Props {
	children?: React.ReactNode;
	className?: string;
}

export const HFlexbox: React.FC<Props> = ({ children, className }) => {
	let divStyle = {
		display: 'flex',
		flexDirection: 'row',
		padding: 0,
		margin: 0,
	} as React.CSSProperties;

	return (
		<div style={divStyle} className={className}>
			{children}
		</div>
	);
};

export const VFlexbox: React.FC<Props> = ({ children, className }) => {
	let divStyle = {
		display: 'flex',
		flexDirection: 'column',
		padding: 0,
		margin: 0,
	} as React.CSSProperties;

	return (
		<div style={divStyle} className={className}>
			{children}
		</div>
	);
};
