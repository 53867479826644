import styled from '@emotion/styled';
import { ErrorMessage } from '@hookform/error-message';
import { IonButton, IonIcon, IonInput } from '@ionic/react';
import { chevronForward } from 'ionicons/icons';
import * as Joi from 'joi';

import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormItem, FormLabel } from '../../components/Form/FormComponents';
import { getValidationResolver } from '../../utils/ValidationResolver';

export interface CreateAccountFormModel {
	displayName: string;
}

const CreateAccountFormSchema = Joi.object({
	displayName: Joi.string()
		.min(6)
		.max(20)
		.regex(new RegExp('^[a-zA-Z0-9_]*$'))
		.message('Only letters, numbers and "_" are allowed.')
		.required(),
});

interface Props {
	onSubmit: (data: CreateAccountFormModel) => any;
}

const CreateAccount: React.FC<Props> = ({ onSubmit }) => {
	const { handleSubmit, control, errors } = useForm<CreateAccountFormModel>({
		mode: 'onSubmit',
		defaultValues: {
			displayName: undefined,
		},
		resolver: getValidationResolver(CreateAccountFormSchema, (error) => ({
			...error,
			message: `This field ${error.message}`,
		})),
	});

	return (
		<FormWrapper>
			<FormItem>
				<FormLabel>Select A Display Name</FormLabel>
				<Controller
					name="displayName"
					control={control}
					render={({ onChange, value }) => (
						<StyledInput
							onIonChange={onChange}
							value={value}
							inputMode="text"
							autoCapitalize="on"
							mode="md"
						/>
					)}
				/>
				<ErrorMessage
					name="displayName"
					errors={errors}
					render={({ message }) => <Error>{message}</Error>}
				/>
			</FormItem>
			<FormItem>
				<StyledIonButton
					onClick={handleSubmit(onSubmit)}
					size="small"
					fill="clear"
				>
					Save Changes <IonIcon icon={chevronForward} slot="end" />
				</StyledIonButton>
			</FormItem>
		</FormWrapper>
	);
};

export default CreateAccount;

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 2rem;
`;

const Error = styled.span`
	color: var(--ion-color-danger);
`;

const StyledInput = styled(IonInput)`
	margin-top: 5px;
	background: var(--ion-color-light);
	border-radius: 10px;
`;

const StyledIonButton = styled(IonButton)`
	float: right;
`;
