import React, { useCallback, useState } from 'react';
import { TrailFragment } from '../../generated/graphql';
import { FormBottomPadding } from '../Form/FormComponents';
import SearchbarInput from './SearchbarInput';
import TrailSearchList from './TrailSearchList';

interface Props {
	inputRef: React.MutableRefObject<any>;
	onSearchItemClick?: (trail: TrailFragment) => void;
}

const TrailSearch: React.FC<Props> = ({ inputRef, onSearchItemClick }) => {
	const [search, setSearch] = useState('');

	const handleSearchItemClick = useCallback(
		(trail: TrailFragment) => {
			setSearch('');
			if (onSearchItemClick) {
				onSearchItemClick(trail);
			}
		},
		[onSearchItemClick, setSearch]
	);

	return (
		<>
			<SearchbarInput
				inputRef={inputRef}
				isButton={false}
				placeholder="Search trails"
				onChange={(e: React.FormEvent<HTMLInputElement>) =>
					setSearch(e.currentTarget.value)
				}
			/>
			<TrailSearchList
				currentSearch={search}
				onSearchItemClick={handleSearchItemClick}
			/>
			<FormBottomPadding />
		</>
	);
};

export default TrailSearch;
