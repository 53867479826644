import { useMemo } from 'react';

const defaultOptions = {
	maxLength: 200,
	newLineWeight: 100,
};

export const useParagraph = (initialText: string, options = defaultOptions) => {
	const [shortenedText, isShortened] = useMemo(() => {
		let counter = 0;
		let shortenedStringArr: string[] = [];
		initialText.split('').forEach((c) => {
			if (c === '\n') {
				counter += options.newLineWeight;
			} else {
				counter += 1;
			}

			if (counter < options.maxLength) {
				shortenedStringArr.push(c);
			}
		});

		const shortenedString = shortenedStringArr.join('');
		return [shortenedString, shortenedString !== initialText];
	}, [initialText, options]);

	return { text: shortenedText, isShortened };
};
