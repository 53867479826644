import { IonGrid } from '@ionic/react';

import styled from '@emotion/styled';

const IonGridNoPadding = styled(IonGrid)`
	padding: 0;
	margin: 0;
`;

export default IonGridNoPadding;
