import {
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardSubtitle,
	IonContent,
	IonHeader,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react';

import React, { useRef } from 'react';

interface Props {}

const PremiumPage: React.FC<Props> = () => {
	const pageRef = useRef<any>();

	return (
		<IonPage ref={pageRef}>
			<IonHeader>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Premium</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonCard>
					<IonCardSubtitle>View Membership Details</IonCardSubtitle>
					<IonCardContent>View dai</IonCardContent>
				</IonCard>
			</IonContent>
		</IonPage>
	);
};

export default PremiumPage;
