import {
	ApolloClient,
	ApolloLink,
	ApolloProvider,
	HttpLink,
	InMemoryCache,
	NormalizedCacheObject,
	Observable,
	split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { IonicStorageWrapper, persistCache } from 'apollo3-cache-persist';
import React, { useEffect, useState } from 'react';

import { Storage } from '@ionic/storage';
import { AUTH_TOKEN } from './AuthContainer';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;
if (!GRAPHQL_ENDPOINT) {
	throw Error('Error: No environment variable set. [REACT_APP_GRAPHQL_ENDPOINT]');
}

const GRAPHQL_WEBSOCKET_ENDPOINT = process.env.REACT_APP_GRAPHQL_WEBSOCKET_ENDPOINT;
if (!GRAPHQL_WEBSOCKET_ENDPOINT) {
	throw Error('Error: No environment variable set. [REACT_APP_GRAPHQL_WEBSOCKET_ENDPOINT]');
}

const authLink = setContext(async (_, { headers, ...context }) => {
	let token;
	const currentUser = await FirebaseAuthentication.getCurrentUser();
	if (currentUser.user) {
		token = (await FirebaseAuthentication.getIdToken()).token ?? undefined;
	}

	return {
		headers: {
			...headers,
			...(token ? { Authorization: `Bearer ${token}` } : {}),
		},
		...context,
	};
});

const authErrorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
	let accessToken = window.localStorage.getItem(AUTH_TOKEN);

	console.error('asdasdsadasdasd autherror', graphQLErrors, networkError, operation, forward);

	if (graphQLErrors) {
		// User access token has expired
		if (graphQLErrors[0].message.includes('Token has expired')) {
			if (accessToken && accessToken !== 'undefined') {
				console.error('4343434343', accessToken);
				// Let's refresh token through async request
				return new Observable((observer) => {
					FirebaseAuthentication.getIdToken({ forceRefresh: true })
						.then((idTokenResult) => {
							if (!idTokenResult.token) {
								window.localStorage.removeItem(AUTH_TOKEN);
								return console.log('Refresh token has expired');
							}

							window.localStorage.setItem(AUTH_TOKEN, idTokenResult.token);

							// reset the headers
							operation.setContext(({ headers = {} }) => ({
								headers: {
									...headers,
									// Switch out old access token for new one
									...(idTokenResult.token
										? { Authorization: `Bearer ${idTokenResult.token}` }
										: {}),
								},
							}));
							const subscriber = {
								next: observer.next.bind(observer),
								error: observer.error.bind(observer),
								complete: observer.complete.bind(observer),
							};

							// Retry last failed request
							forward(operation).subscribe(subscriber);
						})
						.catch((error) => {
							// No refresh or client token available, we force user to login
							observer.error(error);
						});
				});
			}
		}
	}
});

const httpLink = new HttpLink({
	uri: GRAPHQL_ENDPOINT,
});

const wsLink = new WebSocketLink({
	uri: GRAPHQL_WEBSOCKET_ENDPOINT,
	options: {
		reconnect: true,
	},
});

interface Definintion {
	kind: string;
	operation?: string;
}

const link = split(
	({ query }) => {
		const { kind, operation }: Definintion = getMainDefinition(query);
		return kind === 'OperationDefinition' && operation === 'subscription';
	},
	wsLink,
	httpLink
);

const logErrors = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.map(({ message, locations, path }) =>
			console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
		);
	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const createApolloClient = async () => {
	const cache = new InMemoryCache();
	const ionicStorage = new Storage();

	await ionicStorage.create();

	await persistCache({
		cache,
		storage: new IonicStorageWrapper(ionicStorage),
	});

	console.log(cache);

	const apolloClient = new ApolloClient({
		link: ApolloLink.from([logErrors, authErrorLink, authLink, link]),
		cache,
		connectToDevTools: true,
	});

	return apolloClient;
};

const useApollo = () => {
	const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null);

	useEffect(() => {
		const initApolloClient = async () => {
			const apolloClient = await createApolloClient();
			setClient(apolloClient);
		};

		initApolloClient();
	}, []);

	return client;
};

interface Props {
	children: React.ReactNode;
}

const ApolloContainer: React.FC<Props> = ({ children }) => {
	const apolloClient = useApollo();

	if (!apolloClient) return null;

	return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default ApolloContainer;
