import {} from '@ionic/react';

import { joiResolver } from '@hookform/resolvers';
import React from 'react';
import { useForm } from 'react-hook-form';
import CreateTrailView from './CreateTrailView';
import { CreateTrailModel } from './models/CreateTrailModel';
import { CreateTrailSchema } from './models/CreateTrailSchema';

interface Props {
	onSubmit: (data: CreateTrailModel) => any;
	pageRef: any;
}

const CreateTrailLogic: React.FC<Props> = ({ onSubmit, pageRef }) => {
	const form = useForm<CreateTrailModel>({
		mode: 'onSubmit',
		defaultValues: {
			isOwner: false,
		},
		resolver: joiResolver(CreateTrailSchema, {
			errors: {
				label: 'key',
			},
		}),
	});

	return <CreateTrailView onSubmit={onSubmit} form={form} pageRef={pageRef} />;
};

export default CreateTrailLogic;
