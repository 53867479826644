import styled from '@emotion/styled';
import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonLabel,
	IonRefresher,
	IonRefresherContent,
	IonSegment,
	IonSegmentButton,
	IonTitle,
	IonToolbar,
	SegmentValue,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';

import React, { useState } from 'react';
import { useHistory } from 'react-router';
import ApolloErrorHandler from '../../components/ApolloErrorHandler';
import Background from '../../components/Background';
import { ErrorLabel } from '../../components/ErrorLabel';
import FixedContainer from '../../components/FixedContainer';
import TrailReportFab from '../../components/TrailReportFab';
import { TRAIL_PAGE_DEFAULT_BACKGROUND_IMAGE } from '../../constants/consts';
import { Auth } from '../../containers/AuthContainer';
import { LoginModal } from '../../containers/LoginModalContainer';
import { useTrailDetailsQuery } from '../../generated/graphql';
import CreateTrailReportModal from '../create-trail-report/CreateTrailReportModal';
import TrailInfo from './trailinfo/TrailInfo';
import TrailReports from './trailreports/TrailReports';

const DEFAULT_SEGMENT_VALUE = 'info';

interface Props {
	trailId: string;
}

const TrailDetails: React.FC<Props> = ({ trailId }) => {
	const history = useHistory();
	const { user } = Auth.useContainer();
	const [activeSegment, setActiveSegment] = useState<SegmentValue>(DEFAULT_SEGMENT_VALUE);
	const { data, error, refetch } = useTrailDetailsQuery({
		variables: { id: trailId, userId: user?.id || '__NOT_AUTHENTICATED__' },
		fetchPolicy: 'cache-and-network',
	});
	const [showModal, setShowModal] = useState(false);
	const { showLoginScreenPopup } = LoginModal.useContainer();

	if (error)
		return (
			<ApolloErrorHandler
				error={error}
				render={
					<ErrorLabel>
						<p>
							Whoops! Looks like that trail can't be found.{' '}
							<StyledA href="/">Return to home</StyledA>
						</p>
					</ErrorLabel>
				}
			/>
		);

	const handleRefresh = (event: CustomEvent) => {
		refetch();
		setTimeout(() => {
			event.detail.complete();
		}, 1200);
	};

	return (
		<>
			<IonHeader>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonButton onClick={() => history.replace('/trails')}>
							<IonIcon icon={arrowBack}></IonIcon>
						</IonButton>
					</IonButtons>
					<IonTitle>{data?.trail?.name}</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				<IonRefresher slot="fixed" id="refresher" onIonRefresh={handleRefresh}>
					<IonRefresherContent />
				</IonRefresher>
				<TrailHeaderImage>
					<Background
						img={TRAIL_PAGE_DEFAULT_BACKGROUND_IMAGE}
						overlay="rgba(var(--ion-color-primary-rgb), 0.3)"
						blur={0}
					></Background>
				</TrailHeaderImage>

				<SegmentToolbar>
					<IonSegment
						onIonChange={(e) => setActiveSegment(e.detail.value || DEFAULT_SEGMENT_VALUE)}
						value={activeSegment}
						mode="md"
					>
						<IonSegmentButton value="info">
							<IonLabel>Info</IonLabel>
						</IonSegmentButton>
						<IonSegmentButton value="trailreports">
							<IonLabel>Trail Reports ({data?.trail?.trailReports.length})</IonLabel>
						</IonSegmentButton>
					</IonSegment>
				</SegmentToolbar>
				{data?.trail && (
					<>
						{activeSegment === 'info' ? (
							<TrailInfo trail={data.trail} />
						) : (
							<TrailReports trail={data.trail} />
						)}
					</>
				)}
			</IonContent>

			{data?.trail && (
				<>
					<CreateTrailReportModal
						defaultTrail={{ id: trailId, name: data.trail.name }}
						showModal={showModal}
						setShowModal={setShowModal}
					/>
					<FixedContainer position="bottom">
						<TrailReportFab
							onClick={() =>
								user?.id
									? setShowModal(true)
									: showLoginScreenPopup('You must be logged in to create a trail report.')
							}
						/>
					</FixedContainer>
				</>
			)}
		</>
	);
};

export default TrailDetails;

const SegmentToolbar = styled(IonToolbar)`
	box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.3);
`;

const TrailHeaderImage = styled.div`
	height: 200px;
	width: 100%;
`;

const StyledA = styled.a`
	text-decoration: underline;
`;
