import styled from '@emotion/styled';
import { IonIcon } from '@ionic/react';
import { link, logoFacebook, logoInstagram } from 'ionicons/icons';

import React from 'react';
import IonColNoPadding from '../../../../utils/IonColNoPadding';
import IonGridNoPadding from '../../../../utils/IonGridNoPadding';
import IonRowNoPadding from '../../../../utils/IonRowNoPadding';

interface Props {
	facebookURL?: string | null;
	instagramURL?: string | null;
	websiteURL?: string | null;
}

const SocialLinksModule: React.FC<Props> = ({
	facebookURL,
	instagramURL,
	websiteURL,
}) => {
	return (
		<Wrapper>
			<IonGridNoPadding>
				<IonRowNoPadding>
					<IonColNoPadding size="4">
						<Content>
							<StyledATag
								href={facebookURL || '#'}
								target="_blank"
								disabled={!facebookURL}
							>
								<Circle disabled={!facebookURL}>
									<IonIcon icon={logoFacebook} />
								</Circle>
							</StyledATag>
						</Content>
					</IonColNoPadding>

					<IonColNoPadding size="4">
						<Content>
							<StyledATag
								href={instagramURL || '#'}
								target="_blank"
								disabled={!instagramURL}
							>
								<Circle disabled={!instagramURL}>
									<IonIcon icon={logoInstagram} />
								</Circle>
							</StyledATag>
						</Content>
					</IonColNoPadding>

					<IonColNoPadding size="4">
						<Content>
							<StyledATag
								href={websiteURL || '#'}
								target="_blank"
								disabled={!websiteURL}
							>
								<Circle disabled={!websiteURL}>
									<IonIcon icon={link} />
								</Circle>
							</StyledATag>
						</Content>
					</IonColNoPadding>
				</IonRowNoPadding>
			</IonGridNoPadding>
		</Wrapper>
	);
};

export default SocialLinksModule;

const Wrapper = styled.div`
	padding: 1rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
`;

const Content = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledATag = styled.a<{ disabled: boolean }>`
	pointer-events: ${(props) => props.disabled && 'none'};
`;
const Circle = styled.div<{ disabled: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${(props) =>
		props.disabled ? 'var(--ion-color-light-shade)' : 'var(--ion-color-dark)'};
	color: white;
	padding: 1rem;
	border-radius: 9999px;
	margin: auto;
	flex-grow: 0;
	font-size: 1.5rem;
`;
