import { IonButton, IonIcon, IonItem } from '@ionic/react';
import { chevronDown } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { TrailFragment } from '../../../generated/graphql';
import { useFuse } from '../../../hooks/useFuse';
import { IonNoteSmall } from '../../Form/FormComponents';
import TrailSearchItem from '../TrailSearchItem';

interface Props {
	trails: TrailFragment[];
	noItemsLabel: string;
	currentSearch: string;
	onSearchItemClick: (trail: TrailFragment) => void;
	limit: number;
}

const FuseFilteredTrailsSectionView: React.FC<Props> = ({
	trails,
	noItemsLabel,
	currentSearch,
	onSearchItemClick,
	limit,
}) => {
	const [itemsShown, setItemsShown] = useState(limit);

	const { results, setSearch } = useFuse<TrailFragment>({
		data: trails,
		keys: [
			{
				name: 'name',
				weight: 1,
			},
			{
				name: 'location.city',
				weight: 0.4,
			},
			{
				name: 'location.state',
				weight: 0.1,
			},
		],
	});

	useEffect(() => {
		setSearch(currentSearch);
	}, [setSearch, currentSearch]);

	const trailSearchItems = results.map((result) => ({
		key: result.refIndex.toString(),
		trail: result.item,
	}));

	return (
		<>
			{trailSearchItems.length > 0 ? (
				<>
					{trailSearchItems.slice(0, itemsShown).map((item) => (
						<TrailSearchItem
							key={item.key}
							item={item}
							onSearchItemClick={onSearchItemClick}
						/>
					))}
					{trailSearchItems.length > itemsShown && (
						<IonButton
							size="small"
							fill="clear"
							onClick={() => setItemsShown(itemsShown + 70)}
						>
							Show more
							<IonIcon icon={chevronDown} />
						</IonButton>
					)}
				</>
			) : (
				<IonItem lines="none">
					<IonNoteSmall>{noItemsLabel}</IonNoteSmall>
				</IonItem>
			)}
		</>
	);
};

export default FuseFilteredTrailsSectionView;
