import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonModal,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import GoogleMapReact, { Coords } from 'google-map-react';
import { chevronBack, pin } from 'ionicons/icons';

import styled from '@emotion/styled';
import React, { useRef, useState } from 'react';
import { DEFAULT_MAP_CENTER } from '../constants/consts';
import { UserLocation } from '../containers/UserLocationContainer';
import FixedContainer from './FixedContainer';

const INITIAL_ZOOM_LEVEL = 9;

const createMapOptions = (maps: any) => ({
	zoomControl: true,
	zoomControlOptions: {
		position: maps.ControlPosition.TOP_RIGHT,
	},
	mapTypeControl: false,
	scaleControl: false,
	rotateControl: false,
	fullscreenControl: false,
	minZoom: 5,
	maxZoom: 17,
});

interface Props {
	onChange?: any;
	onBlur?: any;
	showModal: boolean;
	setShowModal: any;
	currentPageRef: any;
}

const SelectLocationModal: React.FC<Props> = ({
	onChange,
	onBlur,
	currentPageRef,
	showModal,
	setShowModal,
}) => {
	const mapRef = useRef<any>();
	const { userLocation } = UserLocation.useContainer();
	const [mapCenter, setMapCenter] = useState<Coords>(
		userLocation || DEFAULT_MAP_CENTER
	);

	const handleChange = (newLocation: Coords) => {
		if (onChange) {
			onChange(newLocation);
		}
		handleBlur();
	};

	const handleBlur = () => {
		if (onBlur) {
			onBlur();
		}
		setShowModal(false);
	};

	return (
		<IonModal
			isOpen={showModal}
			presentingElement={currentPageRef}
			onDidDismiss={() => handleBlur()}
		>
			<IonHeader>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonButton onClick={() => handleBlur()}>
							<IonIcon icon={chevronBack} />
						</IonButton>
					</IonButtons>
					<IonTitle>Select Location</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{showModal && (
					<>
						<MapWrapper>
							<GoogleMapReact
								options={createMapOptions}
								bootstrapURLKeys={{
									key: 'AIzaSyBR4NbMIA4bRa8T-6awR-hajwQNqFyR8r8',
								}}
								center={userLocation || DEFAULT_MAP_CENTER}
								defaultZoom={INITIAL_ZOOM_LEVEL}
								yesIWantToUseGoogleMapApiInternals
								resetBoundsOnResize={true}
								onGoogleApiLoaded={({ map }) => {
									mapRef.current = map;
								}}
								onChange={({ center }) => {
									setMapCenter(center);
								}}
							>
								{/* <>
									{userLocation && (
										<GoogleMapMarker
											key='user-location-marker'
											lat={userLocation.lat}
											lng={userLocation.lng}
										>
											<MyLocationMarker />
										</GoogleMapMarker>
									)}
								</> */}
							</GoogleMapReact>
						</MapWrapper>
						<FixedContainer position="bottom">
							<IonButton
								className="m-3"
								expand="block"
								onClick={() => handleChange(mapCenter)}
							>
								Select
							</IonButton>
						</FixedContainer>
						<CenterMapIcon icon={pin}></CenterMapIcon>
					</>
				)}
			</IonContent>
		</IonModal>
	);
};

export default SelectLocationModal;

const MapWrapper = styled.div`
	width: 100%;
	height: 100%;
`;

const CenterMapIcon = styled(IonIcon)`
	position: absolute;
	font-size: 2rem;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -100%);
`;
