import { Geolocation } from '@capacitor/geolocation';
import { Coords } from 'google-map-react';
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';

const initialUserCoordsFromLocalStorage = localStorage.getItem('user_location');
const initialUserCoords = initialUserCoordsFromLocalStorage
	? (JSON.parse(initialUserCoordsFromLocalStorage) as Coords)
	: undefined;

function useUserLocation() {
	const [userLocation, setUserLocation] = useState<Coords | undefined>(
		initialUserCoords
	);

	useEffect(() => {
		fetchUserLocation();
		const interval = setInterval(
			() => {
				fetchUserLocation();
			},
			1000 * 60 * 5
		); // 5 minutes
		return () => clearInterval(interval);
	}, []);

	function fetchUserLocation() {
		Geolocation.getCurrentPosition({
			enableHighAccuracy: true,
			timeout: 10000,
			maximumAge: 10000,
		})
			.then((position) => {
				const newUserLocation = {
					lat: position?.coords.latitude,
					lng: position?.coords.longitude,
				};
				console.log('newPos', newUserLocation);
				setUserLocation(newUserLocation);
				localStorage.setItem('user_location', JSON.stringify(newUserLocation));
			})
			.catch((e) => console.warn('[UserLocation]', e));
	}

	return {
		userLocation,
	};
}

export const UserLocation = createContainer(useUserLocation);
