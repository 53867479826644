import { IonPage } from '@ionic/react';
import React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import TrailDetails from './TrailDetails';

interface Props extends RouteComponentProps<{ id: string }> {}

const TrailDetailsPage: React.FC<Props> = ({ match }) => {
	const trailId = match.params.id;

	if (!trailId) return <Redirect to={`/404`} />;

	return (
		<IonPage id="settings-page">
			<TrailDetails key={trailId} trailId={trailId} />
		</IonPage>
	);
};

export default withRouter(TrailDetailsPage);
