import React from 'react';
import { TrailFragment, UserFragment, useUserQuery } from '../generated/graphql';
import { useRequiredContext } from '../hooks/useRequiredContext';
import { Auth } from './AuthContainer';
import { ApolloError } from '@apollo/client';

interface UserContextProps {
	data:
		| {
				user: UserFragment;
				recentTrailSearches: TrailFragment[];
		  }
		| undefined;
	loading: boolean;
	error: ApolloError | undefined;
}

export const UserContext = React.createContext<UserContextProps | null>(null);

interface UserProviderProps {
	children: React.ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
	const { user } = Auth.useContainer();

	const { data, loading, error } = useUserQuery({
		variables: { userId: user?.id || '__NOT_AUTHENTICATED__' },
		fetchPolicy: 'cache-and-network',
	});

	const value: UserContextProps = {
		data: data?.user
			? {
					user: data.user,
					recentTrailSearches: data.searchEvents.map((searchEvent) => searchEvent.trail),
				}
			: undefined,
		loading,
		error,
	};

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useRequiredContext('UserContext', UserContext);
