import { FetchResult } from '@apollo/client';
import { ErrorMessage } from '@hookform/error-message';
import { joiResolver } from '@hookform/resolvers';
import {
	IonAlert,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import {
	chevronForward,
	close,
	link,
	logoFacebook,
	logoInstagram,
} from 'ionicons/icons';

import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import AdvancedIonInput from '../../components/Form/AdvancedIonInput';
import {
	FormBottomPadding,
	FormError,
	FormInput,
	FormItem,
	FormLabel,
	FormTextarea,
	FormTopPadding,
} from '../../components/Form/FormComponents';
import FormSection from '../../components/Form/FormSection';
import IonToggleExtended from '../../components/Form/IonToggleExtended';
import { EditTrailMutation, Trail_Set_Input } from '../../generated/graphql';
import { showErrorToast, showSuccessToast } from '../../utils/Toasts';
import { removeUndefinedFields } from '../../utils/UtilityFunctions';
import { EditTrailModel } from './models/EditTrailModel';
import { EditTrailSchema } from './models/EditTrailSchema';

interface Props {
	defaultValues: EditTrailModel;
	editTrail: (
		updatedTrail: Trail_Set_Input
	) => Promise<
		FetchResult<EditTrailMutation, Record<string, any>, Record<string, any>>
	>;
	pageRef: any;
	onClose: () => any;
}

const EditTrail: React.FC<Props> = ({
	defaultValues,
	editTrail,
	pageRef,
	onClose,
}) => {
	const [showCloseConfirmationAlert, setShowCloseConfirmationAlert] =
		useState(false);
	const form = useForm<EditTrailModel>({
		mode: 'onSubmit',
		defaultValues: defaultValues,
		resolver: joiResolver(EditTrailSchema, {
			errors: {
				label: 'key',
			},
			abortEarly: false,
		}),
	});

	const onSubmit = (data: EditTrailModel) => {
		console.log(data);
		const uploadData = {
			name: data.name,
			...removeUndefinedFields({
				description: data.description,
				isClassicAllowed: data.isClassicAllowed,
				isSkateAllowed: data.isSkateAllowed,
				trailsLength: data.trailsLength,
				isLighted: data.isLighted,
				isSnowmaking: data.isSnowmaking,
				isDogsAllowed: data.isDogsAllowed,
				passIsRequired: data.passIsRequired,
				passDayCost: data.passDayCost,
				passSeasonCost: data.passSeasonCost,
				passPurchaseURL: data.passPurchaseURL,
				// adminIDs?: string[];
				websiteURL: data.websiteURL,
				facebookURL: data.facebookURL,
				instagramURL: data.instagramURL,
				// businessEmail?: string;
			}),
		};

		editTrail(uploadData)
			.then(() => {
				form.reset(data);
				showSuccessToast('Changes saved');
				onClose();
			})
			.catch((e) => {
				console.error(e);
				showErrorToast('Error updating trail. Please try again later.');
			});
	};

	return (
		<>
			<IonAlert
				isOpen={showCloseConfirmationAlert}
				onDidDismiss={() => setShowCloseConfirmationAlert(false)}
				header={'Are you sure you want to leave?'}
				buttons={[
					{
						text: 'Back',
						role: 'cancel',
					},
					{
						text: 'Confirm',
						handler: () => onClose(),
					},
				]}
			/>
			<IonHeader>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonButton onClick={() => setShowCloseConfirmationAlert(true)}>
							<IonIcon icon={close} />
						</IonButton>
					</IonButtons>
					<IonTitle>Edit Trail - {form.watch('name')}</IonTitle>
					<IonButtons slot="end">
						<IonButton onClick={form.handleSubmit(onSubmit)}>
							Save
							<IonIcon icon={chevronForward} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<FormProvider {...form}>
					<FormTopPadding />
					<FormItem>
						<FormLabel>Trail Name</FormLabel>
						<Controller
							name="name"
							render={({ onChange, value }) => (
								<FormInput
									onIonChange={(e: any) => onChange(e.detail.value)}
									value={value}
								/>
							)}
						/>
						<ErrorMessage
							name="name"
							render={({ message }) => <FormError>{message}</FormError>}
						/>
					</FormItem>

					<FormItem>
						<FormLabel>Location</FormLabel>
						<FormInput
							value={`lat: ${defaultValues.location.lat
								.toString()
								.slice(0, 6)} | lng: ${defaultValues.location.lng
								.toString()
								.slice(0, 6)}`}
							disabled
						/>
						<ErrorMessage
							name="location"
							render={({ message }) => <FormError>{message}</FormError>}
						/>
					</FormItem>

					<FormItem>
						<FormLabel>Trail Contact Email</FormLabel>
						<Controller
							name="businessEmail"
							render={({ onChange, value }) => (
								<FormInput
									onIonChange={(e: any) => onChange(e.detail.value)}
									value={value}
								/>
							)}
						/>
						<ErrorMessage
							name="businessEmail"
							render={({ message }) => <FormError>{message}</FormError>}
						/>
					</FormItem>
					<FormTopPadding />

					<FormSection title="Basic Info">
						<FormItem>
							<FormLabel>Groomed for Classic?</FormLabel>
							<Controller
								name="isClassicAllowed"
								render={({ onChange, value }) => (
									<IonToggleExtended
										onIonChange={(e: any) => onChange(e.detail.checked)}
										checked={value}
									/>
								)}
							/>
							<ErrorMessage
								name="isClassicAllowed"
								render={({ message }) => <FormError>{message}</FormError>}
							/>
						</FormItem>

						<FormItem>
							<FormLabel>Groomed for Skate?</FormLabel>
							<Controller
								name="isSkateAllowed"
								render={({ onChange, value }) => (
									<IonToggleExtended
										onIonChange={(e: any) => onChange(e.detail.checked)}
										checked={value}
									/>
								)}
							/>
							<ErrorMessage
								name="isSkateAllowed"
								render={({ message }) => <FormError>{message}</FormError>}
							/>
						</FormItem>

						<FormItem>
							<FormLabel>Trail Description</FormLabel>
							<Controller
								name="description"
								render={({ onChange, value }) => (
									<FormTextarea
										onIonChange={(e) => onChange(e.detail.value)}
										value={value}
										autoGrow
										inputmode="text"
										debounce={200}
										rows={8}
										spellcheck
									/>
								)}
							/>
							<ErrorMessage
								name="description"
								render={({ message }) => <FormError>{message}</FormError>}
							/>
						</FormItem>

						<FormItem>
							<FormLabel>Trails Length</FormLabel>
							<Controller
								name="trailsLength"
								onChangeName="onIonChange"
								render={({ onChange, value }) => (
									<AdvancedIonInput
										onIonChange={onChange}
										value={value}
										suffix="Kilometers"
										type="number"
										inputmode="numeric"
									/>
								)}
							/>
							<ErrorMessage
								name="trailsLength"
								render={({ message }) => <FormError>{message}</FormError>}
							/>
						</FormItem>

						<FormItem>
							<FormLabel>Lighted at Night?</FormLabel>
							<Controller
								name="isLighted"
								render={({ onChange, value }) => (
									<IonToggleExtended
										onIonChange={(e: any) => onChange(e.detail.checked)}
										checked={value}
									/>
								)}
							/>
							<ErrorMessage
								name="isLighted"
								render={({ message }) => <FormError>{message}</FormError>}
							/>
						</FormItem>

						<FormItem>
							<FormLabel>Snowmaking?</FormLabel>
							<Controller
								name="isSnowmaking"
								render={({ onChange, value }) => (
									<IonToggleExtended
										onIonChange={(e: any) => onChange(e.detail.checked)}
										checked={value}
									/>
								)}
							/>
							<ErrorMessage
								name="isSnowMaking"
								render={({ message }) => <FormError>{message}</FormError>}
							/>
						</FormItem>
					</FormSection>

					<FormSection title="Pass Info">
						<FormItem>
							<FormLabel>Pass Required?</FormLabel>
							<Controller
								name="passIsRequired"
								render={({ onChange, value }) => (
									<IonToggleExtended
										onIonChange={(e: any) => onChange(e.detail.checked)}
										checked={value}
									/>
								)}
							/>
							<ErrorMessage
								name="passIsRequired"
								render={({ message }) => <FormError>{message}</FormError>}
							/>
						</FormItem>

						<FormItem>
							<FormLabel>Day Pass Cost</FormLabel>
							<Controller
								name="passDayCost"
								render={({ onChange, value }) => (
									<AdvancedIonInput
										onIonChange={onChange}
										value={value}
										prefix="$"
										type="number"
										inputmode="numeric"
									/>
								)}
							/>
							<ErrorMessage
								name="passDayCost"
								render={({ message }) => <FormError>{message}</FormError>}
							/>
						</FormItem>

						<FormItem>
							<FormLabel>Season Pass Cost</FormLabel>
							<Controller
								name="passSeasonCost"
								render={({ onChange, value }) => (
									<AdvancedIonInput
										onIonChange={onChange}
										value={value}
										prefix="$"
										type="number"
										inputmode="numeric"
									/>
								)}
							/>
							<ErrorMessage
								name="passSeasonCost"
								render={({ message }) => <FormError>{message}</FormError>}
							/>
						</FormItem>

						<FormItem>
							<FormLabel>Purchase Pass URL</FormLabel>
							<Controller
								name="passPurchaseURL"
								render={({ onChange, value }) => (
									<AdvancedIonInput
										onIonChange={onChange}
										value={value}
										prefix={<IonIcon icon={link} />}
										type="url"
										inputmode="url"
									/>
								)}
							/>
							<ErrorMessage
								name="passPurchaseURL"
								render={({ message }) => <FormError>{message}</FormError>}
							/>
						</FormItem>
					</FormSection>

					{/* <FormSection title='Photos'>
							<FormItem>
								<FormLabel>Photos</FormLabel>
								<Controller
									name='photos.photoURLs'
									render={({ onChange }) => (
										<AttachFiles onUploadChange={onChange} uploadPath='trails' />
									)}
								/>
								<ErrorMessage
									name='photos.photoURLs'
									render={({ message }) => <FormError>{message}</FormError>}
								/>
							</FormItem>
						</FormSection> */}

					{/* <FormSection title='Trail Map'>
							<FormItem>
								<FormLabel>Trail Map Photos</FormLabel>
								<Controller
									name='trailMap.photoURLs'
									render={({ onChange }) => (
										<AttachFiles onUploadChange={onChange} uploadPath='trailMaps' />
									)}
								/>
								<ErrorMessage
									name='trailMap.photoURLs'
									render={({ message }) => <FormError>{message}</FormError>}
								/>
							</FormItem>
						</FormSection> */}

					<FormSection title="Links">
						<FormItem>
							<FormLabel>Website URL</FormLabel>
							<Controller
								name="websiteURL"
								render={({ onChange }) => (
									<AdvancedIonInput
										onIonChange={onChange}
										prefix={<IonIcon icon={link} />}
										type="url"
										inputmode="url"
									/>
								)}
							/>
							<ErrorMessage
								name="websiteURL"
								render={({ message }) => <FormError>{message}</FormError>}
							/>
						</FormItem>

						<FormItem>
							<FormLabel>Facebook URL</FormLabel>
							<Controller
								name="facebookURL"
								render={({ onChange }) => (
									<AdvancedIonInput
										onIonChange={onChange}
										prefix={<IonIcon icon={logoFacebook} />}
										type="url"
										inputmode="url"
									/>
								)}
							/>
							<ErrorMessage
								name="facebookURL"
								render={({ message }) => <FormError>{message}</FormError>}
							/>
						</FormItem>

						<FormItem>
							<FormLabel>Instagram URL</FormLabel>
							<Controller
								name="instagramURL"
								render={({ onChange }) => (
									<AdvancedIonInput
										onIonChange={onChange}
										prefix={<IonIcon icon={logoInstagram} />}
										type="url"
										inputmode="url"
									/>
								)}
							/>
							<ErrorMessage
								name="instagramURL"
								render={({ message }) => <FormError>{message}</FormError>}
							/>
						</FormItem>
					</FormSection>

					<FormBottomPadding />
				</FormProvider>
			</IonContent>
		</>
	);
};

export default EditTrail;
