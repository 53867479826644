import { gql } from '@apollo/client';
import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import { ErrorMessage } from '@hookform/error-message';
import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonContent,
	IonHeader,
	IonLoading,
	IonMenuButton,
	IonPage,
	IonSelect,
	IonSelectOption,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import * as Joi from 'joi';

import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { StringParam, useQueryParam } from 'use-query-params';
import {
	FormBottomPadding,
	FormError,
	FormItem,
	FormLabel,
	FormTextarea,
	FormTopPadding,
} from '../../components/Form/FormComponents';
import { useCreateFeedbackMutation } from '../../generated/graphql';
import { sm } from '../../theme/style-system';
import { showErrorToast, showSuccessToast } from '../../utils/Toasts';
import { getValidationResolver } from '../../utils/ValidationResolver';

gql`
	mutation CreateFeedback($type: String!, $message: String!) {
		insertFeedback(object: { type: $type, message: $message }) {
			id
		}
	}
`;

const FeedbackSchema = Joi.object({
	type: Joi.string().required(),
	message: Joi.string().required(),
});

interface FeedbackModel {
	type: keyof typeof FEEDBACK_OPTIONS;
	message: string;
}

const FEEDBACK_OPTIONS = {
	BUG_REPORT: {
		name: 'Bug Report',
		placeholder: `Platform:  <iOS / android / web>
I found the bug when...`,
	},
	SUGGESTION: {
		name: 'Suggestion',
		placeholder: `I'd like to see Skiwise add...`,
	},
	TRAIL_OWNERSHIP_CLAIM: {
		name: 'Claim Ownership of a Ski Trail',
		placeholder: `Please include the following info:
Trail name: 
Your name: 
Your contact email: 

We'll be in touch soon to get you setup as an administrator for your trail.
  `,
	},
	OTHER: {
		name: 'Other',
		placeholder: 'Message...',
	},
};

interface Props {}

const FeedbackPage: React.FC<Props> = () => {
	const [createFeedbackMutation, { loading: uploading }] =
		useCreateFeedbackMutation();

	const [type] = useQueryParam('type', StringParam);

	const defaultValues = {
		type: Object.keys(FEEDBACK_OPTIONS).some((key) => type === key)
			? (type as keyof typeof FEEDBACK_OPTIONS)
			: undefined,
		message: undefined,
	};

	const form = useForm<FeedbackModel>({
		mode: 'onSubmit',
		defaultValues: defaultValues,
		resolver: getValidationResolver(FeedbackSchema, (error) => ({
			...error,
			message: `This field ${error.message}`,
		})),
	});

	const onSubmit = (data: FeedbackModel) => {
		createFeedbackMutation({
			variables: {
				type: data.type,
				message: data.message,
			},
		})
			.then(() => {
				showSuccessToast('Feedback Sent!');
				form.reset({
					type: data.type,
					message: undefined,
				});
			})
			.catch((e) => {
				console.error(e);
				showErrorToast('Error sending feedback. Please try again later.');
			});
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color="primary">
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Give Feedback</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonLoading isOpen={uploading} message="Uploading..." />

			<IonContent>
				<FormProvider {...form}>
					<FormTopPadding />
					<FormItem>
						<FormLabel>Give us Feedback! </FormLabel>
					</FormItem>
					<FormItem>
						<Global
							styles={css`
								.feedback-popover {
									--width: 80%;
									@media (min-width: ${sm}) {
										--width: 400px;
									}
								}
							`}
						/>
						<Controller
							name="type"
							render={({ onChange, value }) => (
								<StyledIonSelect
									value={value}
									placeholder="Select One"
									onIonChange={(e) => onChange(e.detail.value)}
									interface="popover"
									interfaceOptions={{
										header: 'Feedback Type',
										cssClass: 'feedback-popover',
									}}
								>
									{Object.entries(FEEDBACK_OPTIONS).map(([key, value]) => (
										<IonSelectOption key={key} value={key}>
											{value.name}
										</IonSelectOption>
									))}
								</StyledIonSelect>
							)}
						/>
						<ErrorMessage
							name="type"
							render={({ message }) => <FormError>{message}</FormError>}
						/>
					</FormItem>

					<FormItem>
						<Controller
							name="message"
							render={({ onChange, value }: any) => (
								<FormTextarea
									onIonChange={(e) => onChange(e.detail.value)}
									value={value}
									placeholder={
										FEEDBACK_OPTIONS[
											form.watch(
												'type',
												'OTHER'
											) as keyof typeof FEEDBACK_OPTIONS
										]?.placeholder ?? ''
									}
									rows={8}
								/>
							)}
						/>
						<ErrorMessage
							name="message"
							render={({ message }) => <FormError>{message}</FormError>}
						/>
					</FormItem>

					<FormItem>
						<IonButton onClick={form.handleSubmit(onSubmit)} expand="block">
							Submit Feedback
						</IonButton>
					</FormItem>

					<FormItem>
						<IonCard>
							<IonCardContent>
								If your feedback requires a response we'll contact you through
								your email. If you want to be contacted in another way let us
								know.
							</IonCardContent>
						</IonCard>
					</FormItem>

					<FormBottomPadding />
				</FormProvider>
			</IonContent>
		</IonPage>
	);
};

export default FeedbackPage;

const StyledIonSelect = styled(IonSelect)`
	border: 1px solid var(--ion-color-light-shade);
	border-radius: 6px;
`;
