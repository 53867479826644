import { ApolloError } from '@apollo/client';
import React from 'react';
import { TrailFragment, UserFragment, useTrailsQuery } from '../generated/graphql';
import { useRequiredContext } from '../hooks/useRequiredContext';
import { useUser } from './UserContainer';
import { Auth } from './AuthContainer';

interface TrailsContextProps {
	data:
		| {
				trails: TrailFragment[];
				followingTrails: TrailFragment[];
				recentTrailSearches: TrailFragment[];
		  }
		| undefined;
	loading: boolean;
	error: ApolloError | undefined;
}

export const TrailsContext = React.createContext<TrailsContextProps | null>(null);

interface TrailsProviderProps {
	children: React.ReactNode;
}

export const TrailsProvider = ({ children }: TrailsProviderProps) => {
	const { data: userData } = useUser();
	const auth = Auth.useContainer();

	const { data, loading, error } = useTrailsQuery({
		variables: { userId: auth.user?.id || '__NOT_AUTHENTICATED__' },
		fetchPolicy: 'cache-and-network',
	});

	const value = {
		data: data
			? {
					trails: data.trails,
					followingTrails: getFollowingTrails(userData?.user, data.trails),
					recentTrailSearches: userData?.recentTrailSearches || [],
				}
			: undefined,
		loading,
		error,
	};

	return <TrailsContext.Provider value={value}>{children}</TrailsContext.Provider>;
};

export const useTrails = () => useRequiredContext('TrailsContext', TrailsContext);

const getFollowingTrails = (user: UserFragment | undefined, trails: TrailFragment[]) => {
	if (!user) return [];

	return trails.filter((trail) => user.followingTrails.some(({ trailId }) => trailId === trail.id));
};
