import 'firebase/auth';
import { Redirect, Route } from 'react-router-dom';
import SkeletonSpinner from '../components/Skeletons/SkeletonSpinner';
import { Auth } from '../containers/AuthContainer';
import { useCheckIfAccountExistsQuery } from '../generated/graphql';

const AuthRoute = ({ component: Component, ...rest }: any) => {
	const { user } = Auth.useContainer();
	if (!user?.id)
		return <Route {...rest} render={(props) => <Component {...props} />} />;

	return <Internals component={Component} userId={user?.id} {...rest} />;
};

export default AuthRoute;

const Internals = ({ component: Component, userId, ...rest }: any) => {
	const { data, loading } = useCheckIfAccountExistsQuery({
		variables: { id: userId },
	});

	if (loading) return <SkeletonSpinner />;
	if (!data) return <Redirect to={`/create-account`} />;
	if (!data.user) return <Redirect to={`/create-account`} />;

	return <Route {...rest} render={(props) => <Component {...props} />} />;
};
