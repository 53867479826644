import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react';

import React from 'react';
import { withRouter } from 'react-router-dom';

const TutorialPage: React.FC = () => {
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color="primary">
					{/* <IonButtons slot='start'>
						<IonButton className='ion-float-left' onClick={() => history.goBack()}>
							Back
							<IonIcon icon={arrowBack} slot='start' />
						</IonButton>
					</IonButtons> */}
					<IonTitle>Tutorial</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent></IonContent>
		</IonPage>
	);
};

export default withRouter(TutorialPage);
