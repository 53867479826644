import { Coords } from 'google-map-react';
import { map } from 'ionicons/icons';

export const MAPBOX_ACCESS_TOKEN =
	'pk.eyJ1Ijoic3BlbmNlcnBhdWx5IiwiYSI6ImNraWkyaWpjczJzdzgydm54OWZqOXFtM20ifQ.PcZ9vzvV0ltcJFAPRtpT_A';

export const DEFAULT_PROFILE_PICTURE = './assets/images/trail_bg_square_smaller.jpg';
export const DEFAULT_USER_PHOTO_ID = '3f078da3-eb98-4d88-b823-dd76af793bbf';
export const SKIWISE_LOGO = 'assets/images/logo.png';
export const DEFAULT_BACKGROUND_IMAGE = './assets/images/trail_bg_square.png';
export const TRAIL_PAGE_DEFAULT_BACKGROUND_IMAGE = './assets/images/ski-lodge-bg.jpg';
export const TRAIL_REPORT_BACKGROUND_IMAGE = './assets/images/low-poly-bg.jpg';
export const TRAIL_MARKER_IMAGE = './assets/icon/marker.svg';
export const UNCLAIMED_TRAIL_OWNER_ID = 'wpvVonCjKmaw4g8YmLg7PWUX3c63';

export const TRAIL_ICON = map;
export const STAR_ICON = `./assets/icon/star.svg`;

// Configurations
export const MAX_RECENT_SEARCHES_FOR_SEARCHBAR = 3;
export const MAX_SCREEN_SIZE_FOR_SMALLER_ADS = 576;

export const DEFAULT_MAP_CENTER: Coords = { lat: 44.974, lng: -93.26 };

// For Screenshot Mode {
// export const IS_ADS_DISABLED_BY_DEFAULT = true;
// export const CLUSTER_POINT_RADIUS_DESKTOP = 0;
// export const CLUSTER_POINT_RADIUS_MOBILE = 0;
// } else {
export const IS_ADS_DISABLED_BY_DEFAULT = false;
export const CLUSTER_POINT_RADIUS_DESKTOP = 180;
export const CLUSTER_POINT_RADIUS_MOBILE = 100;

//Map Components

//Higher zoom number => more "zoomed IN"
export const MIN_MAP_ZOOM = 3;
export const MAX_MAP_ZOOM = 17;
export const INITIAL_ZOOM_LEVEL = 11;

// this is in degrees. So 1 means that the bounding box is 1 degree larger on the
// left, right, top and bottom. 0 is exactly the view size.
export const VIEW_RENDERING_BOUNDING_BOX_OFFSET_DESKTOP = 0.8;
export const VIEW_RENDERING_BOUNDING_BOX_OFFSET_MOBILE = 1;
export const DESKTOP_BREAKPOINT = 800;
export const MAX_ZOOM_TO_CREATE_CLUSTERS_MOBILE = 8;
export const MAX_ZOOM_TO_CREATE_CLUSTERS_DESKTOP = 9;

export const LINK_FACEBOOK = 'https://www.facebook.com/SkiwiseApp/';
export const LINK_INSTAGRAM = 'https://www.instagram.com/skiwise/';
export const LINK_WEBSITE = 'https://www.skiwise-app.com/';
