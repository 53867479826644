import { IonPage } from '@ionic/react';
import React, { useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import CreateTrailApollo from './CreateTrailApollo';

interface Props extends RouteComponentProps {}

const CreateTrailPage: React.FC<Props> = ({ history }) => {
	const pageRef = useRef<any>();

	const handleClose = () => {
		history.replace('/trails');
	};

	return (
		<IonPage id="create-trail-page" ref={pageRef}>
			<CreateTrailApollo onClose={handleClose} pageRef={pageRef} />
		</IonPage>
	);
};

export default withRouter(CreateTrailPage);
