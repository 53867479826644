import {} from '@ionic/react';

import styled from '@emotion/styled';
import React from 'react';
import { useFab } from '../hooks/useFab';

interface Props {
	children?: React.ReactNode;
	position?: 'top' | 'bottom';
	maxWidth?: string;
}

const FixedContainer: React.FC<Props> = ({
	children,
	position = 'bottom',
	maxWidth,
}) => {
	const { padding, hidden } = useFab(position);

	return (
		<Wrapper
			hidden={hidden}
			padding={padding}
			position={position}
			maxWidth={maxWidth}
		>
			{children}
		</Wrapper>
	);
};

export default FixedContainer;

const Wrapper = styled.div<{
	position: 'top' | 'bottom';
	maxWidth?: string;
	padding: string;
}>`
	position: fixed;
	margin: auto;
	bottom: ${(props) => props.position === 'bottom' && props.padding};
	top: ${(props) => props.position === 'top' && props.padding};
	background: none;
	width: 100%;
	left: 0;
	right: 0;
	max-width: ${(props) => props.maxWidth || '100%'};
`;
