import { gql } from '@apollo/client';
import { IonLoading } from '@ionic/react';

import React, { useCallback } from 'react';
import { Auth } from '../../containers/AuthContainer';
import { useCreateTrailMutation } from '../../generated/graphql';
import { showErrorToast, showSuccessToast } from '../../utils/Toasts';
import { removeUndefinedFields } from '../../utils/UtilityFunctions';
import CreateTrailLogic from './CreateTrailLogic';
import { CreateTrailModel } from './models/CreateTrailModel';

gql`
	mutation CreateTrail(
		$name: String!
		$ownerId: String
		$location: location_obj_rel_insert_input!
		$businessEmail: String
	) {
		insertTrail(
			object: {
				businessEmail: $businessEmail
				location: $location
				name: $name
				ownerId: $ownerId
			}
		) {
			id
		}
	}
`;

interface Props {
	pageRef: any;
	onClose: () => any;
}

const CreateTrailApollo: React.FC<Props> = ({ pageRef, onClose }) => {
	const { user } = Auth.useContainer();

	const [createTrail, { loading: uploading }] = useCreateTrailMutation();

	const handleSubmit = useCallback(
		(data: CreateTrailModel) => {
			const uploadData = {
				name: data.name,
				location: {
					data: {
						lat: data.location?.lat,
						lng: data.location?.lng,
					},
				},
				ownerId: data.isOwner && user ? user.id : undefined,
				businessEmail: data.businessEmail,
			};

			const purgedUploadData = removeUndefinedFields(uploadData);
			console.log(purgedUploadData);

			createTrail({ variables: purgedUploadData })
				.then(() => {
					showSuccessToast('Trail Submitted Successfully');
					if (onClose) {
						onClose();
					}
				})
				.catch((err) => {
					console.error(err);
					showErrorToast('Error submitting trail: \n' + err.message);
				});
		},
		[createTrail, onClose, user]
	);

	return (
		<>
			<IonLoading isOpen={uploading} message="Submitting Trail..." />
			<CreateTrailLogic pageRef={pageRef} onSubmit={handleSubmit} />
		</>
	);
};

export default CreateTrailApollo;
