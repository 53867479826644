import React from 'react';
import { TrailFragment } from '../../../generated/graphql';
import FuseFilteredTrailsItemsView from './FuseFilteredTrailsItemsView';
import { useTrails } from '../../../containers/TrailsContainer';

interface Props {
	noItemsLabel: string;
	currentSearch: string;
	onSearchItemClick: (trail: TrailFragment) => void;
	limit: number;
}

const FuseFilteredTrailsSection: React.FC<Props> = ({
	noItemsLabel,
	currentSearch,
	onSearchItemClick,
	limit,
}) => {
	const { data } = useTrails();

	return (
		<FuseFilteredTrailsItemsView
			limit={limit}
			trails={data?.trails || []}
			noItemsLabel={noItemsLabel}
			currentSearch={currentSearch}
			onSearchItemClick={onSearchItemClick}
		/>
	);
};

export default FuseFilteredTrailsSection;
